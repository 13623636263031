import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import FormattedNumberField from 'components/Fields/Base/FormattedNumberField'

const PropertyTenants: React.FC = () => (
  <ConditionalField field={fieldNames.propertyTenants}>
    <FormattedNumberField
      field={fieldNames.propertyTenants}
      label="Number of tenants *"
      hint="Please see fee scale for how the number of tenants will assist in determining the fee."
      validationRules={{
        required: true,
        min: {
          value: 1,
          message: 'must be greater than 0',
        },
        max: {
          value: 999,
          message: 'must be less than 1000',
        },
      }}
    />
  </ConditionalField>
)

export default PropertyTenants
