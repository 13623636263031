import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['firm']

  toggle (e) {
    if (this.firmTarget.classList.contains('open')) {
      e.preventDefault()
    }
    this.firmTarget.classList.toggle('open')
  }

  clearData () {
    for (const row of this.firmTarget.querySelectorAll('tr.valuation')) {
      row.remove()
    }
  }

  renderFirmData (e) {
    let [data] = e.detail
    this.firmTarget.classList.add('open')
    for (const row of data.querySelectorAll('tr')) {
      this.firmTarget.append(row)
    }
  }
}
