import { Controller } from 'stimulus'
import L from 'leaflet'
import Resize from 'throttled-resize'
import 'leaflet.awesome-markers'
import SmoothMarkerBouncing from 'leaflet.smooth_marker_bouncing'

export default class extends Controller {
  static targets = ['map', 'valuer', 'resultsContainer']

  connect () {
    SmoothMarkerBouncing(L)
    const map = this.mapTarget
    const _this = this
    this.markers = {}
    this.resize = new Resize()
    this.map = L.map(map)
    this.map.addEventListener('load', () => {
      _this._addPropertyMarker()
      _this.addValuerMarkers()
    })
    this.bounds = JSON.parse(map.dataset.boundingBox)
    this.tiles()
    this.resize.on('resize:end', () => {
      if (typeof this.map === 'undefined') { return }

      this.map.invalidateSize()
    })
  }

  addValuerMarkers () {
    for (const valuer of this.valuerTargets) {
      this._addValuerMarker(valuer)
    }
  }

  tiles () {
    L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors',
      maxZoom: 18
    }).addTo(this.map)
  }

  disconnect () {
    this.resize = null
  }

  bounceMarker (e) {
    let marker = this.markers[e.currentTarget.id]
    if (marker && !marker.isBouncing()) {
      marker.bounce(3)
    }
  }

  unbounceMarker () {
    L.Marker.stopAllBouncingMarkers()
  }

  _addValuerMarker (valuer) {
    let lat = valuer.dataset.objectLatitude
    let lng = valuer.dataset.objectLongitude
    let name = valuer.querySelector('.name').textContent
    let link = this._streetViewLink(lat, lng)
    let nameWithLink = `${name}<br>${link}`

    let marker = L.marker([lat, lng], {
      title: name,
      alt: name,
      icon: this.firmMarker,
      riseOnHover: true
    })

    marker.bindPopup(`<strong>${nameWithLink}</strong>`)

    marker.addTo(this.map)
    this.markers[valuer.id] = marker
    marker._icon.setAttribute('rel', valuer.id)
  }

  _addPropertyMarker () {
    let lat = this.resultsContainerTarget.dataset.latitude
    let lng = this.resultsContainerTarget.dataset.longitude
    let link = this._streetViewLink(lat, lng)

    let marker = L.marker([lat, lng], {
      title: 'Property',
      alt: 'Property',
      icon: this.propertyMarker,
      riseOnHover: true
    })

    marker.bindPopup(`<strong>Property<br>${link}</strong>`)

    marker.addTo(this.map)
  }

  _streetViewLink (lat, lng) {
    return `<a href='https://map.google.com/maps?q=&layer=c&cbll=${lat},${lng}' target='_blank'>` +
      "<i class='fa fa-street-view fa-lg'></i> Street View</a>"
  }

  set bounds (box) {
    this.map.fitBounds(L.latLngBounds(box))
  }

  get propertyMarker () {
    return L.AwesomeMarkers.icon({
      icon: 'home',
      markerColor: 'green',
      prefix: 'fa'
    })
  }

  get firmMarker () {
    return L.AwesomeMarkers.icon({
      icon: 'building',
      markerColor: 'blue',
      prefix: 'fa'
    })
  }
}
