import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "toggleField",
        "piiLevelField",
        "piiNotesField"];
    static values = {
        piiLevel: Number,
        piiNotes: String
    };

    connect() {
        this.updateVisibility();
        this.updateLabelColor();
    }

    toggle() {
        this.updateVisibility();
        this.populateDefaultValues();
        this.updateLabelColor();
    }

    populateDefaultValues() {
        if (this.isCheckboxChecked()) {
            this.populateIfEmpty(this.piiLevelFieldTarget, this.piiLevelValue);
            this.populateIfEmpty(this.piiNotesFieldTarget, this.piiNotesValue);
        }
    }

    updateVisibility() {
        this.toggleFieldTargets.forEach(element => {
            element.style.display = this.isCheckboxChecked() ? "block" : "none";
        });
    }

    updateLabelColor() {
        if (this.isCheckboxChecked()) {
            this.piiOverrideLabel.classList.add('red-profit');
        } else {
            this.piiOverrideLabel.classList.remove('red-profit');
        }
    }

    get piiOverrideLabel() {
        return this.element.querySelector(".pii-override-label");
    }

    populateIfEmpty(field, defaultValue) {
        if (!field.value) {
            field.value = defaultValue;
        }
    }

    isCheckboxChecked() {
        return this.checkbox.checked;
    }

    get checkbox() {
        return this.element.querySelector("input[type='checkbox']");
    }
}
