import { Controller } from "@hotwired/stimulus";
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';

export default class extends Controller {
  static targets = ['datePicker'];

  connect() {
    this.initializeDatePicker();
  }

  initializeDatePicker() {
    const options = JSON.parse(this.datePickerTarget.dataset.options || '{}');
  
    new AirDatepicker(this.datePickerTarget, {
      locale: localeEn,
      dateFormat: 'dd-MM-yyyy',
      range: true,
      multipleDatesSeparator: ' - ',
      autoClose: true,
      position: options.position || 'bottom right',
      toggleSelected: ({ datepicker }) => datepicker.selectedDates.length === 2,
      onSelect: ({ formattedDate, date }) => {
        if (date.length === 2) {
          const [fromDate, toDate] = formattedDate;
          this.datePickerTarget.value = `${fromDate} - ${toDate}`;
          // Pass dates in YYYY-MM-DD format to Ruby
          const formattedFromDate = this.formatDate(date[0]);
          const formattedToDate = this.formatDate(date[1]);
          this.setHiddenInputs(formattedFromDate, formattedToDate);
          if (options.submit_on_change !== false) {
            this.submitForm();
          }
        }
      },
      firstDay: 1,
    });
  }

  formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
  }

  setHiddenInputs(fromDate, toDate) {
    const fromInput = this.element.querySelector('input[name="from"]');
    const toInput = this.element.querySelector('input[name="to"]');

    if (fromInput && toInput) {
      fromInput.value = fromDate;
      toInput.value = toDate;
    }
  }

  submitForm() {
    this.element.requestSubmit();
  }
}
