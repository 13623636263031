import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container', 'name', 'percent', 'progress', 'bar', 'action']

  connect () {
    this.percent = 0
  }

  change (event) {
    this.action = event.detail.action
    this.percent = event.detail.percent
    this.bar = event.detail.percent
  }

  replace (event) {
    var div = document.createElement('div')
    div.innerHTML = event.detail.data
    this.containerTarget.replaceWith(div.firstChild)
  }

  error (event) {
    this.action = 'Error loading'
    this.percent = 0
    this.progressTarget.setAttribute('hidden', true)
  }

  set name (val) {
    this.nameTarget.textContent = val
  }

  set percent (val) {
    this.percentTarget.textContent = '' + parseFloat(val).toFixed(2) + '%'
  }

  set action (val) {
    if (val && val !== '') {
      this.actionTarget.textContent = val
    }
  }

  set bar (val) {
    let percent = parseFloat(val).toFixed(2)
    this.barTarget.setAttribute('style', `width: ${percent}%`)
    this.barTarget.setAttribute('aria-valuenow', percent)
  }
}
