import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'
import TextAreaField from 'components/Fields/Base/TextAreaField'

const PropertyLandDetails: React.FC = () => (
  <>
    <ConditionalField field={fieldNames.propertyLand}>
      <SelectField
        field={fieldNames.propertyLand}
        label="How much land is there associated with the property? *"
        hint="This information is important as it will affect the type of valuer we select."
        validationRules={{ required: true }}
      />
    </ConditionalField>
    <ConditionalField field={fieldNames.propertyLandDetails}>
      <TextAreaField
        field={fieldNames.propertyLandDetails}
        label="Please provide details"
        placeholder="Please provide details"
        hint="For example, 5 acres of agricultural land, 2 acres of woodland etc."
        rows={3}
      />
    </ConditionalField>
  </>
)

export default PropertyLandDetails
