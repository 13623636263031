// app/javascript/controllers/hide_on_change_controller.js
import { Controller } from 'stimulus';
import $ from 'jquery'

export default class extends Controller {
  hide() {
    $(this.element.dataset.targetselector).collapse("hide");
  }

  show() {
    $(this.element.dataset.targetselector).collapse("show");
  }
}
