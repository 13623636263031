import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'

const PropertyResidentialStatus: React.FC = () => (
  <ConditionalField field={fieldNames.propertyResidentialStatus}>
    <SelectField
      field={fieldNames.propertyResidentialStatus}
      label="Residential property status *"
      validationRules={{ required: true }}
    />
  </ConditionalField>
)

export default PropertyResidentialStatus
