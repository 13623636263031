import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'
import ConditionalField from 'components/Fields/Base/ConditionalField'
import FormattedTextArea from 'components/Fields/Base/FormattedTextArea'

const PropertyPreviousVisitCommentary: React.FC = () => {
  return (
    <ConditionalField field={fieldNames.propertyPreviousVisitCommentary}>
      <FormattedTextArea
        field={fieldNames.propertyPreviousVisitCommentary}
        label="Commentary on changes to the property since previous visit"
        hint="Please provide details if the property has changed since the last valuation."
        placeholder="If the property is a development, please tell us what works have taken place since the last valuation, how much expenditure is required to complete etc."
        disableColorPicker={true}
      />
    </ConditionalField>
  )
}

export default PropertyPreviousVisitCommentary
