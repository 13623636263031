import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'

import fieldsNames from 'components/Fields/Base/fieldNames'
import useCanDisplayField from 'components/Fields/Base/useCanDisplayField'
import RequestedPropertySector from 'components/Fields/RequestedPropertySector'
import RequestedPropertyType from 'components/Fields/RequestedPropertyType'
import RequestedReportType from 'components/Fields/RequestedReportType'
import RedirectToLegacyForm from 'components/RedirectToLegacyForm'
import StepBody from 'components/Steps/StepBody'
import StepHeadline from 'components/Steps/StepHeadline'
import {
  SupportedRequestedPropertySectors,
  SupportedRequestedReportTypes,
} from 'components/types'

const isSupported = (
  supportedRequestedReportTypes: SupportedRequestedReportTypes,
  supportedRequestedPropertySectors: SupportedRequestedPropertySectors,
  requestedReportType: string,
  requestedPropertySectors: string
): boolean =>
  (!requestedReportType ||
    supportedRequestedReportTypes.includes(requestedReportType)) &&
  (!requestedPropertySectors ||
    supportedRequestedPropertySectors.includes(requestedPropertySectors))

interface Props {
  onReset: () => void
  onLock: () => void
  supportedRequestedReportTypes: SupportedRequestedReportTypes
  supportedRequestedPropertySectors: SupportedRequestedPropertySectors
}

const ValuationDetails: React.FC<Props> = ({
  onLock,
  onReset,
  supportedRequestedReportTypes,
  supportedRequestedPropertySectors,
}) => {
  const { watch, getValues } = useFormContext()
  const [locked, setLock] = useState<boolean>(false)
  const [displayResetPopup, setDisplayResetPopup] = useState<boolean>(false)
  const [supported, setSupported] = useState<boolean>(true)

  const hasReportType = !!watch(fieldsNames.requestedReportType)
  const hasPropertySector = !!watch(fieldsNames.requestedPropertySector)
  const hasPropertyType = !!watch(fieldsNames.requestedPropertyType)
  const canDisplayPropertySector = useCanDisplayField(
    fieldsNames.requestedPropertySector
  )
  const canDisplayPropertyType = useCanDisplayField(
    fieldsNames.requestedPropertyType
  )
  const canLock =
    hasReportType &&
    (hasPropertySector || !canDisplayPropertySector) &&
    (hasPropertyType || !canDisplayPropertyType)

  const handleLock = () => {
    const requestedReportType = getValues(fieldsNames.requestedReportType)
    const requestedPropertySector = getValues(fieldsNames.requestedPropertySector)
    setLock(true)

    if (
      isSupported(
        supportedRequestedReportTypes,
        supportedRequestedPropertySectors,
        requestedReportType,
        requestedPropertySector
      )
    ) {
      onLock()
    } else {
      setSupported(false)
    }
  }

  useEffect(() => {
    if (!hasReportType && !hasPropertySector && !hasReportType) {
      setLock(false)
    }
  }, [hasReportType, hasPropertySector, hasReportType])

  const handleReset = () => {
    setDisplayResetPopup(false)
    setLock(false)
    onReset()
  }

  const resetPopup = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Warning!</Popover.Title>
      <Popover.Content className="p-3">
        Any details already entered will be lost.
        <div className="mt-3 text-center">
          <Button
            variant="light"
            className="mr-2"
            onClick={() => setDisplayResetPopup(false)}
          >
            Cancel
          </Button>
          <Button onClick={handleReset}>OK</Button>
        </div>
      </Popover.Content>
    </Popover>
  )

  return (
    <>
      <StepHeadline idx={1} title="Valuation Type">
        {locked && supported && (
          <div className="mr-4">
            <OverlayTrigger
              show={displayResetPopup}
              placement="bottom"
              overlay={resetPopup}
            >
              <Button
                variant="light"
                onClick={() => {
                  setDisplayResetPopup(true)
                }}
              >
                Change Valuation Details
              </Button>
            </OverlayTrigger>
          </div>
        )}
      </StepHeadline>
      <StepBody>
        <RequestedReportType disabled={locked} />
        <RequestedPropertySector disabled={locked} />
        <RequestedPropertyType disabled={locked} />
        {!locked && (
          <div className="actions text-right mb-4">
            <Button variant="primary" onClick={handleLock} disabled={!canLock}>
              Next
            </Button>
          </div>
        )}
        {!supported && <RedirectToLegacyForm />}
      </StepBody>
    </>
  )
}

export default ValuationDetails
