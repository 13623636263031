import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from "./Base/SelectField";
import TextField from 'components/Fields/Base/TextField'

const LenderSelect: React.FC = () => (
  <>
    <ConditionalField field={fieldNames.lenderSelect}>
        <SelectField
            field={fieldNames.lenderSelect}
            label="Select lender the report is to be addressed to *"
            validationRules={{ required: true }}
            helpTexts={[
                'The lenders you are authorised to work with will appear in this drop-down list.  If the lender you wish to use does not appear, please contact them directly.'
            ]}
            helpAlertVariant={'warning'}
        />
    </ConditionalField>
    <ConditionalField field={fieldNames.selectedLenderName}>
      <TextField
        field={fieldNames.selectedLenderName}
        label="Report to be addressed to"
        disabled={ true }
        validationRules={{ required: false }}
      />
    </ConditionalField>
  </>
)

export default LenderSelect
