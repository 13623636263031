import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import FormattedNumberField from 'components/Fields/Base/FormattedNumberField'

const PropertyBuildCost: React.FC = () => {
  return (
    <ConditionalField field={fieldNames.propertyBuildCost}>
      <FormattedNumberField
        field={fieldNames.propertyBuildCost}
        prefix="£"
        label="Total Build Costs"
        validationRules={{
          max: {
            value: 999999999,
            message: 'must be less than 999,999,999',
          },
        }}
      />
    </ConditionalField>
  )
}

export default PropertyBuildCost
