import React from 'react'
import { Alert, Form } from 'react-bootstrap'
import { useFormContext, ValidationRules } from 'react-hook-form'

import { FieldName, HelpText } from 'components/types'
import Label from 'components/Fields/Base/Label'
import ErrorMessage from 'components/Fields/Base/ErrorMessage'
import useFieldHelpTexts from 'components/Fields/Base/useFieldHelpTexts'
import useFieldLabel from 'components/Fields/Base/useFieldLabel'
import useFieldLabelHint from 'components/Fields/Base/useFieldLabelHint'
import useFieldValue from 'components/Fields/Base/useFieldValue'
import useHideField from 'components/Fields/Base/useHideField'

interface Props {
  field: FieldName
  label?: string
  helpTexts?: HelpText[]
  hint?: string
  validationRules?: ValidationRules
  value?: string
  disabled?: boolean
  hideField?: boolean
}

const TextField: React.FC<Props> = ({
  field,
  label,
  helpTexts,
  hint,
  validationRules,
  value,
  disabled,
  hideField
}) => {
  const { register, errors } = useFormContext()
  const schemaLabel = useFieldLabel(field)
  const schemaLabelHint = useFieldLabelHint(field)
  const schemaHelpTexts = useFieldHelpTexts(field)
  const schemaValue = useFieldValue(field)
  const schemaHideField = useHideField(field)

  return (
    <>
      { (schemaHideField || hideField) ? (
        <></>
        ) : (
      <Form.Group controlId={field}>
        {(schemaLabel || label) && (
          <Label
            field={field}
            label={schemaLabel || label}
            hint={schemaLabelHint || hint}
          />
        )}
        {(schemaHelpTexts || helpTexts)?.map((helpText, idx) => (
          <Alert variant="info" key={idx}>
            {helpText}
          </Alert>
        ))}
        <Form.Control
          type="text"
          name={field}
          disabled={disabled}
          value={schemaValue || value}
          ref={register(validationRules)}
          isInvalid={errors[field]}
        />
        {errors[field] && <ErrorMessage error={errors[field]} />}
      </Form.Group>
      )}
    </>
  )
}

export default TextField
