import { Blob } from 'activestorage'
import axios from 'axios'
import { useState } from 'react'
import { DirectUpload } from '@rails/activestorage'

import { FormInputs, SubmittingState } from 'components/types'

const uploadFile = (uploadPath: string) => (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const upload = new DirectUpload(file, uploadPath)
    upload.create((error: Error, blob: Blob) => {
      if (error) {
        reject()
      } else {
        resolve(blob.signed_id)
      }
    })
  })
}

const buildPayload = (fields: FormInputs) => ({
  new_form: true,
  requested_report_type: fields.requestedReportType,
  requested_property_sector: fields.requestedPropertySector,
  requested_property_type: fields.requestedPropertyType,
  postcode: fields.propertyPostcode,
  address_line_1: fields.propertyAddressLine1,
  address_line_2: fields.propertyAddressLine2,
  address_line_3: fields.propertyAddressLine3,
  address_line_4: fields.propertyAddressLine4,
  city: fields.propertyCity,
  county: fields.propertyCounty,
  country: fields.propertyCountry,
  property_description: fields.propertyAdditionalInformation,
  property_condition: fields.propertyCondition,
  estimated_value: fields.propertyEstimatedValue,
  outbuildings: fields.propertyOutbuildings,
  outbuildings_details: fields.propertyOutbuildingsDetails,
  land_size: fields.propertyLand,
  land_size_details: fields.propertyLandDetails,
  refurbishment: fields.propertyRefurbishment,
  refurbishment_details: fields.propertyRefurbishmentDetails,
  residential_property_status: fields.propertyResidentialStatus,
  annual_rent: fields.propertyAnnualRent,
  property_valuation_basis: fields.propertyValuationBasis,
  number_of_tenants: fields.propertyTenants,
  tenure: fields.propertyTenure,
  is_remaining_term_less_than_85_years: fields.propertyLeasehold,
  remaining_term_details: fields.propertyLeaseholdDetails,
  loan_type: fields.loanType,
  loan_purpose: fields.loanPurpose,
  lender_reference_number: fields.lenderReferenceNumber,
  lender_addressee_id: fields.lenderAddressee,
  proposed_loan_charge: fields.loanCharge,
  proposed_loan_amount: fields.loanAmount,
  is_broker_involved: fields.brokerName,
  broker_name: fields.brokerName,
  is_lender_involved: fields.lenderName?.length > 0,
  lender_name: fields.lenderName,
  broker_selected_lender_id: fields.lenderSelect,
  applicant_name: fields.applicantName,
  applicant_company: fields.applicantCompany,
  applicant_email: fields.applicantEmail,
  applicant_tel_number: fields.applicantContactNumber,
  additional_information: fields.additionalInformation,
  portfolio_property_count: fields.propertyPortfolioPropertyCount,
  portfolio_description: fields.propertyPortfolioDescription,
  portfolio_valuation_basis: fields.propertyPortfolioValuationBasis,
  previous_report_from_vas: fields.propertyPreviousReportFromVAS,
  re_inspection_type: fields.propertyReInspectionType,
  previous_vp: fields.propertyPreviousVp,
  amendments: fields.propertyAmendments,
  same_report_format: fields.propertySameReportFormat,
  report_format_details: fields.propertyReportFormatDetails,
  valuation_firm: fields.propertyValuationFirm,
  re_inspection: fields.propertyReInspection,
  re_inspection_details: fields.propertyReInspectionDetails,
  previous_visit_commentary: fields.propertyPreviousVisitCommentary,
  development_details: fields.propertyDevelopmentDetails,
  reports: fields.propertyReports,
  inspection_count: fields.propertyInspectionCount,
  contract: fields.propertyContract,
  build_cost: fields.propertyBuildCost,
  remaining_build_cost: fields.propertyRemainingBuildCost,
  contact_ids: Array.isArray(fields.contacts)
    ? fields.contacts.map(({ value }) => value)
    : [],
  report_type: fields.quoteReportType,
  documents: fields.attachments,
  gdv: fields.propertyGrossDevelopmentValue,
  tenancy: fields.propertyTenancyStatus,
  units_count: fields.propertyUnits,
})

const buildFormData = async (fields, uploadPath) => {
  const formData = new FormData()
  const uploadedAttachmentsSignatures = await Promise.all(
    (fields.attachments || []).map(uploadFile(uploadPath))
  )
  const payload = buildPayload({
    ...fields,
    attachments: uploadedAttachmentsSignatures,
  })

  Object.entries(payload).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        formData.append(`quote_request[${key}][]`, v)
      })
    } else {
      if (value) {
        formData.append(`quote_request[${key}]`, value)
      }
    }
  })

  return formData
}

const useQuoteRequestApi = (uploadPath: string) => {
  const [state, setState] = useState<SubmittingState>(null)
  const clearState = () => setState(null)

  const submit = async (isValid, fields) => {
    const csrfToken = document.querySelector<HTMLMetaElement>(
      'meta[name=csrf-token]'
    )?.content
    setState('submitting')

    try {
      if (isValid) {
        const formData = await buildFormData(fields, uploadPath)
        await axios.post('/quote_requests.json', formData, {
          headers: {
            'X-CSRF-Token': csrfToken,
          },
        })
        setState('submitted')
      } else {
        setState('validationError')
      }
    } catch (e) {
      console.error({ e })
      setState('serverError')
    }
  }

  return {
    submit,
    state,
    submitting: state === 'submitting',
    success: state === 'submitted',
    error: state === 'validationError' || state === 'serverError',
    clearState,
  }
}

export default useQuoteRequestApi
