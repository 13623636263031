import React from 'react'
import { Form } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'

import { EMAIL_REGEX } from 'components/helpers';

import fieldNames from 'components/Fields/Base/fieldNames'

import useCanDisplayField from 'components/Fields/Base/useCanDisplayField'
import ConditionalField from 'components/Fields/Base/ConditionalField'
import ErrorMessage from 'components/Fields/Base/ErrorMessage'
import Label from 'components/Fields/Base/Label'

const Applicant: React.FC = () => {
  const { register, errors, getValues, clearErrors } = useFormContext()

  const canDisplayCompany = useCanDisplayField(fieldNames.applicantCompany)
  const canDisplayName = useCanDisplayField(fieldNames.applicantName)

  const validateApplicantCompanyOrName = (otherFieldName) => (value) => {
    const otherField = getValues(otherFieldName)
    const isValid = value.length > 0 || otherField?.length > 0

    if (isValid) {
      clearErrors(otherFieldName)
    }
    return isValid || 'at least one must be present'
  }

  const applicantCompanyOrNameError = (fieldName, otherFieldName) => {
    const canDisplayOtherField = useCanDisplayField(otherFieldName)

    return canDisplayOtherField
      ? errors[fieldName] || errors[otherFieldName]
      : errors[fieldName]
  }

  return (
    <>
      <div className="border rounded p-3 mb-3">
        <ConditionalField field={fieldNames.applicantCompany}>
          <Form.Group controlId={fieldNames.applicantCompany}>
            <Label
              field={fieldNames.applicantCompany}
              label="Applicant company name"
              hint="We request this information so our valuers can check for any conflicts of interests."
            />
            <Form.Control
              type="text"
              name={fieldNames.applicantCompany}
              isInvalid={applicantCompanyOrNameError(
                fieldNames.applicantCompany,
                fieldNames.applicantName
              )}
              ref={register(
                canDisplayName
                  ? {
                      validate: validateApplicantCompanyOrName(
                        fieldNames.applicantName
                      ),
                    }
                  : { required: true }
              )}
            />
            {!canDisplayName && (
              <ErrorMessage error={errors[fieldNames.applicantCompany]} />
            )}
          </Form.Group>
        </ConditionalField>
        <ConditionalField field={fieldNames.applicantName}>
          <Form.Group controlId={fieldNames.applicantName}>
            <Label
              field={fieldNames.applicantName}
              label="Applicant name"
              hint="We request this information so our valuers can check for any conflicts of interests."
            />
            <Form.Control
              type="text"
              name={fieldNames.applicantName}
              isInvalid={applicantCompanyOrNameError(
                fieldNames.applicantName,
                fieldNames.applicantCompany
              )}
              ref={register(
                canDisplayCompany
                  ? {
                      validate: validateApplicantCompanyOrName(
                        fieldNames.applicantCompany
                      ),
                    }
                  : { required: true }
              )}
            />
            <ErrorMessage
              error={applicantCompanyOrNameError(
                fieldNames.applicantCompany,
                fieldNames.applicantName
              )}
            />
          </Form.Group>
        </ConditionalField>
      </div>
      <ConditionalField field={fieldNames.applicantContactNumber}>
        <Form.Group controlId={fieldNames.applicantContactNumber}>
          <Form.Label>Applicant contact number</Form.Label>
          <Form.Control
            type="text"
            name={fieldNames.applicantContactNumber}
            ref={register}
          />
        </Form.Group>
      </ConditionalField>
      <ConditionalField field={fieldNames.applicantEmail}>
        <Form.Group controlId={fieldNames.applicantEmail}>
          <Form.Label>Applicant email</Form.Label>
          <Form.Control
            type="email"
            name={fieldNames.applicantEmail}
            isInvalid={errors[fieldNames.applicantEmail]}
            ref={register({
              pattern: EMAIL_REGEX,
            })}
          />
          {errors[fieldNames.applicantEmail] && (
            <ErrorMessage error={errors[fieldNames.applicantEmail]} />
          )}
        </Form.Group>
      </ConditionalField>
    </>
  )
}

export default Applicant
