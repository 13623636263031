import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'

import fieldsNames from 'components/Fields/Base/fieldNames'

const redirectTimeout: number = 3000

const RedirectToLegacyForm: React.FC = () => {
  const { watch } = useFormContext()

  const requestedReportType = watch(fieldsNames.requestedReportType)
  const requestedPropertySector = watch(fieldsNames.requestedPropertySector)
  const requestedPropertyType = watch(fieldsNames.requestedPropertyType)

  useEffect(() => {
    setTimeout(() => {
      window.location.href =
        '/quote_requests/new?old_form=true' +
        `&requested_report_type=${requestedReportType || ''}` +
        `&requested_property_sector=${requestedPropertySector || ''}` +
        `&requested_property_type=${requestedPropertyType || ''}`
    }, redirectTimeout)
  }, [requestedReportType, requestedPropertySector, requestedPropertyType])

  return (
    <div>
      <Alert variant="primary">
        We are redirecting you to your quote form...
      </Alert>
    </div>
  )
}

export default RedirectToLegacyForm
