import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["input"];

    filter() {
        const filterText = this.inputTarget.value.toUpperCase();
        const tableRows = document.getElementById("searchlist").getElementsByTagName("tr");

        Array.from(tableRows).forEach(row => this.toggleVisibility(row, filterText));
    }

    toggleVisibility(row, filterText) {
        const firstCell = row.getElementsByTagName("td")[0];
        if (!firstCell) return;

        const cellText = firstCell.textContent || firstCell.innerText;
        row.style.display = cellText.toUpperCase().includes(filterText) ? "" : "none";
    }
}
