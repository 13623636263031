import React from 'react'
import { Form } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'
import ReactSelect from 'react-select'

import fieldNames from 'components/Fields/Base/fieldNames'
import { Contact } from 'components/types'

import ConditionalField from 'components/Fields/Base/ConditionalField'

interface Props {
  contacts: Contact[]
}

const Contacts: React.FC<Props> = ({ contacts }) => {
  const { control } = useFormContext()

  return (
    <ConditionalField field={fieldNames.contacts}>
      <Form.Group controlId={fieldNames.contacts}>
        <Form.Label>
          Contact persons(s) who you wish to be included in all correspondence
        </Form.Label>
        <Controller
          defaultValue=""
          name={fieldNames.contacts}
          control={control}
          render={(props) => (
            <ReactSelect
              isMulti
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'rgba(45, 33, 98, 0.5)',
                  primary25: 'rgba(45, 33, 98, 0.15)',
                },
              })}
              options={contacts}
              placeholder={'-- Please select --'}
              {...props}
            />
          )}
        />
      </Form.Group>
    </ConditionalField>
  )
}

export default Contacts
