import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu"]

  connect() {
    document.addEventListener("click", this.hideAllMenus.bind(this))
    document.addEventListener("contextmenu", this.hideAllMenus.bind(this))
    window.addEventListener("scroll", this.hideAllMenus.bind(this))
    this.scrollContainerBoard = document.querySelector('.board')
    this.scrollContainerBoard.addEventListener("scroll", this.hideAllMenus.bind(this))
    this.scrollContainerList = document.querySelector('.board-list-wrapper-frame')
    this.scrollContainerList.addEventListener("scroll", this.hideAllMenus.bind(this))
    this.scrollContainerColumn = document.querySelector('.board-list__content')
    this.scrollContainerColumn.addEventListener("scroll", this.hideAllMenus.bind(this))
  }

  disconnect() {
    document.removeEventListener("click", this.hideAllMenus)
    document.removeEventListener("contextmenu", this.hideAllMenus)
    window.removeEventListener("scroll", this.hideAllMenus)
    this.scrollContainerBoard.removeEventListener("scroll", this.hideAllMenus)
    this.scrollContainerList.removeEventListener("scroll", this.hideAllMenus)
  }

  showMenu(event) {
    event.preventDefault()
    event.stopPropagation()
    
    const valuationElement = event.target.closest('[data-val]')
    if (!valuationElement) return
    
    const valuationData = JSON.parse(valuationElement.dataset.val)
    const valuationId = valuationData.id

    this.hideAllMenus()
    const menu = document.getElementById(`context-menu-valuation-${valuationId}`)
    if (menu) {
      menu.classList.remove("hidden")
      menu.style.left = `${event.clientX}px`
      menu.style.top = `${event.clientY}px`
    }
  }

  hideAllMenus() {
    document.querySelectorAll('.context-menu').forEach(menu => {
      menu.classList.add("hidden")
    })
  }

  openUrl(event) {
    event.preventDefault()
    event.stopPropagation()
    const url = event.currentTarget.getAttribute("href")
    window.location.href = url
  }

  openUrlInNewTab(event) {
    event.preventDefault()
    event.stopPropagation()
    const url = event.currentTarget.getAttribute("href")
    window.open(url, '_blank')
    this.hideAllMenus()
  }
}
