import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'pane']
  initialize() {
    document.addEventListener('DOMContentLoaded', () => {
      this.activateSelectedTab();
    });
  }

  activateSelectedTab(){
    const hash = window.location.hash
    if (hash && this.tabTargets.some(tab => tab.dataset.hash === hash)) {
      this.activateTab(hash);
      this.activatePane(hash);
    }
  }

  activateTab (hash) {
    this.tabTargets.forEach(tab => {
      if (tab.dataset.hash === hash) {
        tab.classList.add('active');
      } else {
        tab.classList.remove('active');
      }
    });
  }

  activatePane (hash) {
    this.paneTargets.forEach(pane => {
      if (`#${pane.id}` === hash) {
        pane.classList.add('active', 'show');
      } else {
        pane.classList.remove('active', 'show');
      }
    });
  }
}
