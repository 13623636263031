import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'multipleEntitiesList', 'multipleEntitiesToggle', 'multipleEntityInput' ]

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.multipleEntitiesToggleTarget.checked) {
      this.multipleEntitiesListTarget.style.display = "block"
      this.multipleEntityInputTargets.forEach((element) => {
        element.disabled = false
      })
    } else {
      this.multipleEntitiesListTarget.style.display = "none";
      this.multipleEntityInputTargets.forEach((element) => {
        element.disabled = true
        if (element.value == "") {
          element.closest('.nested-form-wrapper').remove()
        }
      })
    }
  }
}
