import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'

import fieldsNames from 'components/Fields/Base/fieldNames'

const NoConfirmationModal = ({ onCreateAnotherRequest }) => (
  <Modal show backdrop="static">
    <Modal.Header>
      <Modal.Title>Please Note</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>
        Without this permission we cannot proceed. Please call the team to
        discuss on <a href="tel:01642 262217">01642 262217</a>
      </p>
    </Modal.Body>

    <Modal.Footer>
      <Button 
        variant="link" 
        onClick={() => window.location.href = '/valuations'}
      >
        Go to Dashboard
      </Button>
      <Button variant="primary" onClick={onCreateAnotherRequest}>
        Create another quote request
      </Button>
    </Modal.Footer>
  </Modal>
)

/*
  NOTE: When user confirms and proceeds with the requested report type,
        then appropriate information is added to staff email.
        For more details check app/views/quote_request_mailer/notify_staff.html.haml
*/
const ConfirmationModal = ({ onContinue, onNoPermission }) => {
  return (
    <Modal show backdrop="static">
      <Modal.Header>
        <Modal.Title>Please Note</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          If the original report has been recently issued,
          please could you confirm that you are happy for us to approach
          the previous lender for confirmation that their file is closed
          in advance of acting on this quote request.
        </p>
        <p>Please confirm that you are happy to proceed on this basis.</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={onNoPermission}>
          No
        </Button>
        <Button variant="primary" onClick={onContinue}>
          Yes, continue
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const ReAddressConfirmation = ({ onResetForm }) => {
  const { watch } = useFormContext()
  const reportType = watch(fieldsNames.requestedReportType)
  const [needsPermission, setNeedsPermission] = useState(false)
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showNoConfirmationModal, setShowNoConfirmationModal] = useState(false)

  useEffect(() => {
    if (reportType === 're_address_of_vas_panel_report' && !needsPermission && !hasConfirmed) {
      setNeedsPermission(true)
      setShowConfirmationModal(true)
    }
  }, [reportType, needsPermission, hasConfirmed])

  if (showConfirmationModal) {
    return (
      <ConfirmationModal
        onNoPermission={() => {
          setShowConfirmationModal(false)
          setShowNoConfirmationModal(true)
        }}
        onContinue={() => {
          setHasConfirmed(true)
          setNeedsPermission(false)
          setShowConfirmationModal(false)
        }}
      />
    )
  }

  if (showNoConfirmationModal) {
    return (
      <NoConfirmationModal
        onCreateAnotherRequest={() => {
          setShowNoConfirmationModal(false)
          onResetForm()
        }}
      />
    )
  }

  return null
}

export default ReAddressConfirmation
