import React from 'react'
import { Alert, Form } from 'react-bootstrap'
import { useFormContext, ValidationRules } from 'react-hook-form'

import { FieldName, HelpText } from 'components/types'
import Label from 'components/Fields/Base/Label'
import ErrorMessage from 'components/Fields/Base/ErrorMessage'
import useFieldLabel from 'components/Fields/Base/useFieldLabel'
import useFieldLabelHint from 'components/Fields/Base/useFieldLabelHint'
import useFieldHelpTexts from 'components/Fields/Base/useFieldHelpTexts'

interface Props {
  field: FieldName
  label?: string
  helpTexts?: HelpText[]
  placeholder?: string
  hint?: string
  rows: number
  validationRules?: ValidationRules
}

const TextAreaField: React.FC<Props> = ({
  field,
  label,
  helpTexts,
  placeholder,
  hint,
  rows,
  validationRules,
}) => {
  const { register, errors } = useFormContext()
  const schemaLabel = useFieldLabel(field)
  const schemaLabelHint = useFieldLabelHint(field)
  const schemaHelpTexts = useFieldHelpTexts(field)

  return (
    <Form.Group controlId={field}>
      {(schemaLabel || label) && (
        <Label
          field={field}
          label={schemaLabel || label}
          hint={schemaLabelHint || hint}
        />
      )}
      {(schemaHelpTexts || helpTexts)?.map((helpText, idx) => (
        <Alert variant="info" key={idx}>
          {helpText}
        </Alert>
      ))}
      <Form.Control
        as="textarea"
        rows={rows}
        name={field}
        placeholder={placeholder}
        isInvalid={errors[field]}
        ref={register(validationRules)}
      />
      {errors[field] && <ErrorMessage error={errors[field]} />}
    </Form.Group>
  )
}
export default TextAreaField
