import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import FormattedNumberField from 'components/Fields/Base/FormattedNumberField'

const PropertyAnnualRent: React.FC = () => (
  <ConditionalField field={fieldNames.propertyAnnualRent}>
    <FormattedNumberField
      field={fieldNames.propertyAnnualRent}
      prefix="£"
      label="Annual rent"
      validationRules={{
        max: {
          value: 999999999,
          message: 'must be less than 999,999,999',
        },
      }}
    />
  </ConditionalField>
)

export default PropertyAnnualRent
