import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  before (e) {
    $(e.target).tooltip('hide')
  }

  updateReminder (e) {
    const [data] = e.detail
    let link = data.querySelector('a')
    $(link).tooltip({ boundary: 'viewport' })
    $(link).append(e.target.text)
    e.target.replaceWith(link)
  }
}
