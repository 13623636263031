import React from 'react'
import { useFormContext } from 'react-hook-form'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import FormattedNumberField from 'components/Fields/Base/FormattedNumberField'

const PropertyGrossDevelopmentValue: React.FC = () => {
  const { getValues, } = useFormContext()
  const isGDVRequired = () =>
      (getValues(fieldNames.requestedReportType) != "re_inspection_of_property") &&
        (getValues(fieldNames.propertyRefurbishment) != "unknown")
  const fieldLabelRequired = () => isGDVRequired() ? " *" : ""

  return (
    <ConditionalField field={fieldNames.propertyGrossDevelopmentValue}>
      <FormattedNumberField
        field={fieldNames.propertyGrossDevelopmentValue}
        prefix="£"
        label={"Gross Development Value (GDV)" + fieldLabelRequired()}
        validationRules={{
          required: isGDVRequired(),
          max: {
            value: 999999999,
            message: 'must be less than 999,999,999',
          },
        }}
      />
    </ConditionalField>
  )
}

export default PropertyGrossDevelopmentValue
