import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['actions', 'quote', 'reportSelectedQuotesButton']

  select (e) {
    e.target.closest('.quote').classList.toggle('selected')
    this.toggleActions()
  }

  setParams (e) {
    this.reportSelectedQuotesButtonTarget.disabled = true
    this.reportSelectedQuotesButtonTarget.classList.add('disabled')
    let quotes = []
    for (const quote of this.selectedQuotes) {
      let checkBox = quote.querySelector('input[type=checkbox]:checked')
      quotes.push({ id: checkBox.value, event: 'report' })
    }
    let params = { valuation: { quotes_attributes: quotes } }
    if (e.currentTarget.dataset.event) {
      params['valuation']['event'] = e.currentTarget.dataset.event
    }
    params['valuation']['send_quote_report_email'] = $('#valuation_send_quote_report_email').prop('checked') ? '1' : '0'
    e.currentTarget.dataset.params = $.param(params)
  }

  unsetParams (e) {
    delete e.currentTarget.dataset.params
    Turbo.visit(e.currentTarget.href)
  }

  toggleActions () {
    if (this.selectedQuotes.length) {
      this.actionsTarget.removeAttribute('hidden')
    } else {
      this.actionsTarget.setAttribute('hidden', 'hidden')
    }
  }

  get selectedQuotes () {
    let selectedTargets = []
    for (const quote of this.quoteTargets) {
      if (quote.classList.contains('selected')) selectedTargets.push(quote)
    }
    return selectedTargets
  }
}
