import React from 'react'
import { Alert, Form } from 'react-bootstrap'
import { useFormContext, ValidationRules } from 'react-hook-form'

import { FieldName, HelpText } from 'components/types'
import useFieldValues from 'components/Fields/Base/useFieldValues'
import ErrorMessage from 'components/Fields/Base/ErrorMessage'
import Label from 'components/Fields/Base/Label'
import SelectOptions from 'components/Fields/Base/SelectOptions'
import useFieldLabel from 'components/Fields/Base/useFieldLabel'
import useFieldLabelHint from 'components/Fields/Base/useFieldLabelHint'
import useFieldHelpTexts from 'components/Fields/Base/useFieldHelpTexts'
import useFieldValue from 'components/Fields/Base/useFieldValue'
import useHideField from 'components/Fields/Base/useHideField'

interface Props {
  field: FieldName
  label?: string
  helpTexts?: HelpText[]
  value?: string
  hint?: string
  validationRules?: ValidationRules
  disabled?: boolean
  hideField?: boolean
}

const SelectField: React.FC<Props> = ({
  field,
  label,
  helpTexts,
  hint,
  value,
  validationRules,
  disabled,
  helpAlertVariant,
  hideField
}) => {
  const { register, errors } = useFormContext()
  const options = useFieldValues(field)
  const schemaLabel = useFieldLabel(field)
  const schemaLabelHint = useFieldLabelHint(field)
  const schemaHelpTexts = useFieldHelpTexts(field)
  const schemaValue = useFieldValue(field)
  const schemaHideField = useHideField(field)

  return (
    <>
      { (schemaHideField || hideField) ? (
        <></>
      ) : (
        <Form.Group controlId={field}>
          {(schemaLabel || label) && (
            <Label
              field={field}
              label={schemaLabel || label}
              hint={schemaLabelHint || hint}
            />
          )}
          {(schemaHelpTexts || helpTexts)?.map((helpText, idx) => (
            <Alert variant={helpAlertVariant || 'info'} key={idx}>
              {helpText}
            </Alert>
          ))}
          <Form.Control
            custom
            as="select"
            name={field}
            disabled={disabled}
            defaultValue={schemaValue || value}
            isInvalid={errors[field]}
            ref={register(validationRules)}
          >
            <SelectOptions options={options} />
          </Form.Control>
          {errors[field] && <ErrorMessage error={errors[field]} />}
        </Form.Group>
      )}
    </>
  )
}

export default SelectField
