import $ from 'jquery'

export default anchorModal

function anchorModal (anchor, modalClass = 'modal') {
  if (anchor) {
    try {
      var el = document.querySelector(`${anchor}.${modalClass}`)
      if (el) $(el).modal('show')
    } catch (e) {}
  }
}
