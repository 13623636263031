export const fieldNames = [
  'userType',
  'requestedReportType',
  'requestedPropertySector',
  'requestedPropertyType',
  'propertyPostcode',
  'propertyAddressLine1',
  'propertyAddressLine2',
  'propertyAddressLine3',
  'propertyAddressLine4',
  'propertyCity',
  'propertyCounty',
  'propertyCountry',
  'propertyCondition',
  'propertyEstimatedValue',
  'propertyGrossDevelopmentValue',
  'propertyOutbuildings',
  'propertyOutbuildingsDetails',
  'propertyLand',
  'propertyLandDetails',
  'propertyRefurbishment',
  'propertyRefurbishmentDetails',
  'propertyResidentialStatus',
  'propertyAnnualRent',
  'propertyTenancyStatus',
  'propertyTenants',
  'propertyTenure',
  'propertyUnits',
  'propertyValuationBasis',
  'propertyLeasehold',
  'propertyLeaseholdDetails',
  'propertyAdditionalInformation',
  'propertyPortfolioPropertyCount',
  'propertyPortfolioDescription',
  'propertyPortfolioValuationBasis',
  'propertyAmendments',
  'propertySameReportFormat',
  'propertyReportFormatDetails',
  'propertyValuationFirm',
  'propertyReInspection',
  'propertyReInspectionType',
  'propertyPreviousReportFromVAS',
  'propertyPreviousVp',
  'propertyPreviousVisitCommentary',
  'propertyDevelopmentDetails',
  'propertyReports',
  'propertyReInspectionDetails',
  'propertyInspectionCount',
  'propertyBuildCost',
  'propertyRemainingBuildCost',
  'propertyContract',
  'loanType',
  'loanCharge',
  'loanAmount',
  'loanPurpose',
  'lenderReferenceNumber',
  'lenderAddressee',
  'selectedLenderName',
  'broker',
  'brokerName',
  'lenderName',
  'lenderSelect',
  'applicantCompany',
  'applicantName',
  'applicantContactNumber',
  'applicantEmail',
  'additionalInformation',
  'attachments',
  'contacts',
  'quoteReportType',
  'addresses',
] as const

export type FieldName = typeof fieldNames[number]

export type Rules = {
  [key in FieldName]?: number | string | Array<number | string>
}

export interface Option {
  id: number | string
  name: string
}

export type HelpText = string

export interface Schema {
  rules: Rules
  values: Option[]
  value: string
  label: string
  labelHint: string
  helpTexts: HelpText[]
  placeholder: string
  hideField: string
}

export type Contact = {
  value: string
  label: string
}

export type SupportedRequestedReportTypes = string[]
export type SupportedRequestedPropertySectors = string[]

export type SubmittingState =
  | 'submitting'
  | 'submitted'
  | 'validationError'
  | 'serverError'

export type UserType = 'broker' | 'lender'

export type FormInputs = { [key in FieldName]: any }

export type FormSettings = { [key in FieldName]?: Schema[] }
