import { Controller } from 'stimulus'
import Trix from "trix";

export default class extends Controller {
  static targets = ['colour', 'button']

  connect () {
    this.trixSelectionChangeHandler = this.toggleButtonClass.bind(this)
    this.editor.element.addEventListener('trix-selection-change', this.trixSelectionChangeHandler)

    this.dialogShow = this.dialogShowHandler.bind(this)
    this.editor.element.addEventListener('trix-toolbar-dialog-show', this.dialogShow)

    this.dialogHide = this.dialogHideHandler.bind(this)
    this.editor.element.addEventListener('trix-toolbar-dialog-hide', this.dialogHide)

    this.colourChangeHandler = this.changeColor.bind(this)
    this.initToolbar()

    for (const colour of this.colourTargets) {
      colour.addEventListener('click', this.colourChangeHandler, { bubbles: false })
    }
  }

   initToolbar()  {
    this.toolbar.innerHTML = Trix.config.toolbar.getDefaultHTML();
  }

  toggleButtonClass (event) {
    if (this.editor.attributeIsActive('foregroundColor')) {
      this.buttonTarget.classList.add('trix-active')
    } else {
      this.buttonTarget.classList.remove('trix-active')
    }
  }

  dialogShowHandler (event) {
    this.setCurrentColor()
    if (this.hasColourTarget) this.colorInput(this.colourTarget).focus()
  }

  dialogHideHandler (event) {
    this.clearSelection()
  }

  setCurrentColor () {
    let color = this.editor.composition.getCurrentTextAttributes().foregroundColor

    for (const colour of this.colourTargets) {
      let input = this.colorInput(colour)
      if (input.value === color) input.checked = true
      else input.checked = false
    }
  }

  clearSelection () {
    for (const colour of this.colourTargets) {
      this.colorInput(colour).checked = false
    }
  }

  changeColor (event) {
    event.preventDefault()
    let color = this.colorInput(event.currentTarget).value
    let currentColor = this.editor.composition.getCurrentTextAttributes().foregroundColor
    if (color === currentColor) {
      this.editor.deactivateAttribute('foregroundColor')
    } else {
      this.editor.activateAttribute('foregroundColor', color)
    }
    this.editor.element.focus()
  }

  trixDialog (elem) {
    return elem.closest('.trix-dialog')
  }

  colorInput (elem) {
    return elem.querySelector('input')
  }

  get toolbar () {
    if (this.element.tagName === 'TRIX-TOOLBAR') return this.element
    let element = this.element.querySelector('trix-toolbar')
    if (element) return element
    return this.element.closest('trix-toolbar')
  }

  get editor () {
    let editorElem = document.querySelector(`[toolbar='${this.toolbar.id}']`)
    return editorElem.editor
  }
}
