import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import TextAreaField from 'components/Fields/Base/TextAreaField'

const PropertyPortfolioDescription: React.FC = () => (
  <ConditionalField field={fieldNames.propertyPortfolioDescription}>
    <TextAreaField
      field={fieldNames.propertyPortfolioDescription}
      label="Property/portfolio description *"
      helpTexts={[
        'Please provide all additional addresses here or upload an excel file in section 5. Please provide full details including individual descriptions, Estimated Values, any rental income etc.',
      ]}
      rows={3}
      validationRules={{ required: true }}
    />
  </ConditionalField>
)

export default PropertyPortfolioDescription
