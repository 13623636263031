import React from 'react'
import { Alert, Form, InputGroup } from 'react-bootstrap'
import { useFormContext, Controller, ValidationRules } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { FieldName, HelpText } from 'components/types'
import ErrorMessage from 'components/Fields/Base/ErrorMessage'
import Label from 'components/Fields/Base/Label'
import useFieldHelpTexts from 'components/Fields/Base/useFieldHelpTexts'
import useFieldLabel from 'components/Fields/Base/useFieldLabel'
import useFieldLabelHint from 'components/Fields/Base/useFieldLabelHint'

interface Props {
  field: FieldName
  prefix?: string
  label?: string
  helpTexts?: HelpText[]
  hint?: string
  validationRules?: ValidationRules
  decimalScale?: number
  allowNegative?: boolean
}

const FormattedNumberField: React.FC<Props> = ({
  field,
  prefix,
  label,
  helpTexts,
  hint,
  validationRules,
  decimalScale,
  allowNegative
}) => {
  const { control, errors } = useFormContext()
  const schemaLabel = useFieldLabel(field)
  const schemaLabelHint = useFieldLabelHint(field)
  const schemaHelpTexts = useFieldHelpTexts(field)

  return (
    <Form.Group controlId={field}>
      {(schemaLabel || label) && (
        <Label
          field={field}
          label={schemaLabel || label}
          hint={schemaLabelHint || hint}
        />
      )}
      {(schemaHelpTexts || helpTexts)?.map((helpText, idx) => (
        <Alert variant="info" key={idx}>
          {helpText}
        </Alert>
      ))}
      <InputGroup>
        {prefix && (
          <InputGroup.Prepend>
            <InputGroup.Text>{prefix}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Controller
          defaultValue=""
          name={field}
          rules={validationRules}
          control={control}
          render={({ onChange, ...props }) => (
            <NumberFormat
              customInput={Form.Control}
              decimalScale={decimalScale}
              allowNegative={allowNegative}
              thousandSeparator
              isInvalid={errors[field]}
              onValueChange={({ value }) => {
                onChange(value.length > 0 ? Number(value) : undefined)
              }}
              {...props}
            />
          )}
        />
        {errors[field] && <ErrorMessage error={errors[field]} />}
      </InputGroup>
    </Form.Group>
  )
}

export default FormattedNumberField
