import { Controller } from 'stimulus'
import $ from 'jquery'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['sendEmail', 'notifyEmail']

  connect() {
    $('.collapse').on('hide.bs.collapse', (e) => {
      const sendEmail = this.sendEmailTarget
      const notifyEmail = this.notifyEmailTarget

      if (!sendEmail.checked && !notifyEmail.checked) {
        $('#collapseOne').collapse('show')
      }

      if (e.target.id === 'collapseShouldEmail' && e.type === 'hide') {
        if (sendEmail.checked) {
          sendEmail.click()
        }

        if (notifyEmail.checked) {
          notifyEmail.click()
        }
      }
    })
  }

  postNote(e) {
    e.preventDefault()
    const addNoteBtn = document.querySelector('#addNote');
    const noteForm = addNoteBtn.form;
    if (!noteForm.checkValidity()) {
      return noteForm.reportValidity()
    }

    Rails.ajax({
      type: "POST",
      url: noteForm.action,
      data: new FormData(noteForm),
      success: (noteId) => {
        if (!noteId) return

        location.hash = '#note_' + noteId
        location.reload()
      },
      error: (err) => {
        const errors = Object.keys(err).map(x => `${x}: ${err[x].join(', ')}`)
        alert(`Errors:\n\n${errors.join('\n\n')}`)
      }
    })
  }
}
