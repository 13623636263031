import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ["category"]

  formatState(state) {
    if (!state.id) {
      return state.text;
    }
    const color = state.element.getAttribute('class');
    const style = state.element.getAttribute('style');
    return $(
      '<span class="' + color + '" style="' + style + '">' + state.text + '</span>'
    );
  };

  connect() {
    $(this.categoryTarget).select2({templateResult: this.formatState, templateSelection: this.formatState})
  }

  disconnect() {
    $(this.categoryTarget).select2('destroy')
  }
}
