import { Controller } from 'stimulus'

export default class extends Controller {
  toggleNoteAndEmailForm (event) {
    event.preventDefault()
    let a = event.currentTarget
    a.parentElement.parentElement.querySelector('li > a.active').classList.remove('active')
    a.classList.add('active')
    let selectedForm = a.dataset.tab
    var forms = document.body.getElementsByClassName('note-email-forms')
    Array.prototype.filter.call(forms, function (el) {
      el.style.display = 'none'
    })
    document.body.querySelector('#' + selectedForm).style.display = 'block'
  }
}
