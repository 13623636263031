import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'

const PropertyCondition: React.FC = () => (
  <ConditionalField field={fieldNames.propertyCondition}>
    <SelectField
      field={fieldNames.propertyCondition}
      label="Property condition *"
      hint="This information is important as it will affect the type of valuer we select."
      validationRules={{ required: true }}
    />
  </ConditionalField>
)

export default PropertyCondition
