import Label from 'components/Fields/Base/Label'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'

import fieldNames from 'components/Fields/Base/fieldNames'
import useFieldValues from 'components/Fields/Base/useFieldValues'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import ErrorMessage from 'components/Fields/Base/ErrorMessage'

const QuoteReportType: React.FC = () => {
  const { register, errors } = useFormContext()

  const quoteReportTypeOptions = useFieldValues(fieldNames.quoteReportType)

  return (
    <ConditionalField field={fieldNames.quoteReportType}>
      <Form.Group controlId={fieldNames.quoteReportType}>
        <Label
          field={fieldNames.quoteReportType}
          label="Report type *"
          hint="If you are unsure contact our Quotes Team on 01642 262217 or alternatively email VASQuotes@vas-group.co.uk."
        />
        {quoteReportTypeOptions.map((quoteReportTypeOption, idx) => (
          <Form.Check
            key={quoteReportTypeOption.id}
            type="radio"
            name={fieldNames.quoteReportType}
            id={quoteReportTypeOption.name}
            label={quoteReportTypeOption.name}
            value={quoteReportTypeOption.id}
            defaultChecked={idx == 0}
            ref={register}
            className="mb-2"
          />
        ))}
        {errors[fieldNames.quoteReportType] && (
          <ErrorMessage error={errors[fieldNames.quoteReportType]} />
        )}
      </Form.Group>
    </ConditionalField>
  )
}

export default QuoteReportType
