import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'

const PropertyTenure: React.FC = () => (
  <ConditionalField field={fieldNames.propertyTenure}>
    <SelectField
      field={fieldNames.propertyTenure}
      label="Tenure *"
      validationRules={{ required: true }}
    />
  </ConditionalField>
)

export default PropertyTenure
