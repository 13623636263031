import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['destroy']

  addRule () {
    this.destroyTarget.value = false
  }

  removeRule () {
    this.destroyTarget.value = true
  }
}
