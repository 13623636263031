import { Controller } from "@hotwired/stimulus";
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ['datePicker'];

  connect() {
    this.initializeDatePickers();
    this.addValidationListeners();
    this.disableAutocomplete();
    this.addCalendarIcon();
  }

  disconnect() {
    if (this.datepickers) {
      this.datepickers.forEach(datepicker => {
        if (datepicker && typeof datepicker.destroy === 'function') {
          datepicker.destroy();
        }
      });
    }
    
    this.datePickerTargets.forEach(datePickerInput => {
      const wrapper = datePickerInput.closest('.input-group-sm')
      if (wrapper) {
        wrapper.parentNode.insertBefore(datePickerInput, wrapper)
        wrapper.remove()
      }
    })
  }

  initializeDatePickers() {
    this.datepickers = [];
    this.datePickerTargets.forEach(element => {
      try {
        const datepicker = new AirDatepicker(element, { 
          locale: localeEn,
          autoClose: true,
          dateFormat: 'dd/mm/yyyy',
          container: element.closest('.modal-content') || '.air-datepicker-global-container',
          position({$datepicker, $target, $pointer, done}) {
            const popper = createPopper($target, $datepicker, {
              placement: 'bottom-start',
              modifiers: [
                {
                  name: 'flip',
                  options: {
                    padding: {
                      top: 64
                    }
                  }
                },
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10]
                  }
                },
                {
                  name: 'arrow',
                  options: {
                    element: $pointer
                  }
                }
              ]
            });

            return function completeHide() {
              popper.destroy();
              done();
            }
          },
          toggleSelected: ({datepicker, date}) => {
            const currentValue = element.value;
            const newValue = this.formatDate(date);
            if (currentValue === newValue) {
              datepicker.hide();
              return false;
            }
            return true;
          },
          onSelect: ({ date }) => {
            element.value = this.formatDate(date);
            setTimeout(() => {
              element.classList.remove('is-invalid');
              this.removeValidationMessage(element);
            }, 0);
          },
          onShow: () => {
            this.setPickerZIndex();
          },
          onChangeView: () => {
            this.setPickerZIndex();
          },
          firstDay: 1,
          minDate: element.dataset.minDateToday === 'true' ? new Date() : false,
        });
        // Prevent label click from triggering the datepicker
        const label = document.querySelector(`label[for="${element.id}"]`);
        if (label) {
          label.addEventListener('click', (event) => {
            event.preventDefault();
          });
        }
        this.datepickers.push(datepicker);
      } catch (error) {
        console.warn(`Failed to initialize datepicker for element:`, element, error);
      }
    });
  }

  setPickerZIndex() {
    const visiblePicker = document.querySelector('.air-datepicker-global-container');

    if (visiblePicker) {
      visiblePicker.style.zIndex = "3000";
    }
  }

  formatDate(date) {
    if (!(date instanceof Date)) return '';
    return date.toLocaleDateString('en-GB');
  }


  hideCalendars() {
    if (this.datepickers) {
      this.datepickers.forEach(datepicker => {
        if (datepicker && typeof datepicker.hide === 'function') {
          try {
            datepicker.hide();
          } catch (error) {
            console.warn('Error hiding datepicker:', error);
          }
        }
      });
    }
  }

  addValidationListeners() {
    this.datePickerTargets.forEach(element => {
      element.addEventListener('blur', this.validateDate.bind(this));
      element.addEventListener('change', this.validateDate.bind(this));
      element.addEventListener('input', this.formatInput.bind(this));
    });
  }

  formatInput(event) {
    const input = event.target;
    let value = input.value.replace(/[^0-9]/g, '');
    
    if (value.length > 2) value = value.slice(0, 2) + '/' + value.slice(2);
    if (value.length > 5) value = value.slice(0, 5) + '/' + value.slice(5, 9);
    
    input.value = value;
  }

  validateDate(event) {
    const input = event.target;
    const value = input.value;
    
    // Clear any existing validation messages
    this.removeValidationMessage(input);
    
    if (value) {
      // Format validation
      const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
      if (!regex.test(value)) {
        this.addValidationMessage(input, 'Please enter a valid date in DD/MM/YYYY format');
        input.classList.add('is-invalid');
        return false;
      }
      
      // Additional validation (amount of days in month, leap years etc)
      const [day, month, year] = value.split('/');
      const date = new Date(year, month - 1, day);
      if (date.getDate() != day || (date.getMonth() + 1) != month || date.getFullYear() != year) {
        this.addValidationMessage(input, 'Please enter a valid date');
        input.classList.add('is-invalid');
        return false;
      }
    }
    
    input.classList.remove('is-invalid');
    return true;
  }

  addValidationMessage(input, message) {
    const feedback = document.createElement('div');
    feedback.className = 'invalid-feedback';
    feedback.textContent = message;
    input.parentNode.appendChild(feedback);
  }

  removeValidationMessage(input) {
    const feedback = input.parentNode.querySelector('.invalid-feedback');
    if (feedback) {
      feedback.remove();
    }
  }

  addCalendarIcon() {
    this.datePickerTargets.forEach(datePickerInput => {
      const wrapper = document.createElement('div');
      wrapper.className = 'input-group input-group-sm';
      
      const iconDiv = document.createElement('div');
      iconDiv.className = 'input-group-prepend';
      iconDiv.style.display = 'flex';
      
      const iconSpan = document.createElement('span');
      iconSpan.className = 'input-group-text rounded-end-0';
      
      const icon = document.createElement('i');
      icon.className = 'fas fa-regular fa-calendar';
      
      iconSpan.appendChild(icon);
      iconDiv.appendChild(iconSpan);
      
      datePickerInput.parentNode.insertBefore(wrapper, datePickerInput);
      wrapper.appendChild(iconDiv);
      wrapper.appendChild(datePickerInput);
    });
  }

  disableAutocomplete() {
    this.datePickerTargets.forEach(datePicker => {
      datePicker.setAttribute('autocomplete', 'off');
    });
  }
}
