import { FieldName } from 'components/types'

import useFieldSchema from './useFieldSchema'

const useFieldLabel = (fieldName: FieldName): string | null => {
  const schema = useFieldSchema(fieldName)
  return schema?.label
}

export default useFieldLabel
