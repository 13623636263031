import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'postcode',
    'container',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressLine4',
    'conflictCheckLinkModal'
  ]

  connect () {
    this.currentValuationId = document.location.href.split('/')[4].split('?')[0]
    this.conflictCheckLinkModalTarget.href = this.conflictCheckUrlWithArguments('modal')

    fetch(this.conflictCheckUrlWithArguments('json'))
      .then((response) => response.json())
      .then((body) => {
        if (body.length > 0) {
          this.showConflictCheckModal()
        }
      })
  }

  showConflictCheckModal () {
    this.conflictCheckLinkModalTarget.click()
  }

  conflictCheckUrlWithArguments(format) {
    let url = `${this.conflictCheckUrl}.${format}?postcode=${this.postcode}&address=${encodeURIComponent(this.address)}`
    const id = this.currentValuationId
    if (id !== 'new') {
      url += `&id=${id}`
    }
    return url
  }

  get conflictCheckUrl() {
    return this.postcodeTarget.dataset.url
  }

  get postcode() {
    return this.postcodeTarget.value
  }

  get address() {
    return [
      this.addressLine1Target.value,
      this.addressLine2Target.value,
      this.addressLine3Target.value,
      this.addressLine4Target.value
    ].filter(x => x != null && x !== '').join(' ')
  }
}
