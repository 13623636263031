import React, { useState } from 'react'
import axios from 'axios'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import snakeCase from 'lodash/snakeCase'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'
import SelectOptions from 'components/Fields/Base/SelectOptions'
import TextField from 'components/Fields/Base/TextField'

type Address = {
  id: number
  name: string
  raw: any
}

interface Props {
  onAddressSelected: (addressSelected: boolean) => void,
}

const handlePostcodeFormSubmit = (event) => {
  event.preventDefault();
};

const useAddressSearch = (props: Props) => {
  const { setValue, getValues } = useFormContext()
  const [searching, setSearching] = useState<boolean>(false)
  const [addresses, setAddresses] = useState<Address[]>(null)
  const [addressSelected, setAddressSelected] = useState<boolean>(false)

  const cleanUp = () => {
    setAddresses(null)
  }

  const handleAddressSelected = () => {
    setAddressSelected(true)
    props.onAddressSelected(true)
  }

  const buildAddresses = (addresses) => {
    if (addresses.length > 0) {
      return [{
        id: 0,
        name: '=== Type manually ===',
        raw: null,
      }].concat(addresses.map((address, index) => ({
        id: index + 1,
        name: address.formatted_address.filter((e) => e.length > 0).join(', '),
        raw: address,
      })))
    }

    handleAddressSelected()
    return []
  }

  const search = async () => {
    const postcode = getValues(fieldNames.propertyPostcode)
    if (!postcode) {
      return
    }

    cleanUp()
    setSearching(true)
    const response = await axios.get('/properties/search', {
      params: {
        postcode,
        expand: true,
      },
    })
    setSearching(false)
    setAddresses(buildAddresses(response?.data?.addresses || []))
  }

  const select = (id) => {
    const address = addresses[id]?.raw

    setValue(fieldNames.propertyAddressLine1, address?.line_1, {
      shouldValidate: true,
    })
    setValue(fieldNames.propertyAddressLine2, address?.line_2, {
      shouldValidate: true,
    })
    setValue(fieldNames.propertyAddressLine3, address?.line_3, {
      shouldValidate: true,
    })
    setValue(fieldNames.propertyAddressLine4, address?.line_4, {
      shouldValidate: true,
    })
    setValue(fieldNames.propertyCity, address?.town_or_city, {
      shouldValidate: true,
    })
    setValue(fieldNames.propertyCounty, address?.county, {
      shouldValidate: true,
    })
    setValue(fieldNames.propertyCountry, snakeCase(address?.country), {
      shouldValidate: true,
    })

    cleanUp()
    handleAddressSelected()
  }

  return {
    addresses,
    search,
    searching,
    select,
    addressSelected,
  }
}

const PropertyAddress: React.FC<Props> = (props) => {
  const { addresses, search, searching, select, addressSelected } = useAddressSearch(props)

  return (
    <>
      <Form onSubmit={handlePostcodeFormSubmit}>
        <Row className="align-items-end">
          <Col sm={9}>
            <ConditionalField field={fieldNames.propertyPostcode}>
              <TextField
                field={fieldNames.propertyPostcode}
                label="Property postcode *"
                validationRules={{ required: true }}
              />
            </ConditionalField>
          </Col>
          <Col sm={3} className="text-right">
            <Form.Group>
              <Button
                variant="primary"
                onClick={search}
                disabled={searching}
                style={{ marginTop: 29 }}
                type="submit"
              >
                {searching ? 'Please wait...' : 'Search'}
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      {addresses && addresses.length > 0 && (
        <Form.Group controlId={fieldNames.addresses}>
          <Form.Control
            custom
            as="select"
            name={fieldNames.addresses}
            onChange={(ev) => select(ev.target.value)}
          >
            <SelectOptions options={addresses} />
          </Form.Control>
        </Form.Group>
      )}
      {addresses && addresses.length === 0 && (
        <div className="fade alert alert-warning show">No results found</div>
      )}
      <Row style={{display: addressSelected ? null : 'none' }}>
        <Col>
          <ConditionalField field={fieldNames.propertyAddressLine1}>
            <TextField
              field={fieldNames.propertyAddressLine1}
              label="Address *"
              validationRules={{ required: true }}
            />
          </ConditionalField>
          <ConditionalField field={fieldNames.propertyAddressLine2}>
            <TextField field={fieldNames.propertyAddressLine2} />
          </ConditionalField>
          <ConditionalField field={fieldNames.propertyAddressLine3}>
            <TextField field={fieldNames.propertyAddressLine3} />
          </ConditionalField>
          <ConditionalField field={fieldNames.propertyAddressLine4}>
            <TextField field={fieldNames.propertyAddressLine4} />
          </ConditionalField>
          <ConditionalField field={fieldNames.propertyCity}>
            <TextField
              field={fieldNames.propertyCity}
              label="City *"
              validationRules={{ required: true }}
            />
          </ConditionalField>
          <ConditionalField field={fieldNames.propertyCounty}>
            <TextField
              field={fieldNames.propertyCounty}
              label="County *"
              validationRules={{ required: true }}
            />
          </ConditionalField>
          <ConditionalField field={fieldNames.propertyCountry}>
            <SelectField field={fieldNames.propertyCountry} label="Country" />
          </ConditionalField>
        </Col>
      </Row>
    </>
  )
}

export default PropertyAddress
