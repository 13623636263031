import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form", 
    "submitButton", 
    "send_quote_report_email_checkbox_label", 
    "send_quote_report_email_checkbox"
  ]

  connect() {
    this.toggleButton()
  }

  toggleButton() {
    const selectedQuotes = document.querySelectorAll('input[name="valuation[quote_ids][]"]:checked')
    const hasSelectedQuotes = selectedQuotes.length > 0

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.toggle('d-none', !hasSelectedQuotes)
    }

    if (this.hasSend_quote_report_email_checkbox_labelTarget) {
      this.send_quote_report_email_checkbox_labelTarget.classList.toggle('d-none', !hasSelectedQuotes)
    }

    if (this.hasSend_quote_report_email_checkboxTarget) {
      this.send_quote_report_email_checkboxTarget.classList.toggle('d-none', !hasSelectedQuotes)
    }
  }

  doNothing(event) {
    event.preventDefault()
    event.stopPropagation()
    return false
  }
}
