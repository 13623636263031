import { Controller } from 'stimulus'
import $ from 'jquery'
import broker from "../components/Fields/Broker";

export default class extends Controller {
  static targets = ['fixedFeeContainer', 'propertyType',
    'propertyTypeId', 'quoteRequestId',
    'propertyCondition', 'propertyRefurbishment',
    'propertyOutbuildings', 'propertyLandsize',
    'propertyTenure', 'inspectionCount',
    'propertyTimeleft', 'sameReport',
    'reInspection', 'quoteRequestor',

    'lenderContacts', 'lenderAddressee', 'brokerContacts',
    'inspectionCountDiv', 'reportFormatDiv',
    'reInspectionDiv', 'outbuildingsDiv',
    'landsizeDiv', 'ledByApi', 'apiAccessDiv',
    'newBuildHouse', 'newBuildFlat'
  ]

  timeoutId = null

  connect() {
    if (this.quoteRequestId) {
      this.blockValuation()
    }
    this.toggleCondition()
    this.toggleOutbuildings()
    this.toggleLandsize()
    this.toggleTenure()
    this.updatePropertyType()
    this.toggleReportFormatDetails()
    this.toggleReInspectionDetails()
    this.toggleInspectionCount()
    this.onQuoteRequestorChange()
    this.setLenderSelectField();
    this.setBrokerSelectField();
    this.setLenderContactsSelectField();
    this.setBrokerContactsSelectField();
    this.setLenderAddresseeSelectField();
    this.toggleApiAccess()
  }

  disconnect() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    if (this.quoteRequestId) {
      this.unblockValuation();
    }

    $('#lenderselect select').select2('destroy');
    $('#brokerselect select').select2('destroy');
    $('#lendercontacts select').select2('destroy');
    $('#lenderaddressee select').select2('destroy');
    $('#brokercontacts select').select2('destroy');
  }

  onQuoteRequestorChange() {
    const requestor_value = this.quoteRequestorTarget.value
    this.hideShowContactFields();
    this.indicateCurrentRequestor(requestor_value);
  }

  hideShowContactFields() {
    const requestor_value = this.quoteRequestorTarget.value

    this.toggleInputDisabled(
      this.brokerContactsTarget,
      requestor_value !== 'Broker' || !$('#brokerselect select').val()
    )
    this.toggleInputDisabled(
      this.lenderContactsTarget,
      (requestor_value !== 'Broker' && requestor_value !== 'Lender') || !$('#lenderselect select').val()
    )
  }

  setLenderSelectField() {
    const $select = $('#lenderselect select');
    
    if ($select.hasClass('select2-hidden-accessible')) {
      return;
    }
    
    $select.select2({
      width: '100%',
      allowClear: true,
      placeholder: 'Choose lender',
      ajax: {
        delay: 250,
        url: '/lenders.json',
        data: (params) => {
          const brokerSelect = document.querySelector('#brokerselect select');
          const requestor_value = this.quoteRequestorTarget.value;

          params.for_broker_id = brokerSelect.value
          params.requestor_value = requestor_value

          return params;
        },
        processResults: (data) => {
          return {
            results: (data || []).map((lender) => {
              return {
                id: lender.id,
                text: lender.name,
                lender_addressee_id: lender.main_lender_addressee_id,
                lender_addressee_name: lender.main_lender_addressee_name
              }
            })
          };
        }
      },
    }).on('change', (args) => {
      $('#lendercontacts select').val(null).trigger('change');
      $('#lenderaddressee select').val(null).trigger('change');
      this.hideShowContactFields();
    });

    $('#lenderselect select').on('select2:select', function (e) {
      var data = e.params.data;
      if (data.lender_addressee_id != null)
      {
        var newOption = new Option(data.lender_addressee_name, data.lender_addressee_id, false, true);
        $('#lenderaddressee select').append(newOption).trigger('change');
      }
    });

  }

  setBrokerSelectField() {
    const $select = $('#brokerselect select');
    
    if ($select.hasClass('select2-hidden-accessible')) {
      return;
    }

    $select.select2({
      width: '100%',
      allowClear: true,
      placeholder: 'Choose broker',
      ajax: {
        delay: 250,
        url: '/brokers.json',
        data: (params) => {
          const lenderSelect = document.querySelector('#lenderselect select');
          const requestor_value = this.quoteRequestorTarget.value;

          params.for_lender_id = lenderSelect.value
          params.requestor_value = requestor_value

          return params;
        },
        processResults: (data) => {
          return {results: (data || [])};
        }
      }
    }).on('change', (args) => {
      $('#brokercontacts select').val(null).trigger('change');
      this.hideShowContactFields();
    });
  }

  setBrokerContactsSelectField() {
    const $select = $('#brokercontacts select');
    
    if ($select.hasClass('select2-hidden-accessible')) {
      return;
    }

    $select.select2({
      width: '100%',
      allowClear: true,
      placeholder: 'Choose broker contacts',
      multiple: true,
      ajax: {
        delay: 250,
        url: function (params) {
          return '/brokers/' + $('#brokerselect select').val() + '/contacts.json';
        },
        processResults: (data) => {
          return {
            results: (data || []).map((contact) => {
              return {
                id: contact.id,
                text: contact.name
              }
            })
          };
        }
      }
    });

  }

  setLenderContactsSelectField() {
    const $select = $('#lendercontacts select');
    
    if ($select.hasClass('select2-hidden-accessible')) {
      return;
    }

    $select.select2({
      width: '100%',
      allowClear: true,
      placeholder: 'Choose lender contacts',
      multiple: true,
      ajax: {
        delay: 250,
        url: function (params) {
          return '/lenders/' + $('#lenderselect select').val() + '/contacts.json';
        },
        processResults: (data) => {
          return {
            results: (data || []).map((contact) => {
              return {
                id: contact.id,
                text: contact.name
              }
            })
          };
        }
      }
    });
  }

  setLenderAddresseeSelectField() {
    const $select = $('#lenderaddressee select');
    
    if ($select.hasClass('select2-hidden-accessible')) {
      return;
    }

    $select.select2({
      width: '100%',
      allowClear: true,
      placeholder: 'Choose lender addressee',
      multiple: false,
      ajax: {
        delay: 250,
        url: function (params) {
          return '/lender_addressees.json?lender_id=' + $('#lenderselect select').val();
        },
        processResults: (data) => {
          return {
            results: (data || []).map((lender_addressee) => {
              return {
                id: lender_addressee.id,
                text: lender_addressee.name,
                disabled: lender_addressee.archived_at !== null
              }
            })
          };
        }
      }
    });
  }

  toggleApiAccess() {
    try {
      const led_by_api_value = this.ledByApiTarget.value;
      this.apiAccessDivTarget.hidden = (led_by_api_value === 'false');
      this.lenderAddresseeTarget.hidden = (led_by_api_value === 'true');
    } catch (e) {
      // This happens because feature flag is disabled
      console.log(e)
    }
  }

  indicateCurrentRequestor(requestor_value) {
    const lenderFields = document.getElementById('lenderfields');
    const brokerFields = document.getElementById('brokerfields');
    const boldRequestorTag = document.createElement('b');
    boldRequestorTag.innerHTML = ' - Requestor';

    // reset indicator
    if (document.querySelector('#lenderfields label b')) {
      document.querySelector('#lenderfields label').removeChild(document.querySelector('#lenderfields label b'));
    }
    if (document.querySelector('#brokerfields label b')) {
      document.querySelector('#brokerfields label').removeChild(document.querySelector('#brokerfields label b'));
    }

    if (requestor_value === 'Broker') {
      brokerFields.querySelector('label').appendChild(boldRequestorTag);
    } else if (requestor_value === 'Lender') {
      lenderFields.querySelector('label').appendChild(boldRequestorTag);
    }
  }


  toggleInputDisabled(target, isDisabled) {
    target.hidden = isDisabled
    $(target).find('input, select').prop('disabled', isDisabled)
  }

  toggleInspectionCount() {
    const type = this.inspectionCountTarget.value
    if (type === 'initial_and_interim_reports') {
      this.inspectionCountDivTarget.hidden = false
    } else {
      this.inspectionCountDivTarget.hidden = true
    }
  }

  toggleCondition() {
    const refurbishment_value = this.propertyRefurbishmentTarget.value
    const refurbishment = $('#refurbishment')
    if (this.showRefurbishmentDetails(refurbishment_value)) {
      refurbishment.show()
    } else {
      refurbishment.hide()
    }
  }

  showRefurbishmentDetails(refurbishment_value) {
    return ['light', 'heavy', 'unknown'].includes(refurbishment_value)
  }

  toggleReportFormatDetails() {
    const type = this.sameReportTarget.value
    if (type === 'false') {
      this.reportFormatDivTarget.hidden = false
    } else {
      this.reportFormatDivTarget.hidden = true
    }
  }

  toggleReInspectionDetails() {
    const type = this.reInspectionTarget.value
    if (type === 'true') {
      this.reInspectionDivTarget.hidden = false
    } else {
      this.reInspectionDivTarget.hidden = true
    }
  }

  toggleOutbuildings() {
    const type = this.propertyOutbuildingsTarget.value
    if (type === 'true') {
      this.outbuildingsDivTarget.hidden = false
    } else {
      this.outbuildingsDivTarget.hidden = true
    }
  }

  toggleLandsize() {
    const type = this.propertyLandsizeTarget.value
    if (type === 'over_two_acres') {
      this.landsizeDivTarget.hidden = false
    } else {
      this.landsizeDivTarget.hidden = true
    }
  }

  toggleTenure() {
    const tenure_value = this.propertyTenureTarget.selectedOptions[0].innerHTML
    const timeleft_value = this.propertyTimeleftTarget.value
    const tenure = $('#tenure')
    const timeleft = $('#timeleft')
    if (tenure_value === 'Long Leasehold' && timeleft_value === 'true') {
      tenure.show()
      timeleft.show()
    } else if (tenure_value === 'Long Leasehold' && timeleft_value != 'true') {
      tenure.show()
      timeleft.hide()
    } else {
      tenure.hide()
      timeleft.hide()
    }
  }

  get quoteRequestId() {
    return this.quoteRequestIdTarget.firstElementChild?.value
  }

  blockValuation() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content

    fetch('/api/v1/valuations/block', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        quote_request_id: this.quoteRequestId
      })
    })
    .then(response => {
      if (!response.ok) {
        console.error('Failed to block valuation')
        return
      }
      
      // Refresh lock every 10 seconds
      this.timeoutId = setTimeout(() => {
        this.blockValuation()
      }, 10000)
    })
    .catch(error => {
      console.error('Error blocking valuation:', error)
    })
  }

  unblockValuation() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;

    fetch('/api/v1/valuations/unblock', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        quote_request_id: this.quoteRequestId
      })
    }).catch(error => {
      console.error('Error unblocking valuation:', error);
    });
  }

  toggleFixedFee() {
    if (this.fixedFeeContainerTarget.hidden) {
      this.fixedFeeContainerTarget.hidden = false
    } else {
      this.fixedFeeContainerTarget.hidden = true
    }
  }

  updatePropertyType() {
    let option = this.propertyTypeIdTarget.options[this.propertyTypeIdTarget.selectedIndex]
    this.propertyTypeTarget.value = option.text
  }

  toggleNewBuild(event) {
    const checkbox = event.target
    const isHouse = checkbox === this.newBuildHouseTarget
    
    if (checkbox.checked) {
      if (isHouse) {
        this.newBuildFlatTarget.checked = false
      } else {
        this.newBuildHouseTarget.checked = false
      }
    }
  }
}
