import TextAreaField from 'components/Fields/Base/TextAreaField'
import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'

const PropertySameReportFormat: React.FC = () => {
  return (
    <>
      <ConditionalField field={fieldNames.propertySameReportFormat}>
        <SelectField
          field={fieldNames.propertySameReportFormat}
          label="Same report format?"
          hint="Are you happy to receive the report in the same format or do you want it transcribing onto a different template?"
        />
      </ConditionalField>
      <ConditionalField field={fieldNames.propertyReportFormatDetails}>
        <TextAreaField
          field={fieldNames.propertyReportFormatDetails}
          label="Report format comments"
          rows={3}
          validationRules={{
              maxLength: {
                  value: 255,
                  message: "Characters limit is 255"
              }
          }}
        />
      </ConditionalField>
    </>
  )
}

export default PropertySameReportFormat
