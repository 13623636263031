import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'
import TextAreaField from 'components/Fields/Base/TextAreaField'

const PropertyOutbuildings: React.FC = () => (
  <>
    <ConditionalField field={fieldNames.propertyOutbuildings}>
      <SelectField
        field={fieldNames.propertyOutbuildings}
        label="Are there any outbuildings? *"
        hint="This information is important as it will affect the type of valuer we select."
        validationRules={{
          required: true,
        }}
      />
    </ConditionalField>
    <ConditionalField field={fieldNames.propertyOutbuildingsDetails}>
      <TextAreaField
        field={fieldNames.propertyOutbuildingsDetails}
        label="Please provide details"
        placeholder="Please provide details"
        rows={3}
      />
    </ConditionalField>
  </>
)

export default PropertyOutbuildings
