import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['template', 'revisedFixedFeeModal', 'list', 'submit']

  connect() {
    Inputmask().mask(document.querySelectorAll(".modal input[data-inputmask]"));
  }

  addValuer(e) {
    e.preventDefault()

    const feeType = e.currentTarget.dataset.type;

    let feeAmount = null;
    let item = e.currentTarget.closest('li')

    if (feeType == 'valuation-revised-fixed-fee') {
      let modal = $(this.revisedFixedFeeModalTarget);

      this.resetModal();
      modal.modal('show')

      modal.find('form').on('submit', function (e) {
        e.preventDefault();
        let feeInput = modal.find('input[name="revised_fixed_fee"]')
        feeAmount = feeInput.val()

        if (feeAmount != '' && parseFloat(feeAmount) > 0) {
          feeInput.val('')

          modal.find('form').off('submit')
          modal.modal('hide')

          this.performAdd(item, feeType, feeAmount)
        }
      }.bind(this))
    }
    else {
      feeAmount = e.currentTarget.dataset.amount;

      this.performAdd(item, feeType, feeAmount)
    }
  }
  
  resetModal() {
    const modal = $(this.revisedFixedFeeModalTarget);
    const feeInput = modal.find('input[name="revised_fixed_fee"]');
    feeInput.val('');
    modal.find('form').off('submit');
  }

  performAdd(item, feeType, feeAmount) {
    let valuerItem = { item, feeType, feeAmount }

    this.valuer = valuerItem

    item.setAttribute('hidden', true)
    this.toggleSubmit()
  }

  addValuerWithConfirm(e) {
    if (confirm('Are you sure?')) {
      this.addValuer(e);
    } else {
      e.preventDefault();
    }
  }

  removeValuer (e) {
    e.preventDefault()
    document.querySelector(".valuation_firm[data-id='" + e.currentTarget.dataset.id + "']").removeAttribute('hidden')
    e.currentTarget.closest('.selected-valuation-firm').remove()
    this.toggleSubmit()
  }

  toggleSubmit () {
    if (!document.querySelectorAll('.selected-valuation-firm').length) {
      this.submitTarget.setAttribute('disabled', 'disabled')
    } else {
      this.submitTarget.removeAttribute('disabled')
    }
  }

  set valuer(valuerItem) {
    let item = valuerItem.item
    let feeType = valuerItem.feeType
    let feeAmount = valuerItem.feeAmount || null
    let timestamp = new Date().getTime()
    let element = document.importNode(this.templateTarget.content, true)
    let data = item.dataset
    let name = item.querySelector('.name').textContent
    element.querySelector('.selected-valuation-firm').dataset.url = data.contactUrl
    element.querySelector('.selected-valuation-firm').dataset.id = data.id
    element.querySelector('.selected-valuation-firm').id = 'selected_valuation_firm_' + data.id
    element.querySelector('.firm-name').innerHTML = name
    element.querySelector('.remove').dataset.id = data.id

    this.prepareElement(element, 'valuation_firm_id', timestamp, data.id)
    this.prepareElement(element, 'amount', timestamp, feeAmount)
    this.prepareElement(element, 'distance', timestamp, data.distance)

    element.querySelector('#quotes_attributes_contacts').name = 'valuation[quotes_attributes][' + timestamp + '][contact_ids][]'
    element.querySelector('#quotes_attributes_contacts').id = 'valuation_quotes_attributes_' + timestamp + '_contacts'
    element.querySelector('#quotes_attributes_label_list').name = 'valuation[quotes_attributes][' + timestamp + '][label_list][]'
    element.querySelector('#quotes_attributes_label_list').id = 'valuation_quotes_attributes_' + timestamp + '_label_list'

    element.querySelector('.fee-type').classList.add(feeType)

    let feeTypeText = ''
    let feeTypeAttribute = ''
    switch (feeType) {
      case 'valuation-quote-request':
        feeTypeText = 'Quote Request';
        feeTypeAttribute = 'quote_request'
        break;
      case 'valuation-fixed-fee':
        feeTypeText = 'Fixed Fee';
        feeTypeAttribute = 'fixed_fee'
        break;
      case 'valuation-revised-fixed-fee':
        feeTypeText = 'Revised Fixed Fee'
        feeTypeAttribute = 'revised_fixed_fee'
        break;
    }

    this.prepareElement(element, 'fee_type', timestamp, feeTypeAttribute)

    element.querySelector('.fee-type').innerHTML = feeTypeText

    if (feeAmount && feeAmount > 0) {
      element.querySelector('.fee-amount').innerHTML = ("£" + parseFloat(feeAmount).toFixed(2))
    }

    this.listTarget.append(element)
  }

  prepareElement(element, selector, timestamp, value) {
    let querySelector = '#quotes_attributes_' + selector

    let input = element.querySelector(querySelector)

    if (value) {
      input.value = value
      input.name = 'valuation[quotes_attributes][' + timestamp + '][' + selector + ']'
      input.id = 'valuation_quotes_attributes_' + timestamp + '_' + selector
    } else {
      input.remove()
    }
  }
}
