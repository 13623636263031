import NestedForm from "@stimulus-components/rails-nested-form"

export default class extends NestedForm {
  connect() {
    super.connect()
  }

  add(event) {
    super.add(event)
    this.initializeNestedFormPlugins(event.detail?.content)
  }

  confirmRemove(event) {
    if (confirm('Are you sure you want to remove this item?')) {
      this.remove(event)
    }else{
      event.preventDefault();
      event.target.closest('.btn').blur()
    }
  }

  remove(event) {
    super.remove(event)
    this.disableRequiredAttributes(event.target.closest(this.wrapperSelectorValue))
  }

  initializeNestedFormPlugins(newForm) {
    if (!newForm || !(newForm instanceof Element)) {
      return
    }

    this.initializeSelect2(newForm)
  }

  /**
   * Reinitialize Select2 js controller on the select elements within the new form
   * @param {Element} newForm 
   */
  initializeSelect2(newForm) {
    const selectElements = newForm.querySelectorAll('select[data-controller="select2"]')
    if (selectElements.length === 0) {
      return
    }
    
    selectElements.forEach(element => {
      new Select2(element, {
        width: element.dataset.width || 'resolve',
        placeholder: element.getAttribute('placeholder'),
        templateSelection: templateSelection
      })
    })
  }

  /**
   * Set required attribute to false on the inputs within the wrapper
   * @param {Element} wrapper 
   */
  disableRequiredAttributes(wrapper) {
    if (!wrapper) return
    
    const requiredInputs = wrapper.querySelectorAll('[required]')
    requiredInputs.forEach(input => {
      input.required = false
    })
  }
}
