import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'
import TextAreaField from 'components/Fields/Base/TextAreaField'

const PropertyLeasehold: React.FC = () => (
  <>
    <ConditionalField field={fieldNames.propertyLeasehold}>
      <SelectField
        field={fieldNames.propertyLeasehold}
        label="How long is the remaining term?"
        hint="We ask for 85 years or below as the value of a property starts to reduce at this point."
        helpTexts={[
          'Please provide length of remaining term and any annual ground rent payable as additional information below.',
        ]}
      />
    </ConditionalField>
    <ConditionalField field={fieldNames.propertyLeaseholdDetails}>
      <TextAreaField
        field={fieldNames.propertyLeaseholdDetails}
        label="Please provide length of remaining term and any ground rent payable, or any additional information"
        rows={3}
      />
    </ConditionalField>
  </>
)

export default PropertyLeasehold
