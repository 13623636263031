import { Controller } from 'stimulus'
import { templateSelection } from '../utils/select2_templates'
export default class extends Controller {
  static targets = ['brokerSelect', 'lenderSelect']

  connect() {
    [this.brokerSelectTarget, this.lenderSelectTarget].forEach((select) => {
      $(select).select2({
        width: '100%',
        placeholder: this.element.getAttribute('placeholder'),
      })
      $(select).on("change", this.submitSearchForm);
    })
  }
  disconnect() {
    [this.brokerSelectTarget, this.lenderSelectTarget].forEach((select) => {
      $(select).off("change", this.submitSearchForm);
      $(select).select2('destroy');
    });
  }

  submitSearchForm (event) {
    document.getElementById('valuation-index-search').submit()
  }
}
