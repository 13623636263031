import React from 'react'

import { Contact } from 'components/types'

import AdditionalInformation from 'components/Fields/AdditionalInformation'
import Attachments from 'components/Fields/Attachments'
import Contacts from 'components/Fields/Contacts'
import SimpleStep from 'components/Steps/SimpleStep'

interface Props {
  contacts: Contact[]
}

const AdditionalDetails: React.FC<Props> = ({ contacts }) => (
  <SimpleStep idx={5} title="Additional Details">
    <AdditionalInformation />
    <Attachments />
    <Contacts contacts={contacts} />
  </SimpleStep>
)

export default AdditionalDetails
