import React from 'react'
import { Option } from 'components/types'

interface Props {
  options: Option[]
}

const SelectOptions: React.FC<Props> = ({ options }) => (
  <>
    <option value="">-- Please Select --</option>
    {options.map((option) => (
      <option key={option.id} value={option.id}>
        {option.name}
      </option>
    ))}
  </>
)

export default SelectOptions
