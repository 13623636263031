import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'

const PropertyReports: React.FC = () => {
  return (
    <ConditionalField field={fieldNames.propertyReports}>
      <SelectField
        field={fieldNames.propertyReports}
        label="Reports Required? *"
        validationRules={{ required: true }}
      />
    </ConditionalField>
  )
}

export default PropertyReports
