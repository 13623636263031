import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { FieldName } from 'components/types'

interface Props {
  field: FieldName
  label: string
  hint?: string
}

const Label: React.FC<Props> = ({ field, label, hint }) => (
  <Form.Label>
    {label}
    {hint && (
      <div className="d-inline-block ml-2">
        <OverlayTrigger
          overlay={<Tooltip id={`${field}-tooltip`}>{hint}</Tooltip>}
        >
          <div>
            <i className="fas fa-info-circle" />
          </div>
        </OverlayTrigger>
      </div>
    )}
  </Form.Label>
)

export default Label
