import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["overlay", "timeoutMessage", "closeButton"]
  static values = {
    timeoutDelay: { type: Number, default: 8000 }, // 8s until timeout message is shown
    showDelay: { type: Number, default: 150 }  // 150ms delay before showing spinner, prevents flickering
  }

  connect() {
    if (document.body.classList.contains('test-mode')) return
    if (!document.querySelector('.quoflow-board, .servflow-board, .proflow-board, .comflow-board, .trackflow-board')) return
    
    document.addEventListener("turbo:before-fetch-request", this.showOverlay.bind(this))
    document.addEventListener("turbo:before-fetch-response", this.hideOverlay.bind(this))
    document.addEventListener("turbo:submit-start", this.showOverlay.bind(this))
    document.addEventListener("turbo:submit-end", this.hideOverlay.bind(this))
    document.addEventListener("ajax:before", this.showOverlay.bind(this))
    document.addEventListener("ajax:complete", this.hideOverlay.bind(this))
    document.addEventListener("ajax:error", this.hideOverlay.bind(this))
  }

  disconnect() {
    if (document.body.classList.contains('test-mode')) return
    if (!document.querySelector('.quoflow-board, .servflow-board, .proflow-board, .comflow-board, .trackflow-board')) return
    
    document.removeEventListener("turbo:before-fetch-request", this.showOverlay.bind(this))
    document.removeEventListener("turbo:before-fetch-response", this.hideOverlay.bind(this))
    document.removeEventListener("turbo:submit-start", this.showOverlay.bind(this))
    document.removeEventListener("turbo:submit-end", this.hideOverlay.bind(this))
    document.removeEventListener("ajax:before", this.showOverlay.bind(this))
    document.removeEventListener("ajax:complete", this.hideOverlay.bind(this))
    document.removeEventListener("ajax:error", this.hideOverlay.bind(this))
    this.clearTimeoutTimer()
    if (this.showDelayTimer) {
      clearTimeout(this.showDelayTimer)
      this.showDelayTimer = null
    }
  }

  showOverlay(event) {
    // Skip showing overlay for requests that should skip the loading spinner
    if (event && event.target && event.target.hasAttribute('data-skip-loading-spinner')) return

    if (this.hasOverlayTarget) {
      if (this.showDelayTimer) {
        clearTimeout(this.showDelayTimer)
      }

      this.showDelayTimer = setTimeout(() => {
        this.overlayTarget.classList.remove('d-none')
        this.overlayTarget.offsetHeight
        this.overlayTarget.classList.add('show')
        
        this.timeoutMessageTarget.classList.add('d-none')
        this.closeButtonTarget.classList.add('d-none')
        this.startTimeoutTimer()
      }, this.showDelayValue)
    }
  }

  hideOverlay() {
    if (this.hasOverlayTarget) {
      if (this.showDelayTimer) {
        clearTimeout(this.showDelayTimer)
        this.showDelayTimer = null
      }

      this.overlayTarget.classList.remove('show')
      setTimeout(() => {
        this.overlayTarget.classList.add('d-none')
      }, 300)
      this.clearTimeoutTimer()
    }
  }

  startTimeoutTimer() {
    this.clearTimeoutTimer()
    this.timeoutTimer = setTimeout(() => {
      this.timeoutMessageTarget.classList.remove('d-none')
      this.closeButtonTarget.classList.remove('d-none')
    }, this.timeoutDelayValue)
  }

  clearTimeoutTimer() {
    if (this.timeoutTimer) {
      clearTimeout(this.timeoutTimer)
      this.timeoutTimer = null
    }
  }

  close(event) {
    event.preventDefault()
    this.hideOverlay()
  }
}
