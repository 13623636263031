import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'

const PropertyPortfolioValuationBasis: React.FC = () => {
  return (
    <ConditionalField field={fieldNames.propertyPortfolioValuationBasis}>
      <SelectField
        field={fieldNames.propertyPortfolioValuationBasis}
        label="Basis of portfolio valuation"
        hint="Please let us know if you require the aggregate portfolio value of each individual property and / or a portfolio value. Usually the portfolio value has a discount to the aggregate value assuming the whole portfolio is sold at once."
      />
    </ConditionalField>
  )
}

export default PropertyPortfolioValuationBasis
