import { Controller } from 'stimulus';
import Chart from 'chart.js/auto';

export default class extends Controller {
  connect() {
    const chartData = JSON.parse(this.element.dataset.chartDataset);
    this.renderChart(chartData);
  }

  renderChart(data) {
    const ctx = this.element.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: data.labels,
        datasets: data.datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: function(context) {
                const value = Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'GBP',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }).format(context.parsed.y);

                return `${context.dataset.data[context.dataIndex].name}: ${value}`;
              },
              footer: function(context) {
                const value = context[0].chart.data.datasets[0].data[context[0].dataIndex].delta;

                return `Diff: ${value}`;
              }
            },
            multiline: true
          },
          title: {
              display: true,
              text: data.title,
              position: 'top',
              align: 'center',
              color: 'black',
              font: {
                size: 20,
                family: 'Montserrat',
                weight: 'normal'
              }
          },
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              display: false
            }
          }
        }
      }
    });
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
