import { useFormContext } from 'react-hook-form'

import { useFormSettings } from 'components/FormSettingsContext'
import { FieldName, Schema } from 'components/types'
import matchingFieldSchema from 'components/Fields/Base/matchingFieldSchema'

import getDependentFieldNames from './getDependentFieldNames'

const useFieldSchema = (fieldName: FieldName): Schema => {
  const settings = useFormSettings()
  const { watch } = useFormContext()

  const dependentFieldNames = getDependentFieldNames(fieldName, settings)
  const dependenciesWatch = watch(dependentFieldNames)

  return matchingFieldSchema(fieldName, dependenciesWatch, settings)
}

export default useFieldSchema
