import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import FormattedNumberField from 'components/Fields/Base/FormattedNumberField'

const PropertyEstimatedValue: React.FC = () => (
  <ConditionalField field={fieldNames.propertyEstimatedValue}>
    <FormattedNumberField
      field={fieldNames.propertyEstimatedValue}
      prefix="£"
      label="Estimated value *"
      validationRules={{
        required: true,
        max: {
          value: 999999999,
          message: 'must be less than 999,999,999',
        },
        min: {
          value: 1,
          message: 'must be greater than 0',
        },
      }}
    />
  </ConditionalField>
)

export default PropertyEstimatedValue
