import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.style.position = 'fixed'
    this.isDragging = false
    this.currentX = 0
    this.currentY = 0
    this.initialX = 0
    this.initialY = 0
    this.xOffset = 0
    this.yOffset = 0

    this.dragStart = this.dragStart.bind(this)
    this.drag = this.drag.bind(this)
    this.dragEnd = this.dragEnd.bind(this)
  }

  dragStart(e) {
    if (e.type === "touchstart") {
      this.initialX = e.touches[0].clientX - this.xOffset
      this.initialY = e.touches[0].clientY - this.yOffset
    } else {
      this.initialX = e.clientX - this.xOffset
      this.initialY = e.clientY - this.yOffset
    }

    if (this.element.contains(e.target)) {
      this.isDragging = true
      document.addEventListener('mousemove', this.drag)
      document.addEventListener('touchmove', this.drag)
      document.addEventListener('mouseup', this.dragEnd)
      document.addEventListener('touchend', this.dragEnd)
    }
  }

  drag(e) {
    if (this.isDragging) {
      e.preventDefault()

      if (e.type === "touchmove") {
        this.currentX = e.touches[0].clientX - this.initialX
        this.currentY = e.touches[0].clientY - this.initialY
      } else {
        this.currentX = e.clientX - this.initialX
        this.currentY = e.clientY - this.initialY
      }

      this.xOffset = this.currentX
      this.yOffset = this.currentY

      this.setTranslate(this.currentX, this.currentY)
    }
  }

  dragEnd() {
    this.initialX = this.currentX
    this.initialY = this.currentY
    this.isDragging = false

    document.removeEventListener('mousemove', this.drag)
    document.removeEventListener('touchmove', this.drag)
    document.removeEventListener('mouseup', this.dragEnd)
    document.removeEventListener('touchend', this.dragEnd)
  }

  setTranslate(xPos, yPos) {
    this.element.style.transform = `translate(${xPos}px, ${yPos}px)`
  }
}
