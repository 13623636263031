import { Controller } from 'stimulus'
import { templateSelection } from '../utils/select2_templates'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    let width = this.element.dataset.width || 'resolve'
    let disabled = this.element.getAttribute('readonly') == 'readonly'
    $(this.element).select2({
      width: width,
      placeholder: this.element.getAttribute('placeholder'),
      disabled: disabled,
      templateSelection: templateSelection,
      maximumSelectionLength: this.element.dataset.maximumSelectionLength,
    }).on('change', (args) => {

      // valuation#updatePropertyType
      if (this.element.dataset.target === 'valuation.propertyTypeId') {
        const event = new Event('update', { bubbles: true })
        this.element.dispatchEvent(event)
      }

      if (this.element.dataset.submitOnChange === 'true') {
        document.getElementById('save_button').click();
      }
    });
  }
  disconnect() {
    $(this.element).select2('destroy');
    $(this.element).off('change');
  }
}
