import { Controller } from 'stimulus'
import $ from 'jquery'
import { templateSelection } from '../utils/select2_templates'
import Rails from '@rails/ujs'
import { put } from '@rails/request.js'

export default class extends Controller {
  static targets = ['formTarget', 'formServflow', 'formAdditionalInformation', 'event', 'valuerFee', 'margin', 'lenderFee', 'label']
  static values = { status: String }

  connect() {
    this.initSelect2()
    if(this.lenderFeeTarget.value){
      this.updateMargin()  // calculate margin value on init
    }
  }

  initSelect2() {
    if(!this.element.elements['hide_further_details'].value) {
      $(this.element.elements['quote_contact_ids']).select2({
        width: '100%',
        templateSelection: templateSelection,
      })
    }
    if (!this.element.elements['hide_labels'].value) {
      $(this.labelTarget).select2({
        width: '100%',
        templateSelection: templateSelection,
      }).on('change.select2', (e) => {
        const selectedOptions = e.target.selectedOptions
        this.persistLabels([].map.call(selectedOptions, el => el.text))
        $(`#labels_${this.element.id}`).html([].map.call(selectedOptions, el => this.buildSpanElement(el)))
      })
    }
  }

  disconnect() {
    $(this.element.elements['quote_contact_ids']).select2('destroy')
    $(this.labelTarget).select2('destroy')
  }

  persistLabels(labelList) {
    put(this.element.action, {
      body: JSON.stringify({
        quote: {
          label_list: labelList,
        },
      }),
      contentType: 'application/json',
      responseKind: 'json',
    })
  }

  buildSpanElement(rawEl) {
    const el = document.createElement('span')
    el.classList.add('badge', 'badge-pill', 'text-uppercase')
    el.style = `background-color: ${rawEl.dataset.color}; color: ${rawEl.dataset.textColor}`
    el.textContent = rawEl.text
    return el
  }

  updateMargin() {
    this.marginTarget.value = (
      Number(this.lenderFeeTarget.value) - Number(this.valuerFeeTarget.value)
    ).toFixed(2)
  }

  submitDraft(e) {
    if (this.eventTarget) {
      this.eventTarget.remove()
    }
  }

  decline(e) {
    const confimed = confirm("Are you sure?");
    if (!confimed) {
      return
    }

    // Prevent from submitting original quote's form
    e.preventDefault()

    if (this.formTargetTarget.value === '_top') {
      // When the form is displayed in the old modal
      const form = new FormData()
      if (this.statusValue != 'declined') {
        form.set('quote[event]', 'decline')
      }
      form.set('quote[declined_reason]', e.target.dataset.declineReason)
      form.set('quote[additional_information]', this.formAdditionalInformationTarget.value)
      form.set('quote[servflow]', this.formServflowTarget.value)
      form.set('quote[target_board]', e.target.dataset.targetBoard)

      Rails.ajax({
        type: 'PUT',
        url: this.targets.element.action,
        data: form,
      })
    } else {
      put(this.targets.element.action, {
        body: JSON.stringify({
          quote: {
            event: 'decline',
            servflow: this.formServflowTarget.value,
            additional_information: this.formAdditionalInformationTarget.value,
            declined_reason: e.target.dataset.declineReason,
            target_board: e.target.dataset.targetBoard,
          },
        }),
        responseKind: 'turbo-stream',
      })
    }
  }

  updateLenderFee() {
    this.lenderFeeTarget.value = (
      Number(this.valuerFeeTarget.value) + Number(this.marginTarget.value)
    ).toFixed(2)
  }

  disableSubmitButtons() {
    this.targets.element.querySelectorAll('button').forEach((el) => {
      el.disabled = true
    })
  }

  enableReadonly(target) {
    target.setAttribute('readonly', 'true')
  }

  disableReadonly(target) {
    target.removeAttribute('readonly')
  }
}
