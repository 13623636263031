import React from 'react'
import { Button } from 'react-bootstrap'

const ConfirmationMessage: React.FC = () => (
  <div>
    <h3>Thank you for your quote request</h3>
    <p>We will be in touch shortly via email to confirm:</p>
    <ul>
      <li>Valuer quotations and availability,</li>
      <li>
        Or if an instant instruction has been requested, confirmation that the
        valuation has been directly instructed based off our fee scale
      </li>
    </ul>
    <p>
      A unique VP reference number will be shortly confirmed via email with all
      information available on your VAS Dashboard
    </p>
    <p>If we need to clarify anything we’ll contact you</p>
    <p className="mb-0">
      Kind Regards
      <br />
      Team VAS
    </p>
    <div className="text-right mt-5">
      <Button 
        variant="link" 
        onClick={() => window.location.href = '/valuations'}
      >
        Go to Dashboard
      </Button>
      <Button variant="primary" href="/quote_requests/new" data-turbo="false">
        Create another quote request
      </Button>
    </div>
  </div>
)

export default ConfirmationMessage
