import { FieldName, FormSettings } from 'components/types'

import getFieldSchemas from 'components/Fields/Base/getFieldSchemas'

export const getDependentFieldNames = (
  fieldName: FieldName,
  settings: FormSettings
): FieldName[] => {
  const schemas = getFieldSchemas(fieldName, settings)

  return Array.from(
    schemas
      .reduce((acc, c) => {
        Object.keys(c.rules || {}).forEach((n: FieldName) => {
          acc.add(n)
        })

        return acc
      }, new Set<FieldName>())
      .values()
  )
}

export default getDependentFieldNames
