import Trix from 'trix'
import cleanArray from 'clean-array'

const getHexColor = (color) => {
  if (!color) { return '' }
  if (/^#./.test(color)) { return color }

  let rgbValues = getRGBValues(color)
  let hexValues = rgbValues.map(numberToHex)
  return `#${hexValues.join('')}`
}

const numberToHex = (number) => `0${number.toString(16)}`.slice(-2).toUpperCase()

const getRGBValues = (color) => {
  const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/i)
  var r, g, b
  if (match) {
    [r, g, b] = match.slice((match.length - 3), match.length)
  } else {
    let canvas = document.createElement('canvas')
    canvas.height = canvas.width = 1
    let context = canvas.getContext('2d')
    context.fillStyle = color
    context.fillRect(0, 0, 1, 1);

    [r, g, b] = context.getImageData(0, 0, 1, 1)
  }
  return [r, g, b].map(Number)
}

const createColorParser = function (element) {
  while (element && element.tagName !== 'TRIX-EDITOR') {
    let inlineStyles = element.getAttribute('style')
    if (inlineStyles) {
      let styleObject = {}
      for (const stylePair of cleanArray(inlineStyles.split(';'))) {
        let [key, value] = stylePair.split(':')
        styleObject[`${key}`] = value.trim()
      }

      let namedColor = styleObject[this.styleProperty]
      if (namedColor && /^[a-z]+$/i.test(namedColor)) {
        return namedColor
      } else if (namedColor) {
        let hexColor = getHexColor(namedColor)
        if (hexColor) { return hexColor }
      }
    }
    element = element.parentElement
  }
}

Trix.config.textAttributes.foregroundColor = {
  inheritable: true,
  styleProperty: 'color',
  parser: createColorParser
}

Trix.config.textAttributes.backgroundColor = {
  inheritable: true,
  styleProperty: 'backgroundColor',
  parser: createColorParser
}
