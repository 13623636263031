import React from 'react'

import Applicant from 'components/Fields/Applicant'
import SimpleStep from 'components/Steps/SimpleStep'

const ApplicantDetails: React.FC = () => (
  <SimpleStep idx={4} title="Applicant Details">
    <Applicant />
  </SimpleStep>
)

export default ApplicantDetails
