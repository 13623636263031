import { Controller } from 'stimulus'
import Inputmask from 'inputmask'

export default class extends Controller {

  connect() {
    Inputmask().mask(document.querySelectorAll("input[data-inputmask]"));
  }

}
