import { Controller } from 'stimulus'
import pluralize from 'pluralize'
import Chart from 'chart.js/auto'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['chart', 'container', 'total_instructions', 'total_quotes']

  initialize () {
    this.createChart()
  }

  changePeriod (e) {
    const success = function (data) {
      this.drawChart(data)
      this.updateTotals(data)
    }.bind(this)

    let period = e.currentTarget.options[e.currentTarget.selectedIndex].value
    let url = this.url + '?period=' + period

    this.callDataSource(url, success)
  }

  updateTotals (data) {
    this.total_instructionsTarget.innerHTML = data.total_instructions
    this.total_quotesTarget.innerHTML = data.total_quotes
  }

  createChart () {
    const success = function (data) {
      this.drawChart(data)
      this.updateTotals(data)
    }.bind(this)

    let url = this.url

    this.callDataSource(url, success)
  }

  drawChart (dataset) {
    let data = this.fillDataset(dataset.labels, dataset.instructions, dataset.quotes, dataset.profits, dataset.valuations)
    let options = {
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          display: false,
          gridLines: {
            display: false
          },
          ticks: {
            display: false,
            beginAtZero: true
          }
        }]
      },
      legend: {
        display: false
      },
      tooltips: {
        displayColors: false,
        mode: 'index',
        callbacks: {
          label: function (tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || ''
            let amount = tooltipItem.yLabel
            return pluralize(label, amount, true)
          },
          beforeBody: function (tooltipItem, data) {
            let profit = data.datasets[2].data[tooltipItem[0].index]
            let label = ' Profit'
            return '£' + profit + label
          },
          afterBody: function (tooltipItem, data) {
            let label = []
            let valuations = data.datasets[3].data[tooltipItem[0].index]
            if (valuations.length === 0) {
              label = [' ', 'No valuations paid today']
            } else {
              label = [' ', 'Valuations paid today:']
            }
            return label.concat(valuations)
          }
        }
      }
    }
    let ctx = this.chartTarget.getContext('2d')
    let myNewChart = new Chart(ctx, { type: 'bar', data: data, options: options })
    myNewChart()
  }

  fillDataset (labels, instructions, quotes, profits, valuations) {
    return {
      labels: labels,
      datasets: [
        {
          label: 'Instruction',
          fillColor: 'rgba(220,220,220,0.5)',
          strokeColor: 'rgba(220,220,220,1)',
          pointColor: 'rgba(220,220,220,1)',
          pointStrokeColor: '#fff',
          beginAtZero: true,
          data: instructions
        },
        {
          label: 'Quote',
          data: quotes,
          type: 'line',
          fill: false,
          lineTension: 0
        },
        {
          label: 'Profit',
          data: profits,
          hidden: true
        },
        {
          label: 'Valuation',
          data: valuations,
          hidden: true
        }
      ]
    }
  }

  callDataSource (url, success) {
    $.ajax({
      type: 'GET',
      url: url,
      processData: false,
      contentType: false,
      success: success,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.metaValue('csrf-token'),
        'Access-Control-Allow-Origin': location.origin
      }
    })
  }

  metaValue (name) {
    let element = $(document).find('head').find('meta[name="' + name + '"]')
    if (element) {
      return element.attr('content')
    }
  }

  get url () {
    return this.containerTarget.dataset.apiUrl
  }
}
