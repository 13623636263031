import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import SelectField from 'components/Fields/Base/SelectField'
import ConditionalField from 'components/Fields/Base/ConditionalField'

const PropertyTenancyStatus: React.FC = () => (
  <ConditionalField field={fieldNames.propertyTenancyStatus}>
    <SelectField
      field={fieldNames.propertyTenancyStatus}
      label="Tenancy status *"
      validationRules={{ required: true }}
    />
  </ConditionalField>
)

export default PropertyTenancyStatus
