import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['droparea', 'fileinput', 'documentlist', 'progresstemplate', 'container']

  showArea (e) {
    e.preventDefault()
    $(this.dropareaTarget).prop('hidden', false)
  }

  hideArea (e) {
    e.preventDefault()
    $(this.dropareaTarget).prop('hidden', true)
  }

  openFileInput (e) {
    e.preventDefault()
    $(this.fileinputTarget).click()
  }

  uploadFileFromDrop (e) {
    e.preventDefault()
    $(this.dropareaTarget).prop('hidden', true)

    const files = e.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      this.handleFile(files[i])
    }
  }

  uploadFileFromInput(e) {
    this.documentlistTarget.innerHTML = "";
    let files = e.currentTarget.files
    for (let i = 0; i < files.length; i++) {
      this.handleFile(files[i])
    }
  }

  appendFilesInInput(e) {
    const inputFiles = $(this.fileinputTarget).prop('files');
    const additionalFiles = e.dataTransfer.files;
    const newFileList = new DataTransfer();
    Array.from(inputFiles).forEach(file => newFileList.items.add(file));
    Array.from(additionalFiles).forEach(file => newFileList.items.add(file));
    $(this.fileinputTarget).prop('files', newFileList.files);
  }

  handleFile (file) {
    let id = '' + file.lastModified + file.size
    let reader = new FileReader()

    reader.onloadstart = this.loadstart(file, id)
    reader.onload = this.load(file, id)

    reader.readAsBinaryString(file)
  }

  performFileUpload (event) {
    let { file, id } = event.detail
    let url = this.url
    let formData = new FormData()
    formData.append('data', file)
    formData.append('form[parent_id]', this.containerTarget.dataset.formParentId)
    formData.append('form[parent]', this.containerTarget.dataset.formParent)
    formData.append('form[name]', this.containerTarget.dataset.formName)

    let handleProgress = this.progress(id)
    let handleSuccess = this.success(id)
    let handleError = this.error(id)

    $.ajax({
      type: 'POST',
      url: url,
      data: formData,
      processData: false,
      contentType: false,
      success: handleSuccess,
      error: handleError,
      progress: handleProgress,
      xhr: function () {
        let myXhr = $.ajaxSettings.xhr()
        myXhr.addEventListener('progress', handleProgress, false)
        myXhr.upload.addEventListener('progress', handleProgress, false)
        return myXhr
      },
      headers: {
        'Accept': 'text/html, application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.metaValue('csrf-token'),
        'Access-Control-Allow-Origin': location.origin
      }
    })
  }

  loadstart (file, id) {
    let template = this.progresstemplateTarget
    template.content.querySelector('.row.file-uploader-progress').setAttribute('data-id', id)
    template.content.querySelector('.file-name').textContent = file.name
    let instance = document.importNode(template.content, true)
    this.documentlistTarget.prepend(instance)
  }

  progress(id) {
    return function(e) {
      if (e.lengthComputable) {
        let percent = e.loaded / e.total * 100
        let event = new CustomEvent('progress:change', {detail: {percent: percent, action: 'Upload'}})
        document.querySelector(`[data-id="${id}"]`).dispatchEvent(event)
      }
    }
  }

  load(file, id) {
    let event = new CustomEvent('file-uploader:upload', {detail: {file: file, id: id}})
    this.containerTarget.dispatchEvent(event)
  }

  success(id) {
    return function(data, status) {
      let event = new CustomEvent('progress:end', {detail: {data: data}})
      document.querySelector(`[data-id="${id}"]`).dispatchEvent(event)
    }
  }

  error(id) {
    return function(xhr, status, error) {
      let event = new CustomEvent('progress:error', {detail: {data: error}})
      document.querySelector(`[data-id="${id}"]`).dispatchEvent(event)
    }
  }

  metaValue(name) {
    let element = $(document).find('head').find('meta[name="' + name + '"]')
    if (element) {
      return element.attr('content')
    }
  }

  uploadSubmitFormFileFromDrop (e) {
    e.preventDefault()
    $(this.dropareaTarget).prop('hidden', true)

    const files = e.dataTransfer.files;
    const newFileList = new DataTransfer();
    
    if (this.containerTarget.dataset.multiple === 'false') {
      this.documentlistTarget.innerHTML = "";
      if (files.length > 0) {
        newFileList.items.add(files[0]);
      }

      $(this.fileinputTarget).prop('files', newFileList.files);
      this.handleFile(files[0]);
    } else {
      this.appendFilesInInput(e);
      for (let i = 0; i < files.length; i++) {
        this.handleFile(files[i])
      }
    }
  }

  renderSubmittableAttachment(event) {
    let {file, id} = event.detail
    let handleSuccess = this.success(id)

    setTimeout((file1) => {
      handleSuccess(`<div class="row file-uploaded" data-controller="file-remover" data-target="file-remover.file" id="file=${id}">
  <div class="col col-md-12">
    <div class="d-flex justify-content-between border-bottom">
      <p class="file-name">${file1}</p>
      <span class="row-actions">
        <span class="delete">
        <a class="remove-file" data-action="file-uploader#removeFromList file-remover#removeFile" filename="${file1}" data-remote="true" rel="nofollow" href="#">
           <i class="fas fa-trash-alt"></i>
        </a></span>
        </span>
    </div>
  </div>
</div>`)
    }, 200, file.name, id);
  }

  removeFromList(e) {
    e.preventDefault()
    const fileName = e.currentTarget.getAttribute('filename');
    const inputFiles = $(this.fileinputTarget).prop('files')
    const list = new DataTransfer();
    Array.from(inputFiles).filter(it => it.name !== fileName).forEach(file => list.items.add(file))
    $(this.fileinputTarget).prop('files', list.files)
  }

  removeReturnedAttachment(e) {
    e.preventDefault()
    e.currentTarget.closest('.returned-attachment').remove()
  }

  set documentRow([data, name, id]) {
    let json = data.responseJSON
    let div = $(this.documentlistTarget).find('#file-template').clone()
    div.attr('hidden', false)
    div.attr('id', 'file-' + json.blob_id)
    div.find('.file-name').html(name)
    div.find('a').attr('href', json.blob_id)
    div.find('input').attr('disabled', false)
    div.find('input').attr('value', json.blob_id)
    div.find('input').attr('id', div.find('input').attr('id') + '_' + json.blob_id)
    div.find('a').attr('href', json.blob_id)
    $(this.documentlistTarget).prepend(div)
  }

  get url() {
    return this.containerTarget.dataset.directUploadUrl
  }
}
