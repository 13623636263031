import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import $ from 'jquery'
import _ from 'lodash'

export default class extends Controller {
  static targets = ['highlight']

  connect() {
    this.typingTimer = null
    this.doneTypingInterval = 1500
    this.openSearchOnEvent()
    this.waitForTypingAndSubmit = _.debounce(this.waitForTypingAndSubmit, this.doneTypingInterval).bind(this)
  }

  waitForTypingAndSubmit(event) {
    const searchQueryLength = document.getElementById('nav-search-text-field').value.length;
    if (searchQueryLength >= 3) {
      this.submitForm.call(this, event)
    }
  }

  submitForm(event) {
    Rails.fire(document.getElementById('nav-search-form'), 'submit')
  }

  renderResults(event) {
    const [, , xhr] = event.detail
    const resultContainer = document.getElementById('nav-search-results-container')
    resultContainer.innerHTML = xhr.response
    this.highlight()
  }

  openSearch() {
    const ele = document.getElementById('nav-search')
    ele.classList.add('nav-search__form--focus')
  }

  closeSearch(event) {
    const ele = document.getElementById('nav-search')
    ele.classList.remove('nav-search__form--focus')
    document.getElementById('nav-search-text-field').value = ''
  }

  highlight() {
    this.highlightTargets.forEach((ele, i) => {
      const highlightText = ele.getAttribute('data-highlight-text')
      const regex = new RegExp(highlightText, 'gi')
      ele.innerHTML = ele.innerHTML.replace(regex, `<strong>${highlightText}</strong>`)
    })
  }

  openSearchOnEvent() {
    $(document).on('keydown', (e) => {
      if (e.originalEvent.key !== '/') {
        return
      }
      if (e.originalEvent.key === '/' && $.inArray(e.target.nodeName, ['INPUT', 'TEXTAREA', 'TRIX-EDITOR']) !== -1) {
        return
      }
      e.preventDefault()
      this.openSearch()
      document.getElementById('nav-search-text-field').focus()
    })
  }
}
