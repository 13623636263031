import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  toggleRemainingTermCheckbox () {
    const type = $('#tenure_dropdown').find('option:selected').text()
    const remaining_term_checkbox = $('#remaining_term_checkbox')
    if (type === 'Long Leasehold') {
      remaining_term_checkbox.parent().show()
    } else {
      remaining_term_checkbox.parent().hide()
      remaining_term_checkbox.prop('checked', false)
    }
  }

  toggleBrokerInvolved () {
    const brokerInvolvedChecked = $('#broker_involved_checkbox').is(':checked')
    const brokerNameElem = $('#broker_name_text_field')
    if (brokerInvolvedChecked) {
      brokerNameElem.parent().show()
    } else {
      brokerNameElem.parent().hide()
      brokerNameElem.val('')
    }
  }

  toggleLenderInvolved () {
    const lenderInvolvedChecked = $('#lender_involved_checkbox').is(':checked')
    const lenderNameElem = $('#lender_name_text_field')
    if (lenderInvolvedChecked) {
      lenderNameElem.parent().show()
    } else {
      lenderNameElem.parent().hide()
      lenderNameElem.val('')
    }
  }

  connect() {
    this.toggleRemainingTermCheckbox()

    this.toggleBrokerInvolved()
    this.toggleLenderInvolved()
  }
}
