import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect () {
    $('.collapsible').on('hidden.bs.collapse', function () {
      $(this).parents('.document-rule-accordion__card').removeClass('open')
    }).on('shown.bs.collapse', function () {
      $(this).parents('.document-rule-accordion__card').addClass('open')
    })
  }

  resetForm (e) {
    const parent = $(e.target).parents('.document-rule-accordion__card')
    parent.find('select').val('')
    parent.find('input[type="checkbox"]').prop('checked', false)
    parent.find('.collapsible').collapse('hide')
  }
}
