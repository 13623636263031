import { Controller } from 'stimulus'
import moment from 'moment-business-days'
import 'moment/locale/en-gb'
import pluralize from 'pluralize'

export default class extends Controller {
  static targets = ['badge']

  connect () {
    moment.updateLocale('en-gb', {
      holidays: window.holidays,
      holidayFormat: 'YYYY-MM-DD'
    })

    let now = moment().startOf('day')
    if (now.isAfter(this.date, 'day')) {
      this.badgeTarget.classList.add('badge-danger')
      let text = 'Overdue: '
      text += pluralize('day', now.businessDiff(moment(this.date)), true)
      this.badgeTarget.textContent = text
    } else if (now.isSame(this.date, 'day')) {
      this.badgeTarget.classList.add('badge-warning')
      this.badgeTarget.textContent = 'Due Today'
    }
  }

  get date () {
    return this.badgeTarget.dataset.date
  }
}
