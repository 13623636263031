import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from "./Base/SelectField";

const PropertyReInspectionType: React.FC = () => (
    <ConditionalField field={fieldNames.propertyReInspectionType}>
        <SelectField
            field={fieldNames.propertyReInspectionType}
            label="Type of re-inspection *"
            validationRules={{
                required: true,
            }}
        />
    </ConditionalField>
)

export default PropertyReInspectionType
