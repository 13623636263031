import { Controller } from 'stimulus'
import Chart from 'chart.js/auto'
import moment from 'moment'

export default class extends Controller {
  static targets = ['lineChart', 'container', 'loadedData']

  connect () {
    let data = this.loadData()
    this.drawLineChart(data)
  }

  loadData () {
    return JSON.parse(this.containerTarget.dataset.loadedData)
  }

  drawLineChart (dataset) {
    if (dataset.single_entry) {
      dataset.x_axis_label[1] = moment(dataset.x_axis_label[1]).format('D MMM')
    }
    let data = this.fillDataset(dataset.x_axis_label, dataset.instructions, dataset.valuations_created, dataset.profits, dataset.valuations)
    let options = {
      scales: {
        xAxes: [{
          autoSkip: false,
          gridLines: {
            display: false
          },
          time: {
            unit: 'day',
            stepSize: Math.floor(dataset.x_axis_label.length / 9),
            displayFormats: {
              'day': 'D MMM'
            }
          },
          scaleLabel: {
            display: true
          }
        }],
        yAxes: [{
          gridLines: {
            display: true
          },
          ticks: {
            display: true,
            stepSize: 10,
            beginAtZero: true
          }
        }]
      },
      legend: {
        display: false
      },
      tooltips: {
        backgroundColor: 'rgba(0,0,0,0)',
        mode: 'index',
        position: 'nearest',
        // Disable the on-canvas tooltip
        enabled: false,
        custom: function (tooltipModel) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip')

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div')
            tooltipEl.id = 'chartjs-tooltip'
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0
            return
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform')
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign)
          } else {
            tooltipEl.classList.add('no-transform')
          }

          function getBody (bodyItem) { // eslint-disable-line no-unused-vars
            return bodyItem.lines
          }

          // `this` will be the overall tooltip
          var position = this._chart.canvas.getBoundingClientRect()

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1
          tooltipEl.style.background = 'rgba(0,0,0,0)'
          tooltipEl.style.position = 'absolute'
          tooltipEl.style.left = position.left + tooltipModel.caretX + 'px'
          tooltipEl.style.top = position.top + tooltipModel.caretY + 'px'
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
        },
        callbacks: {
          label: function (tooltipModel, data) {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip')
            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div')
              tooltipEl.id = 'chartjs-tooltip'
            }
            let html = dataset.tooltip[tooltipModel.index]
            if (html) {
              tooltipEl.innerHTML = html
              document.body.appendChild(tooltipEl)
            }
          },
          afterBody: function (tooltipItem, data) {
            let label = []
            let valuations = data.datasets[3].data[tooltipItem[0].index]
            if (valuations.length === 0) {
              label = [' ', 'No valuations paid today']
            } else {
              label = [' ', 'Valuations paid today:']
            }
            return label.concat(valuations)
          }
        }
      }
    }

    if (!dataset.single_entry) {
      options.scales.xAxes[0].type = 'time'
    }
    let ctx = this.lineChartTarget.getContext('2d')
    let myNewChart = new Chart(ctx, { type: 'line', data: data, options: options })
    myNewChart()
  }

  fillDataset (labels, instructions, quotes, profits, valuations) {
    return {
      labels: labels,
      datasets: [
        {
          borderColor: '#006b2d',
          data: instructions,
          fill: false,
          label: 'Instruction',
          type: 'line',
          lineTension: 0
        },
        {
          borderColor: '#2d2162',
          data: quotes,
          fill: false,
          type: 'line',
          label: 'Quote',
          lineTension: 0
        },
        {
          label: 'Profit',
          data: profits,
          hidden: true
        },
        {
          label: 'Valuation',
          data: valuations,
          hidden: true
        }
      ]
    }
  }
}
