import { Controller } from 'stimulus'
import 'select2'
import $ from 'jquery'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['loaderTrigger', 'loadableSelect', 'loadableSelectWrapper', 'form', 'content', 'contactableType', 'recipientIds']

  connect () {
    this.initSelect2()
    this.options_selector = null
  }

  initSelect2 () {
    $(this.loadableSelectTarget).select2({ placeholder: this.loadableSelectTarget.getAttribute('placeholder'), width: '100%' })
  }

  disconnect() {
    $(this.loadableSelectTarget).select2('destroy')
  }

  populateEmailContent(event) {
    const noteContent = document.querySelector('#note_content_editor');
    if (noteContent && this.hasContentTarget) {
      this.contentTarget.value = noteContent.value;
    }
  }
  
  populateRecipients(event) {
    this.setUrl(event)
  }


  setUrl (e) {
    let selectEl = e.currentTarget
    if (selectEl.value === '') {
      e.preventDefault()
      this.loadableSelectWrapperTarget.classList.remove('has-results')
      return false
    }

    let optionNode = selectEl.options[selectEl.selectedIndex]
    this.updateOtherFields(optionNode.dataset)
    if (selectEl.value === 'Applicant') {
      this.loadableSelectWrapperTarget.classList.remove('has-results')
      this.loadableSelectTarget.removeAttribute('required')
    } else {
      this.loadableSelectWrapperTarget.classList.add('has-results')
      const url = optionNode.getAttribute('data-url')
      selectEl.setAttribute('data-url', url)
      
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.populateSelect({ detail: [data] })
        })
        .catch(error => console.error('Error:', error))
    }

    $(this.loadableSelectTarget).select2('destroy')
    this.initSelect2()
  }

  populateSelect (e) {
    if (!this.options_selector) {
      this.options_selector = $(this.loadableSelectTarget).find('option[selected]')
    }
    const [data] = e.detail
    let selected_options = []
    let all_options = ''
    for (let i = 0; i < this.options_selector.length; i++) {
      selected_options.push(this.options_selector[i].value)
    }
    for (let i = 0; i < data.length; i++) {
      if (selected_options.includes(data[i].id.toString())) {
        all_options += `<option value="${data[i].id}" selected>${data[i].name}</option>`
      } else {
        all_options += `<option value="${data[i].id}">${data[i].name}</option>`
      }
    }
    $(this.loadableSelectTarget).find('option').remove().end()
    $(this.loadableSelectTarget).append(all_options)
    this.initSelect2()
  }

  updateOtherFields (data) {
    for (const updatable of this.formTarget.querySelectorAll('[data-update-key]')) {
      if (data) {
        updatable.value = data[updatable.getAttribute('data-update-key')]
      }
    }
  }

  recipientsRequired() {
    var option = this.loaderTriggerTarget.options[this.loaderTriggerTarget.selectedIndex]

    this.loadableSelectTarget.required = option.dataset.recipientsRequired === 'true'
  }
}
