import { FieldName, Option } from 'components/types'

import useFieldSchema from './useFieldSchema'

const useFieldValues = (fieldName: FieldName): Option[] => {
  const schema = useFieldSchema(fieldName)
  return schema?.values || []
}

export default useFieldValues
