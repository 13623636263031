import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useForm, FormProvider } from 'react-hook-form'

import useQuoteRequestApi from 'components/useQuoteRequestApi'
import fieldsNames from 'components/Fields/Base/fieldNames'
import {
  Contact,
  FormSettings,
  FormInputs,
  SupportedRequestedPropertySectors,
  SupportedRequestedReportTypes,
  UserType,
} from 'components/types'

import AdditionalDetails from 'components/Steps/AdditionalDetails'
import Alert from 'components/Alert'
import ApplicantDetails from 'components/Steps/ApplicantDetails'
import ConfirmationMessage from 'components/ConfirmationMessage'
import FormSettingsContext from 'components/FormSettingsContext'
import LoanDetails from 'components/Steps/LoanDetails'
import PropertyDetails from './Steps/PropertyDetails'
import ReAddressConfirmation from 'components/ReAddressConfirmation'
import ReportDetails from 'components/Steps/ReportDetails'
import ValuationDetails from 'components/Steps/ValuationDetails'

const containerWidth = 800

interface Props {
  contacts: Contact[]
  userType: UserType
  settings: FormSettings
  supportedRequestedReportTypes: SupportedRequestedReportTypes
  supportedRequestedPropertySectors: SupportedRequestedPropertySectors
  uploadPath: string
}

const QuoteRequestForm: React.FC<Props> = ({
  contacts,
  userType,
  settings,
  supportedRequestedReportTypes,
  supportedRequestedPropertySectors,
  uploadPath,
}) => {
  const methods = useForm<FormInputs>({ mode: 'all' })
  const {
    submit,
    state,
    submitting,
    success,
    error,
    clearState,
  } = useQuoteRequestApi(uploadPath)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [addressSelected, setAddressSelected] = useState<boolean>(false)

  const handleSubmit = async () => {
    const { trigger, getValues } = methods
    const isValid = await trigger()
    const fields = getValues()

    await submit(isValid, fields)
  }

  const handleAddressSelected = (addressSelected: boolean) => {
    setAddressSelected(addressSelected)
  }

  const resetForm = () => {
    const { setValue } = methods

    setValue(fieldsNames.requestedPropertyType, undefined)
    setValue(fieldsNames.requestedPropertySector, undefined)
    setValue(fieldsNames.requestedReportType, undefined)

    setAddressSelected(false)
  }

  const handleValuationDetailsLock = () => {
    setShowForm(true)
  }

  const handleValuationDetailsReset = () => {
    setShowForm(false)
    resetForm()
  }

  const renderMainForm = () => (
    <>
      <LoanDetails />
      <ApplicantDetails />
      <AdditionalDetails contacts={contacts} />
      <ReportDetails />
      <div className="actions text-right mb-4">
        <Button
          className="text-muted"
          variant="link"
          href="/valuations"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={submitting}
        >
          {submitting ? 'Please wait...' : 'Submit'}
        </Button>
      </div>
    </>
  )

  if (success) {
    return (
      <Container className="my-5" style={{ maxWidth: containerWidth }}>
        <ConfirmationMessage />
      </Container>
    )
  }

  return (
    <Container style={{ maxWidth: containerWidth }}>
      <FormSettingsContext.Provider value={settings}>
        <FormProvider {...methods}>
          <input
            type="hidden"
            name={fieldsNames.userType}
            value={userType}
            ref={methods.register}
          />
          <ValuationDetails
            onLock={handleValuationDetailsLock}
            onReset={handleValuationDetailsReset}
            supportedRequestedReportTypes={supportedRequestedReportTypes}
            supportedRequestedPropertySectors={
              supportedRequestedPropertySectors
            }
          />
          {showForm && (
            <>
              <ReAddressConfirmation
                onResetForm={handleValuationDetailsReset}
              />
              <PropertyDetails
                onAddressSelected={handleAddressSelected}
              />
              {addressSelected && renderMainForm() }
            </>
          )}
        </FormProvider>
      </FormSettingsContext.Provider>
      <Alert show={error} state={state} onClose={clearState} />
    </Container>
  )
}

export default QuoteRequestForm
