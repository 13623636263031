import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["publicCheckbox", "asEmailCheckbox", "notifyEmailCheckbox", "emailOptions", "emailForm", "subjectField", "submitButton"]

  connect() {
    this.togglePublic()
    this.toggleEmailForm()
  }

  togglePublic() {
    const isPublic = this.publicCheckboxTarget.checked
    this.emailOptionsTarget.classList.toggle('d-none', !isPublic)
    if (!isPublic) {
      this.asEmailCheckboxTarget.checked = false
      this.notifyEmailCheckboxTarget.checked = false
      this.toggleEmailForm()
    }
  }

  toggleEmailOption(event) {
    const isAsEmail = event.target === this.asEmailCheckboxTarget
    const isNotifyEmail = event.target === this.notifyEmailCheckboxTarget

    if (isAsEmail && this.notifyEmailCheckboxTarget.checked) {
      this.notifyEmailCheckboxTarget.checked = false
    } else if (isNotifyEmail && this.asEmailCheckboxTarget.checked) {
      this.asEmailCheckboxTarget.checked = false
    }

    this.toggleEmailForm()
    this.toggleSubjectField()
    this.toggleEmailButton()
  }

  toggleEmailForm() {
    const showEmailForm = this.asEmailCheckboxTarget.checked || this.notifyEmailCheckboxTarget.checked
    this.emailFormTarget.classList.toggle('d-none', !showEmailForm)

    const emailFields = this.emailFormTarget.querySelectorAll('input, select, textarea')
    emailFields.forEach((field) => {
      if (showEmailForm) {
        field.setAttribute('required', 'required')
      } else {
        field.removeAttribute('required')
      }
    })
  }

  toggleSubjectField() {
    if (this.hasSubjectFieldTarget) {
      const isAsEmail = this.asEmailCheckboxTarget.checked
      if (isAsEmail) {
        this.subjectFieldTarget.classList.remove('d-none')
        this.subjectFieldTarget.classList.add('d-flex')
        this.subjectFieldTarget.querySelector('input').setAttribute('required', 'required')
      } else {
        this.subjectFieldTarget.classList.add('d-none')
        this.subjectFieldTarget.classList.remove('d-flex')
        this.subjectFieldTarget.querySelector('input').removeAttribute('required')
      }
    }
  }

  toggleEmailButton() {
    if (this.asEmailCheckboxTarget.checked) {
      this.submitButtonTarget.value = 'Add Note & Send Email';
    } else if (this.notifyEmailCheckboxTarget.checked) {
      this.submitButtonTarget.value = 'Add Note & Notify';
    } else {
      this.submitButtonTarget.value = 'Add Note';
    }
  }
}
