import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import TextField from 'components/Fields/Base/TextField'

const LenderName: React.FC = () => (
  <ConditionalField field={fieldNames.lenderName}>
    <TextField
      field={fieldNames.lenderName}
      label="Name of lender the report is to be addressed to *"
      validationRules={{ required: true }}
    />
  </ConditionalField>
)

export default LenderName
