import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import $ from 'jquery'

export default class extends Controller {
  updateNote (event) {
    const form = $(event.target).parents('form')[0]
    Rails.fire(form, 'submit')
  }

  updateNoteSuccess (e) {
    const [, , xhr] = e.detail
    const targetNote = $(e.target).parents('.note')
    targetNote.replaceWith(xhr.response)
  }
}
