import React from 'react'

import { FieldName } from 'components/types'
import useCanDisplayField from 'components/Fields/Base/useCanDisplayField'

import StepHeadline from './StepHeadline'
import StepBody from './StepBody'

interface Props {
  idx: number
  title: string
  dependentFields?: FieldName[]
}

const SimpleStep: React.FC<Props> = ({
  idx,
  title,
  children,
  dependentFields = [],
}) => {
  const canDisplayAll = dependentFields
    .map(useCanDisplayField)
    .every((canDisplay) => canDisplay)

  return canDisplayAll ? (
    <>
      <StepHeadline idx={idx} title={title} />
      <StepBody>{children}</StepBody>
    </>
  ) : null
}

export default SimpleStep
