import React, { useEffect, useState } from 'react'

import { SubmittingState } from 'components/types'

interface Props {
  show: boolean
  state: SubmittingState
  onClose: () => void
}
const Alert: React.FC<Props> = ({ show, state, onClose }) => {
  const [lastState, setLastState] = useState<SubmittingState>(null)

  useEffect(() => {
    setLastState(state)
  }, [state])

  const humanMessage = {
    serverError:
      'Your quote request has not been submitted. Please check the form and try again. If this problem continues, please let us know.',
    validationError:
      'Please correct the issues highlighted above and submit again.',
  }

  return (
    <div id="alerts" style={{ pointerEvents: show ? null : 'none' }}>
      <div
        className={`notification notification-danger fade-${
          show ? 'in' : 'out'
        }`}
      >
        <div className="icon">
          <i className="fa fa-exclamation fa-w-6 fa-2x" aria-hidden="true" />
        </div>
        <div className="detail">
          <h4>Oops! Something went wrong!</h4>
          <p>{humanMessage[lastState]}</p>
        </div>
        <button className="close" onClick={onClose}>
          <i className="fa fa-times fa-w-11" aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}

export default Alert
