import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link', 'filefield', 'file']

  removeFile (e) {
    this.fileTarget.remove()
  }

  get url () {
    return this.linkTarget.dataset.directRemoveUrl
  }
}
