import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import FormattedTextArea from 'components/Fields/Base/FormattedTextArea'

const removeHTML = function(str: string){
  var tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
}

const PropertyAdditionalInformation: React.FC = () => (
  <ConditionalField field={fieldNames.propertyAdditionalInformation}>
    <FormattedTextArea
      label="Property Description *"
      field={fieldNames.propertyAdditionalInformation}
      disableColorPicker={true}
      validationRules={{
        required: true,
        validate: {
          minTrixLength: (v: string) => removeHTML(v).length >= 10 || 'Must contain at least 10 characters'
        }
      }}
    />
  </ConditionalField>
)

export default PropertyAdditionalInformation
