import { Controller } from 'stimulus'
import { fromEvent, fromEventPattern, merge, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map, scan } from "rxjs/operators";

export default class extends Controller {
  static targets = ['search', 'assignee', 'escalation', 'lender', 'valuer',
                    'broker', 'reportType', 'valuerRag', 'auditorName',
                    'priorityFlag', 'quoteStatus' ]

  initialize() {
    this.searchChange$ = fromEvent(this.searchTarget, "keyup").pipe(
      map((e) => e.target.value),
      map(el => ({ser: el})),
      debounceTime(500),
      distinctUntilChanged());

    this.escalationChange$ = fromEvent(this.escalationTarget, "input")
      .pipe(
        map((e) => e.target.value),
        map(el => ({esc: el}))
      );

    this.assigneeChange$ = fromEvent(this.assigneeTarget, "input")
      .pipe(
        map((e) => e.target.value),
        map(el => ({ass: el}))
      );

    // select2 doesn't emit DOM events
    // https://select2.org/programmatic-control/events#internal-select2-events
    this.labelInput = $('#label_filter');
    this.labelChange$ = fromEventPattern(
      handler => this.labelInput.on('change', handler),
      handler => this.labelInput.off('change', handler)
    ).pipe(
      map((e) => [].map.call(e.target.selectedOptions, el => el.text)),
      map(el => ({lab: el}))
    );

    this.lenderChange$ = fromEvent(this.lenderTarget, "input")
      .pipe(
        map((e) => e.target.value),
        map(el => ({len: el}))
      );

    this.valuerChange$ = fromEvent(this.valuerTarget, "input")
      .pipe(
        map((e) => e.target.value),
        map(el => ({valFirm: el}))
      );

    this.brokerChange$ = fromEvent(this.brokerTarget, "input")
      .pipe(
        map((e) => e.target.value),
        map(el => ({bro: el}))
      );

    this.reportTypeChange$ = fromEvent(this.reportTypeTarget, "input")
      .pipe(
        map((e) => e.target.value),
        map(el => ({reportType: el}))
      );

    if (this.auditorNameTargets.length > 0) {
      this.auditorNameChange$ = fromEvent(this.auditorNameTarget, "input")
        .pipe(
            map((e) => e.target.value),
            map(el => ({auditorName: el}))
        );
    }

    if (this.valuerRagTargets.length > 0) {
      this.valuerRagChange$ = fromEvent(this.valuerRagTarget, "input")
        .pipe(
            map((e) => e.target.value),
            map(el => ({ragRating: el}))
        );
    }

    if (this.priorityFlagTargets.length > 0) {
      this.priorityFlagChange$ = fromEvent(this.priorityFlagTarget, "input")
          .pipe(
              map((e) => e.target.value),
              map(el => ({priorityFlag: el}))
          );
    }

    if (this.quoteStatusTargets.length > 0) {
      this.quoteStatusChange$ = fromEvent(this.quoteStatusTarget, "input")
        .pipe(
          map((e) => e.target.value),
          map(el => ({quoteStatus: el}))
        );
    }
  }

  connect() {
    let observers = [
      this.searchChange$,
      this.escalationChange$,
      this.assigneeChange$,
      this.labelChange$,
      this.lenderChange$,
      this.valuerChange$,
      this.brokerChange$,
      this.reportTypeChange$,
      this.auditorNameChange$,
      this.valuerRagChange$,
      this.priorityFlagChange$,
      this.quoteStatusChange$
    ]
    merge.apply(this, observers.filter((v) => v !== undefined))
      .pipe(
        scan((acc, curr) => Object.assign({}, acc, curr), {})
      ).subscribe((v) => {
      document.dispatchEvent(new CustomEvent('board_list_event_change', {detail: {filter: v}}));
    });
  }
}
