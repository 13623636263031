import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['modal']

  setModal (e) {
    // [xhr, options]
    const [xhr] = e.detail
    xhr.setRequestHeader('X-Modal', 'true')
  }

  open (e) {
    if(!this.currentlyOpen) {
      this.currentlyOpen = true
      // [data, status, xhr]
      const [data] = e.detail
      let modal = data.body.querySelector('.modal')
      let node = document.importNode(modal, true)
      if (e.target.dataset.modalId) {
        node.id = e.target.dataset.modalId
      }
      this.turbo_frame_tag = e.target.dataset.id || null
      let turbo_frame_tag = this.turbo_frame_tag

      if (turbo_frame_tag) {
        let turboFrame = document.createElement('turbo-frame')
        turboFrame.id = turbo_frame_tag
        turboFrame.appendChild(node)
        document.body.appendChild(turboFrame)
      } else {
        document.body.appendChild(node)
      }

      $(node).modal('show')

      $(node).on('hidden.bs.modal', (ev) => {
        ev.target.remove()
        this.currentlyOpen = false
      })
    }
  }

  close () {
    $(this.modalTarget).modal('hide')
    this.currentlyOpen = false
    // this.modalTarget.remove()
    // document.querySelector('.modal-backdrop').remove()
  }

  disconnect () {
    let modals = document.querySelectorAll('.modal')
    let backdrops = document.querySelectorAll('.modal-backdrop')
    let turboFrame = document.getElementById(this.turbo_frame_tag)

    if (turboFrame) {
      turboFrame.remove()
    }

    if (backdrops.length > modals.length) {
      backdrops[backdrops.length - 1].remove()
    }
  }
}
