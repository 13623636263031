import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'
import TextField from 'components/Fields/Base/TextField'

const Broker: React.FC = () => (
  <>
    <ConditionalField field={fieldNames.broker}>
      <SelectField
        field={fieldNames.broker}
        label="Is there a broker involved? *"
        validationRules={{ required: true }}
      />
    </ConditionalField>
    <ConditionalField field={fieldNames.brokerName}>
      <TextField
        field={fieldNames.brokerName}
        label="Broker company name *"
        validationRules={{ required: true }}
      />
    </ConditionalField>
    <ConditionalField field={fieldNames.lenderReferenceNumber}>
      <TextField
          field={fieldNames.lenderReferenceNumber}
          label="Lender reference"
          hint="Optional: please provide the unique case reference if you have one"
          validationRules={{ required: false }}
      />
    </ConditionalField>
    <ConditionalField field={fieldNames.lenderAddressee}>
      <SelectField
        field={fieldNames.lenderAddressee}
        label="Report to be addressed to *"
        validationRules={{ required: true }}
      />
    </ConditionalField>
  </>
)

export default Broker
