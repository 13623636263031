import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['postValuationQueryForm', 'raisedBySelect', 'solicitorRow', 'solicitorSelect', 'postValuationQuerySubmit', 
                   'solicitorSelectButton', 'carbonCopyContactsSelectSecond', 'categoryInput', 'detailsInput']
  
  check_if_solicitor() {
    if (this.solicitorRowTarget.classList.contains('d-none') && this.raisedBySelectTarget.value == 'solicitor') {
      this.solicitorRowTarget.classList.remove('d-none')
      this.solicitorSelectTarget.disabled = false
    } else {
      this.solicitorRowTarget.classList.add('d-none')
      this.solicitorSelectTarget.disabled = true
    }
    this.postValuationQueryFormTarget.method = 'get'
  }

  cc_solicitor_changed() {
    this.postValuationQueryFormTarget.method = 'get'
    if (this.hasCategoryInputTarget) {
      this.categoryInputTarget.removeAttribute('required')
    }
    if (this.hasDetailsInputTarget) {
      this.detailsInputTarget.removeAttribute('required')
    }
    this.postValuationQuerySubmitTarget.disabled = true
    this.solicitorSelectButtonTarget.disabled = false
    this.carbonCopyContactsSelectSecondTarget.style.display = 'none'
  }
}
