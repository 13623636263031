import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'
import ConditionalField from 'components/Fields/Base/ConditionalField'
import FormattedTextArea from 'components/Fields/Base/FormattedTextArea'

const placeholder =
  'Please provide any additional details / documents you think necessary that will assist the valuer including:\n' +
  '- Any special requirements, additional bases of valuation, special assumptions that need to be made.\n' +
  '- Details of the property condition.\n' +
  '- Report required urgently by a certain date for property purchase / auction purchase etc.\n' +
  '- If development - provide planning details etc.\n' +
  '- Provide sales details / brochure if applicable.'

const AdditionalInformation: React.FC = () => (
  <ConditionalField field={fieldNames.additionalInformation}>
    <FormattedTextArea
      field={fieldNames.additionalInformation}
      label="Additional information"
      placeholder={placeholder}
      disableColorPicker={true}
    />
  </ConditionalField>
)

export default AdditionalInformation
