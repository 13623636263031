import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'

interface Props {
  disabled: boolean
}

const RequestedPropertyType: React.FC<Props> = ({ disabled }) => (
  <ConditionalField field={fieldNames.requestedPropertyType}>
    <SelectField
      field={fieldNames.requestedPropertyType}
      label="Requested property type"
      hint="If Residential has been selected, this does not take into account any refurbishment / GDV requests. Please select Developments / Land or buildings (WITH planning permission)."
      disabled={disabled}
    />
  </ConditionalField>
)

export default RequestedPropertyType
