import { maxBy } from 'lodash'

import {
  FieldName,
  FormSettings,
  Schema,
} from 'components/types'
import getFieldSchemas from 'components/Fields/Base/getFieldSchemas'

/**
 * Function returning the best matching schema for a given field
 */
const matchingFieldSchema = (
  fieldName: FieldName,
  fieldValues: { [key in FieldName]: string },
  settings: FormSettings
): Schema => {
  const schemas = getFieldSchemas(fieldName, settings)

  const matchingSchemas = schemas.filter((s) =>
    Object.entries(s.rules || {}).every(
      ([dependentFieldName, dependentFieldValue]) => {
        const fieldValue = fieldValues[dependentFieldName]

        if (Array.isArray(dependentFieldValue)) {
          return dependentFieldValue
            .map((v) => v.toString())
            .includes(fieldValue)
        } else {
          return dependentFieldValue.toString() === fieldValue
        }
      }
    )
  )

  return maxBy(matchingSchemas, (s) => Object.keys(s.rules).length)
}

export default matchingFieldSchema
