import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container', 'notification']

  showNotification (e) {
    this._hideNotifications()
    let note = e.target
    note.dataset.added = true
    this.containerTarget.appendChild(note)
    note.focus()
    note.classList.add('fade-in')
    if (typeof note.dataset.clear !== 'undefined') {
      note.addEventListener('transitionend', () => {
        let hideEvent = new CustomEvent('hide')
        setTimeout(() => note.dispatchEvent(hideEvent), 3000)
      })
    }
  }

  _hideNotifications () {
    for (const notification of this.notifications) {
      const data = notification.dataset
      if (notification.classList.contains('fade-in') && (typeof data.clear !== 'undefined' || typeof data.form !== 'undefined')) {
        let hideEvent = new CustomEvent('hide')
        notification.dispatchEvent(hideEvent)
      }
    }
  }

  get notifications () {
    return this.containerTarget.querySelectorAll('.notification')
  }
}
