import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    $(document).ready(() => {
      if (!this.element.dataset.added) {
        const event = new CustomEvent('notification:added', {bubbles: true, cancelable: true})
        this.element.dispatchEvent(event)
      }
    })
  }

  close (e) {
    const el = e.target.closest('.notification')
    this._hideNotification(el)
  }

  hide (e) {
    const el = e.currentTarget
    this._hideNotification(el)
  }

  _hideNotification (el) {
    el.addEventListener('transitionend', () => el.remove())
    setTimeout(() => {
      el.classList.remove('fade-in')
      el.classList.add('fade-out')
    }, 3)
  }
}
