import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import TextAreaField from 'components/Fields/Base/TextAreaField'

const PropertyAmendments: React.FC = () => {
  return (
    <ConditionalField field={fieldNames.propertyAmendments}>
      <TextAreaField
        field={fieldNames.propertyAmendments}
        label="Amendments Required"
        hint="Do you need a different basis of valuation?"
        rows={3}
      />
    </ConditionalField>
  )
}

export default PropertyAmendments
