import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  submit (e) {
    e.preventDefault()
    const target = document.querySelector(this.element.dataset.target)
    if (typeof target.dataset.remote === 'undefined') {
      target.submit()
    } else {
      Rails.fire(target, 'submit')
    }
  }
}
