export const templateSelection = (state, container) => {
  if (!state || !state.element) {
    return state?.text || '';
  }

  const element = state.element;

  if (element && element.dataset?.color && element.dataset?.textColor) {
    const label = container[0];
    const closeButton = label.children[0];
    label.setAttribute(
      'style',
      `background-color: ${element.dataset.color}; color: ${element.dataset.textColor}`
    );
    closeButton.setAttribute('style', `color: ${element.dataset.textColor}`);
  }

  return state.text;
}
