import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'

const PropertyContract: React.FC = () => {
  return (
    <ConditionalField field={fieldNames.propertyContract}>
      <SelectField field={fieldNames.propertyContract} label="Contract" />
    </ConditionalField>
  )
}

export default PropertyContract
