import React from 'react'
import { Form } from 'react-bootstrap'

const messages = {
  required: "can't be blank",
  pattern: "doesn't match pattern",
}

type Error = {
  type: string
  message?: string
}

interface Props {
  error: Error
}

const ErrorMessage: React.FC<Props> = ({ error }) =>
  error ? (
    <Form.Control.Feedback type="invalid">
      {messages[error.type] || error?.message}
    </Form.Control.Feedback>
  ) : null

export default ErrorMessage
