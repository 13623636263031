import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['response', 'form', 'submit']

  disableEmailCheckbox(e) {
    const asEmailCheckbox = document.querySelector('#note_as_email');
    if (!e.target.checked && asEmailCheckbox.checked) {
      asEmailCheckbox.click()
    }
  }

  disableSubmit(e) {
    if (this.submitTarget) {
      this.submitTarget.classList.add('disabled')
    }
  }

  renderErrors (e) {
    const [, , xhr] = e.detail
    e.currentTarget.outerHTML = xhr.response
  }

  renderSuccess (e) {
    const el = e.currentTarget
    const df = this._documentFragment(e)
    if (el.dataset.placement === 'append') this._append(el, df)
    else if (el.dataset.placement === 'prepend') this._prepend(el, df)
    else if (el.dataset.placement === 'replace') this._replace(el, df)
    else if (el.dataset.placement === 'replace_inner') this._replaceInner(el, e)
  }

  dispatchEvent (e) {
    const el = e.currentTarget
    let eventName = el.dataset.eventName
    let eventTarget = document.querySelector(el.dataset.eventTarget)
    let event = new CustomEvent(eventName, { bubbles: true })
    eventTarget.dispatchEvent(event)
  }

  // Private methods

  _append (element, fragment) {
    this._target(element).append(fragment)
  }

  _prepend (element, fragment) {
    this._target(element).prepend(fragment)
  }

  _replace (element, fragment) {
    this._target(element).replaceWith(fragment)
  }

  _replaceInner (el, e) {
    const element = this._target(el)
    const [, , xhr] = e.detail
    element.innerHTML = xhr.responseText
  }

  _documentFragment (e) {
    const [data] = e.detail
    const df = document.createDocumentFragment()
    for (const node of data.body.children) df.appendChild(node)
    return df
  }

  _target (el) {
    return document.querySelector(el.dataset.responseTarget)
  }
}
