import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'
import TextAreaField from 'components/Fields/Base/TextAreaField'

const PropertyRefurbishment: React.FC = () => (
  <>
    <ConditionalField field={fieldNames.propertyRefurbishment}>
      <SelectField
        field={fieldNames.propertyRefurbishment}
        label="Type of refurbishment required? *"
        hint="Please provide as much information as possible as it will affect the type of valuer we select."
        validationRules={{ required: true }}
      />
    </ConditionalField>
    <ConditionalField field={fieldNames.propertyRefurbishmentDetails}>
      <TextAreaField
        field={fieldNames.propertyRefurbishmentDetails}
        label="Please provide details *"
        placeholder="Please provide details of the refurbishment e.g schedule/cost of works as well as the end value upon completion of these works"
        rows={3}
        validationRules={{ required: true }}
      />
    </ConditionalField>
  </>
)

export default PropertyRefurbishment
