import { Controller } from 'stimulus'

// select_input_changed_alert_controller.js
// This controller is used to alert the user when the selection of a select input is changed
// The user will be prompted to confirm the change of selection
// If the user confirms the change, the previous value will be updated to the new value
// example params:
// { data: { controller: 'select-input-changed-alert',
//   action: 'change->select-input-changed-alert#confirmChange',
//   select_input_changed_alert_original_value: f.object.led_by_api.to_s,
//   select_input_changed_alert_message_value: I18n.t('valuations.form.led_by_api_changed.confirmation') } }

export default class extends Controller {
  static values = { original: String, message: String }

  confirmChange() {
    if (("" + this.originalValue) != ("" + this.element.value)) {
      if (!confirm(this.messageValue)) {
        this.element.value = this.originalValue
      }
    }
  }
}
