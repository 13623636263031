import { Controller } from 'stimulus'
import { templateSelection } from '../utils/select2_templates'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['valuer', 'sendTo', 'contactList', 'labelsList', 'valuerLabels']

  connect () {
    this.fetchContacts(this.valuerTarget)
  }

  fetchContacts (valuer) {
    let setContacts = this.setContacts.bind(this)

    $.ajax({
      type: 'GET',
      url: valuer.dataset.url,
      processData: false,
      contentType: 'application/json',
      success: setContacts
    })
  }

  setContacts (data, status, xhr) {
    if (data.contacts && data.contacts.length) {
      for (let i = 0; i < data.contacts.length; i++) {
        let option = document.createElement('OPTION')
        option.value = data.contacts[i].id
        option.innerHTML = data.contacts[i].name
        this.contactListTarget.appendChild(option)
      }
      this.sendToTarget.removeAttribute('hidden')
      $(this.contactListTarget).select2({
        width: '100%',
      })
    } else {
      this.sendToTarget.remove()
    }
    this.initLabelList()
  }

  initLabelList() {
    $(this.labelsListTarget).select2({
      placeholder: "",
      width: '100%',
      templateSelection: templateSelection,
    })
    this.valuerLabelsTarget.removeAttribute('hidden')
  }

  disconnect() {
    $(this.contactListTarget).select2('destroy')
    $(this.labelsListTarget).select2('destroy')
  }
}
