import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import TextField from 'components/Fields/Base/TextField'

const PropertyValuationFirm: React.FC = () => (
  <>
    <ConditionalField field={fieldNames.propertyValuationFirm}>
      <TextField
        field={fieldNames.propertyValuationFirm}
        label="Valuation Firm"
        hint="Please provide the name of the firm that carried out the valuation."
        helpTexts={[
          'Please note that in some circumstances we have set valuer parameters, we will be in touch asap to confirm whether we can proceed with this opportunity. Please attach original report in section 5 below.',
        ]}
      />
    </ConditionalField>
  </>
)

export default PropertyValuationFirm
