import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ["select"]

  connect() {
    this.selectedNotes = new Set()
    this.initializeOptions()
    this.initializeEditorAndNotes()
    this.reapplySelectedStyling()
  }

  initializeEditorAndNotes() {
    const editor = document.querySelector('trix-editor')
    if (editor) {
      const content = editor.editor.getDocument().toString()
      this.initializeExistingNotes(content)
      this.setupEditorChangeHandler(editor.editor)
    }
  }

  initializeExistingNotes(content) {
    this.originalOptions.forEach(option => {
      if (content.includes(option.value)) {
        const selectOption = Array.from(this.selectTarget.options)
          .find(opt => opt.value === option.value)
        if (selectOption) {
          selectOption.style.color = '#DCDCDC'
          selectOption.disabled = true
          this.updateOriginalOption(selectOption)
          this.selectedNotes.add(option.value)
        }
      }
    })
  }

  initializeOptions() {
    this.originalOptions = Array.from(this.selectTarget.options).map(option => ({
      value: option.value,
      text: option.text,
      selected: option.selected,
      style: {
        color: option.style.color,
        backgroundColor: option.style.backgroundColor
      }
    }))
  }

  filterOptions(event) {
    const filterText = event.target.value.toLowerCase()
    this.renderFilteredOptions(filterText)
  }

  clearFilter() {
    const filterInput = this.element.querySelector('#quick-notes-filter')
    filterInput.value = ''
    this.renderFilteredOptions('')
  }

  renderFilteredOptions(filterText) {
    const filteredOptions = this.originalOptions.filter(option =>
      option.text.toLowerCase().includes(filterText)
    )

    this.selectTarget.innerHTML = ''
    filteredOptions.forEach(option => {
      const newOption = document.createElement('option')
      newOption.value = option.value
      newOption.text = option.text
      newOption.selected = option.selected
      newOption.style.color = option.style.color
      newOption.style.backgroundColor = option.style.backgroundColor
      this.selectTarget.add(newOption)
    })
    this.reapplySelectedStyling()
  }

  reapplySelectedStyling() {
    this.selectTarget.querySelectorAll('option').forEach(option => {
      if (option.selected) {
        option.style.backgroundColor = '#e6e6e6'
        option.style.color = '#333'
      } else {
        option.style.backgroundColor = ''
        option.style.color = option.style.color || ''
      }
    })
  }

  selectAndAppend(e) {
    const selectedOption = e.target.options[e.target.selectedIndex]
    const quickNoteValue = selectedOption.value

    if (this.selectedNotes.has(quickNoteValue)) {
      e.preventDefault()
      return
    }

    this.selectedNotes.add(quickNoteValue)

    selectedOption.style.color = '#DCDCDC'
    selectedOption.disabled = true
    this.updateOriginalOption(selectedOption)
    $(e.target).find('option:selected').css('color', '#DCDCDC');
    const editor = document.getElementById(e.target.dataset.targetField).parentElement.querySelector('trix-editor').editor
    let text = editor.element.value

    if (text.includes("<ul>")) {
      text = text.replace("</ul>", `<li>${quickNoteValue}<li></ul>`)
    } else {
      text += `<ul><li>${quickNoteValue}<li></ul>`
    }
    editor.loadHTML(text)

    this.setupEditorChangeHandler(editor)

    e.target.blur()
    e.target.selectedIndex = -1
  }

  setupEditorChangeHandler(editor) {
    if (this.changeHandler) {
      editor.element.removeEventListener('trix-change', this.changeHandler)
    }

    this.changeHandler = () => {
      const currentText = editor.element.value

      Array.from(this.selectedNotes).forEach(note => {
        if (!currentText.includes(note)) {
          this.selectedNotes.delete(note)
          this.resetOptionStyling(note)
        }
      })
    }

    editor.element.addEventListener('trix-change', this.changeHandler)
  }

  resetOptionStyling(noteValue) {
    const option = Array.from(this.selectTarget.options).find(opt => opt.value === noteValue)
    if (option) {
      option.style.color = ''
      option.style.backgroundColor = ''
      option.disabled = false
    }

    const originalOption = this.originalOptions.find(o => o.value === noteValue)
    if (originalOption) {
      originalOption.style.color = originalOption.style.color || ''
      originalOption.style.backgroundColor = originalOption.style.backgroundColor || ''
      originalOption.disabled = false
    }
  }

  updateOriginalOption(selectedOption) {
    const originalOption = this.originalOptions.find(o => o.value === selectedOption.value)
    if (originalOption) {
      originalOption.style.color = '#DCDCDC'
    }
  }
}
