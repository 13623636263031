import { Controller } from 'stimulus'
import cleanArray from 'clean-array'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['postcode', 'list', 'line1', 'line2', 'line3', 'line4', 'city', 'county', 'country']

  setup (e) {
    if (!this.postcodeTarget.value.length) e.preventDefault()

    this.postcodeTarget.value = this.postcodeTarget.value.toUpperCase()

    e.currentTarget.dataset.params = $.param({
      postcode: this.postcodeTarget.value,
      expand: true
    })
  }

  update(e) {
    const [data] = e.detail
    this.list = data.addresses
    if (data.addresses.length == 1) {
      this.property = data.addresses[0]
    }
  }

  select (e) {
    const option = e.currentTarget.options[e.currentTarget.selectedIndex]
    this.property = JSON.parse(option.dataset.address)
  }

  _textAddress (address) {
    return cleanArray(address.formatted_address).join(', ')
  }

  set list (list) {
    this.listTarget.innerHTML = ''
    this.listTarget.add(new Option('Select Address', ''))
    for (const property of list) {
      let option = new Option(this._textAddress(property), this._textAddress(property))
      option.dataset.address = JSON.stringify(property)
      this.listTarget.add(option)
    }
  }

  set property(property) {
    this.line1Target.value = property.line_1
    this.line2Target.value = property.line_2
    this.line3Target.value = property.line_3
    this.line4Target.value = property.line_4
    this.cityTarget.value = property.town_or_city
    this.countyTarget.value = property.county

    if (this.hasCountryTarget) {
      this._setCountry(property.country)
    }
  }

  // Country name is returned from API as fully qualified country name
  // We need to convert it to the key so proper option can be selected.
  _setCountry(countryName) {
    let countryVal = $(this.countryTarget).data("countries")[countryName]
    $(this.countryTarget).val(countryVal)
  }
}
