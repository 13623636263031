import { Controller } from 'stimulus'

/**
 * Extracts and parses the JSON stored in the `data-val` attribute of a provided HTML element.
 * @param {HTMLElement} card - The HTML element from which to extract the attribute.
 * @returns {Object} The parsed JSON object from the card's `data-val` attribute.
 */
const attrsFromCard = (card) => {
  return JSON.parse(card.getAttribute('data-val'));
}

/**
 * Compares two attributes, optionally treating them as dates, and supports ascending or descending order.
 * @param {*} attrA - The first attribute to compare.
 * @param {*} attrB - The second attribute to compare.
 * @param {boolean} isDateTime - Flag to determine if the attributes should be treated as datetime values.
 * @param {boolean} isDescending - Flag to determine if the sorting should be in descending order.
 * @returns {number} - The comparison result: -1, 0, or 1, suitable for array sorting methods.
 */
function compareAttributes(attrA, attrB, isDateTime, isDescending) {
  if (attrA === undefined || attrB === undefined) {
    return 0;
  }

  let comparison;

  if (isDateTime) {
    const dateA = new Date(attrA).getTime();
    const dateB = new Date(attrB).getTime();
    comparison = dateA - dateB;
  } else {
    const valueA = String(attrA);
    const valueB = String(attrB);
    comparison = valueA.localeCompare(valueB);
  }

  return isDescending ? -comparison : comparison;
}

/**
 * Sorts an array of elements based on multiple keys recursively, allowing for complex sorting orders.
 * Supports sorting by nested keys and handling datetime values. Groups and sorts each segment recursively.
 * @param {Array} cards - The array of elements to sort.
 * @param {Array<string>} sortKeys - The keys to sort by, each an attribute name from the elements, optionally prefixed with '-' for descending order.
 * @returns {Array} The sorted array of elements.
 */
function recursiveMultipleSort(cards, sortKeys) {
  if (sortKeys.length === 0) {
    return cards;
  }

  const key = sortKeys[0].startsWith('-') ? sortKeys[0].substring(1) : sortKeys[0];
  const isDescending = sortKeys[0].startsWith('-');
  const isDateTime = key.endsWith('At');

  cards.sort((cardA, cardB) => {
    const attrA = attrsFromCard(cardA)[key];
    const attrB = attrsFromCard(cardB)[key];
    return compareAttributes(attrA, attrB, isDateTime, isDescending);
  });

  let result = [];
  let currentGroup = [];
  let lastValue = null;

  // Loop over each card, grouping them by the current key's value.
  cards.forEach(item => {
    let card_attributes = attrsFromCard(item);

    // Determine the grouping value, handling datetime specifically.
    let groupingValue;
    if (isDateTime) {
      if (card_attributes[key] === null) {
        groupingValue = '1970-01-01'; // Default date for null datetime values
      } else {
        groupingValue = card_attributes[key].substring(0, 10); // Extract date part only, omitting time
      }
    } else {
      groupingValue = card_attributes[key];
    }

    // Check if the current card can be added to the current group or starts a new group.
    if (lastValue === null || groupingValue === lastValue) {
      currentGroup.push(item);
    } else {
      // If a new group starts, sort the current group by the next sort key and add to result.
      result = result.concat(recursiveMultipleSort(currentGroup, sortKeys.slice(1)));
      currentGroup = [item];
    }
    lastValue = groupingValue;
  });

  // After the loop, sort any remaining group of cards.
  result = result.concat(recursiveMultipleSort(currentGroup, sortKeys.slice(1)));

  return result;
}


export default class extends Controller {
  static targets = ['counter', 'list', 'meta']
  static values = {
    showLoadingState: { type: Boolean }
  }

  initialize() {
    this.observer = new MutationObserver(() => {
      this.render()
    })
    if (this.showLoadingStateValue) {
      this.setLoadingState()
    } else {
      this.updateCounter()
    }
  }

  connect() {
    this.registerObserver()
    this.setupEventListeners()
  }

  disconnect() {
    this.unregisterObserver()
    this.removeEventListeners()
  }

  setupEventListeners() {
    this.boundHandleBoardListChange = (e) => {
      this.lastestFilter = e.detail.filter
      this.render()
    }
    
    this.boundHandleTurboFrameLoad = () => {
      this.isLoading = false
      this.removeLoadingState()
    }

    this.boundHandleTurboFrameLoadStart = () => {
      if (this.showLoadingStateValue) {
        this.isLoading = true
        this.setLoadingState()
      }
    }

    document.addEventListener('board_list_event_change', this.boundHandleBoardListChange)
    
    // Handle Turbo frame loading events
    this.listTarget.addEventListener('turbo:frame-load', this.boundHandleTurboFrameLoad)
    this.listTarget.addEventListener('turbo:frame-loading', this.boundHandleTurboFrameLoadStart)
  }

  removeEventListeners() {
    document.removeEventListener('board_list_event_change', this.boundHandleBoardListChange)
    if (this.listTarget) {
      this.listTarget.removeEventListener('turbo:frame-load', this.boundHandleTurboFrameLoad)
      this.listTarget.removeEventListener('turbo:frame-loading', this.boundHandleTurboFrameLoadStart)
    }
  }

  setLoadingState() {
    const spinner = document.createElement('div')
    spinner.className = 'spinner-border text-primary'
    spinner.setAttribute('role', 'status')
    
    this.counterTarget.innerHTML = ''
    this.counterTarget.appendChild(spinner)
  }

  removeLoadingState() {
    this.updateCounter()
  }

  sort() {
    const sortParams = JSON.parse(this.metaTarget.dataset.sortBy || '[]');
    let cards = Array.from(this.listTarget.getElementsByClassName('board-card'));
    if (sortParams.length > 0) {
      cards = recursiveMultipleSort(cards, sortParams);
    }

    cards.forEach((card, idx) => {
      card.style.order = idx + 1;
    });
  }

  filter({ser, esc, ass, lab, len, bro, valFirm, reportType, auditorName, ragRating, priorityFlag, quoteStatus}) {
    const hideOnHoldColumn = 'hideOnHold' in this.metaTarget.dataset;
    const cardList = this.listTarget.getElementsByClassName('board-card');

    for (const el of cardList) {
      const elementAttr = attrsFromCard(el);
      const hasSearchString = ser ? el.innerText.toLowerCase().includes(ser) : true;
      let hasEscalationFlag = true;
      if (esc) {
        hasEscalationFlag = (elementAttr?.escalation_flag || 'none') === esc;
      }
      let hasAssignee = true;
      if (ass) {
        const assigneeId = Number(ass);
        hasAssignee = elementAttr?.user_ids?.includes(assigneeId);

        // show all Unassigned
        if (assigneeId === 0 && elementAttr?.user_ids?.length === 0) {
          hasAssignee = true
        }
      }
      const hasQuoflowLabel = lab && lab.length > 0 ? lab.every(x => elementAttr?.quoflow_labels?.includes(x)) : true;
      const hasServflowLabel = lab && lab.length > 0 ? lab.every(x => elementAttr?.servflow_labels?.includes(x)) : true;
      const hasComflowLabel = lab && lab.length > 0 ? lab.every(x => elementAttr?.comflow_labels?.includes(x)) : true;
      const hasProflowLabel = lab && lab.length > 0 ? lab.every(x => elementAttr?.proflow_labels?.includes(x)) : true;
      const isOnHold = elementAttr.on_hold === true;
      let hasLender = true;
      if (len) {
        const lenderId = Number(len);
        hasLender = elementAttr?.lender === lenderId;
      }
      let hasBroker = true;
      if (bro) {
        const brokerId = Number(bro);
        hasBroker = elementAttr?.broker === brokerId;
      }
      let hasValuer = true;
      if (valFirm) {
        const valFirmId = Number(valFirm);
        const valuer_array = Array.isArray(elementAttr?.valuer) ? elementAttr?.valuer: [elementAttr?.valuer];
        hasValuer = valuer_array.includes(valFirmId);
      }
      let hasReporttype = true;
      if(reportType) {
        hasReporttype = elementAttr?.report_type === reportType;
      }
      let hasAuditorName = true;
      if (auditorName) {
        const valAuditorName = Number(auditorName);
        hasAuditorName = (elementAttr?.auditor === valAuditorName);
      }
      let hasRagRating = true;
      if (ragRating) {
        const valRagRating = String(ragRating);
        hasRagRating = elementAttr?.rag === valRagRating;
      }
      let hasPriorityFlag = true;
      if (priorityFlag) {
        const valPriorityFlag = String(priorityFlag);
        hasPriorityFlag = elementAttr?.priority_flag === valPriorityFlag;
      }
      let hasQuoteStatus = true;
      if (quoteStatus) {
        const valQuoteStatus = String(quoteStatus);
        hasQuoteStatus = elementAttr?.quote_status?.includes(valQuoteStatus) || false;
      }
      if ([
        hasSearchString,
        hasEscalationFlag,
        hasAssignee,
        hasQuoflowLabel || hasServflowLabel || hasComflowLabel || hasProflowLabel,
        hasLender,
        hasBroker,
        hasValuer,
        hasReporttype,
        hasAuditorName,
        hasRagRating,
        hasPriorityFlag,
        hasQuoteStatus,
        !(hideOnHoldColumn && isOnHold)
      ].every(Boolean)) {
        this.showCard(el);
      } else {
        this.hideCard(el);
      }
    }
  }

  showCard(el) {
    el.classList.add('board-card--visible')
    el.classList.remove('board-card--hidden')
  }

  hideCard(el) {
    el.classList.remove('board-card--visible')
    el.classList.add('board-card--hidden')
  }

  registerObserver() {
    this.observer.observe(this.listTarget, { childList: true, subtree: true })
  }

  unregisterObserver() {
    this.observer.disconnect()
  }

  render() {
    this.sort()
    this.filter(this.lastestFilter || {})
    if (!this.isLoading) {
      this.updateCounter()
    }
  }

  updateCounter() {
    if (this.isLoading) {
      this.setLoadingState()
    } else {
      this.counterTarget.innerHTML = this.listTarget.getElementsByClassName(
        'board-card--visible'
      ).length
    }
  }
}
