import { Controller } from 'stimulus'
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

export default class extends Controller {
  connect () {
    dom.i2svg({ node: this.element })
  }
}
