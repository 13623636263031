import {
  FieldName,
  FormSettings,
  Schema,
} from 'components/types'

const getFieldSchemas = (
  fieldName: FieldName,
  settings: FormSettings
): Schema[] => {
  const schema = settings[fieldName]

  if (!schema) {
    console.error(`Cannot find schema for ${fieldName}`)
  }

  return schema
}
export default getFieldSchemas
