import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'

const PropertyValuationBasis: React.FC = () => (
  <ConditionalField field={fieldNames.propertyValuationBasis}>
    <SelectField
      field={fieldNames.propertyValuationBasis}
      label="Basis of valuation *"
      validationRules={{ required: true }}
      hint="If the property is owner occupied, please state whether you require the 'business value' including within the valuation. The valuer will follow your specific 'basis of value' within your instruction letter."
    />
  </ConditionalField>
)

export default PropertyValuationBasis
