import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import TextAreaField from 'components/Fields/Base/TextAreaField'

const PropertyDevelopmentDetails: React.FC = () => {
  return (
    <ConditionalField field={fieldNames.propertyDevelopmentDetails}>
      <TextAreaField
        field={fieldNames.propertyDevelopmentDetails}
        label="Development Description *"
        hint="e.g. Planning Ref, Number of units, Size, cost to complete etc. Please attach schedule of works in section 5 below"
        rows={3}
        validationRules={{ required: true }}
      />
    </ConditionalField>
  )
}

export default PropertyDevelopmentDetails
