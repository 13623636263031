import { useFormContext } from 'react-hook-form'

import { useFormSettings } from 'components/FormSettingsContext'

import { FieldName, FormSettings } from 'components/types'
import { getDependentFieldNames } from './getDependentFieldNames'
import matchingFieldSchema from 'components/Fields/Base/matchingFieldSchema'

export const canDisplayFiled = (
  fieldName: FieldName,
  fieldValues: { [key in FieldName]: string },
  settings: FormSettings
): boolean => {
  return !!matchingFieldSchema(fieldName, fieldValues, settings)
}

const useCanDisplayField = (fieldName: FieldName): boolean => {
  const { watch } = useFormContext()
  const settings = useFormSettings()

  const dependentFieldNames = getDependentFieldNames(fieldName, settings)
  const dependenciesWatch = watch(dependentFieldNames)

  return canDisplayFiled(fieldName, dependenciesWatch, settings)
}

export default useCanDisplayField
