import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'section', 'field', 'errorsAlert']

  toggleSection() {
    const sectionName = event.currentTarget.dataset.section;
    this.updateButtons(sectionName);
    this.displayForms(sectionName);
    this.displayFields(sectionName);

    this.removeErrorsAlert()
  }

  displayAlternative(){
    this.updateButtons('alternative-form');
    this.displayForms('complete-form');
    this.displayFields('alternative-form');

    this.removeErrorsAlert()
  }

  updateButtons(sectionName){
    this.buttonTargets.forEach((button) => {
      if (button.dataset.section === sectionName) {
        button.classList.remove("btn-active")
        button.classList.add("btn-active")
      } else {
        button.classList.add("btn-active")
        button.classList.remove("btn-active")
      }
    });
  }

  displayForms(sectionName) {
    this.sectionTargets.forEach((section) => {
      if (section.dataset.section === sectionName) {
        section.classList.remove("d-none");
        section.removeAttribute("disabled");
      } else {
        section.classList.add("d-none");
        section.setAttribute("disabled", true);
      }
    });
  }

  displayFields(sectionName) {
    this.fieldTargets.forEach((field) => {
      if (field.dataset.section === sectionName) {
        field.classList.remove("d-none");
        field.removeAttribute("disabled");
      } else {
        field.classList.add("d-none");
        field.setAttribute("disabled", true);
      }
    });
  }

  removeErrorsAlert() {
    if (this.hasErrorsAlertTarget) {
      this.errorsAlertTarget.remove()
    }
  }
}
