import { Controller } from 'stimulus';
import Chart from 'chart.js/auto';

export default class extends Controller {
  connect() {
    const chartData = JSON.parse(this.element.dataset.chartDataset);
    this.renderChart(chartData);
  }

  renderChart(data) {
    const ctx = this.element.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: data.labels,
        datasets: [{
          label: data.label,
          data: data.values,
          backgroundColor: data.colors,
          hoverOffset: 4
        }]
      },
      options: {
        plugins: {
          title: {
            display: false
          }
        },
        cutout: '70%'
      }
    });
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
