import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

/**
 * LiveViewersActioncable Controller
 * 
 * This Stimulus controller manages real-time updates for viewers of a valuation.
 * It establishes a WebSocket connection through Action Cable to track and display
 * who is currently viewing the valuation.
 *
 * @class LiveViewersActioncableController
 * @extends Controller
 *
 * Required data attributes:
 * - data-live-viewers-actioncable-valuation-id: The ID of the valuation to track
 *
 * Required targets:
 * - content: The element where viewer information will be rendered
 */
export default class extends Controller {
  static targets = ["content"]

  /**
   * Initializes the WebSocket connection when the controller connects to the DOM.
   * Subscribes to the ValuationPresenceChannel for real-time viewer updates.
   */
  connect() {
    this.addWarningContainer()
    const valuationId = this.element.dataset.liveViewersActioncableValuationId
    if (!valuationId) return

    this.channel = consumer.subscriptions.create(
      {
        channel: "ValuationPresenceChannel",
        valuation_id: valuationId
      },
      {
        connected: () => {
          this.startPingInterval()
          this.checkViewersCount()
        },
        disconnected: () => {
          this.stopPingInterval()
          this.showConnectionWarning()
        },
        rejected: () => {
          this.showConnectionWarning()
        },
        /**
         * Handles incoming WebSocket messages.
         * Updates the content target with new viewer information.
         * @param {Object} data - The received data containing HTML to be rendered
         */
        received: (data) => {
          if (data.html) {
            this.contentTarget.innerHTML = data.html
            this.checkViewersCount()
          }
        }
      }
    )
  }

  /**
   * Cleans up the WebSocket subscription when the controller disconnects from the DOM.
   */
  disconnect() {
    this.stopPingInterval()
    if (this.channel) {
      this.channel.unsubscribe()
    }

    this.hideAlertWarnning()
    this.removeWarningContainer()
  }

  startPingInterval() {
    this.pingInterval = setInterval(() => {
      try {
        this.channel.perform('ping')
      } catch (error) {
        console.error('Error during ping:', error)
        this.channel.consumer.connection.reopen()
      }
    }, 20000)
  }

  stopPingInterval() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval)
    }
  }

  showConnectionWarning() {
    if (this.warningContainer) {
      this.warningContainer.innerHTML = '<div class="warning-alert">Connection lost. Your presence may not be visible to others.</div>'
    }
  }

  showMultipleViewersWarning() {
    if (this.warningContainer) {
      this.warningContainer.innerHTML = '<div class="warning-alert">There are multiple viewers on this valuation.</div>'
    }
  }

  hideAlertWarnning() {
    if (this.warningContainer) {
      this.warningContainer.innerHTML = ''
    }
  }

  addWarningContainer() {
    if (!this.warningContainer) {
      const bodyContainer = document.getElementById('body-container')
      if (bodyContainer) {
        const warningDiv = document.createElement('div')
        warningDiv.id = 'warning-alert-container'
        bodyContainer.appendChild(warningDiv)
      }
    }
  }

  get warningContainer() {
    return document.getElementById('warning-alert-container')
  }

  removeWarningContainer() {
    const warningContainer = document.getElementById('warning-alert-container')
    if (warningContainer) {
      warningContainer.remove()
    }
  }

  checkViewersCount() {
    const viewersContent = document.getElementById('live-viewers')
    if (viewersContent) {
      const avatarCount = viewersContent.querySelectorAll('.user-avatar').length
      if (avatarCount > 1) {
        this.showMultipleViewersWarning()
      } else {
        this.hideAlertWarnning()
      }
    }
  }
}
