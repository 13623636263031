import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'content',
    'chevrondown',
    'chevronup'
  ]

  toggle() {
    if (this.contentTarget.style.display == 'none') {
      this.contentTarget.style.display = 'block';
      this.chevrondownTarget.style.display = 'none';
      this.chevronupTarget.style.display = 'inline-block';
    } else {
      this.contentTarget.style.display = 'none';
      this.chevrondownTarget.style.display = 'inline-block';
      this.chevronupTarget.style.display = 'none';
    }
  }
}
