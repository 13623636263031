import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'radio']

  collapseOthers(event) {
    if (event.target.checked) {
      const targetId = event.target.getAttribute('aria-controls');
      const formElement = this.element.closest("#collapsedRadioOptions");

      this
      .radioTargets
      .filter(element => element.id !== event.target.id)
      .forEach(element => {
        const collapsibleElements = formElement.querySelectorAll(".collapse");
        collapsibleElements.forEach(element => {
          if (element.id !== targetId) {
            $(element).collapse("hide");
          }
        });
      })
    }
  }

  uncheckOthers(event) {
    if (event.target.checked) {
      this
      .checkboxTargets
      .filter(element => element.id !== event.target.id)
      .forEach(element => {
        if (element.checked) {
          element.click()
        }
      })
    }
  }
}
