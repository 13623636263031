import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['parent', 'contacts', 'contactList', 'newLink']

  connect () {
    if (this.currentValue === '') {
      this.contactListTarget.setAttribute('hidden', 'hidden')
    }
  }

  setUrl (e) {
    if (this.currentValue === '') {
      e.preventDefault()
      this.contactListTarget.setAttribute('hidden', 'hidden')
      return false
    }

    this.contactListTarget.removeAttribute('hidden')
    this.parentTarget.dataset.url = this.currentContactable.dataset.url
  }

  updateContacts (e) {
    Rails.fire(this.parentTarget, 'change')
  }

  populateSelect (e) {
    const [data] = e.detail
    this.options = data
    this.newLinkTarget.href = this.currentContactable.dataset.formUrl
  }

  set options (options) {
    this.contactsTarget.innerHTML = ''
    for (const option of options) {
      this.contactsTarget.add(new Option(option.name, option.id))
    }
  }

  get currentContactable () {
    return this.parentTarget.options[this.parentTarget.selectedIndex]
  }

  get currentValue () {
    return this.parentTarget.value
  }
}
