import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import SelectField from 'components/Fields/Base/SelectField'
import ConditionalField from 'components/Fields/Base/ConditionalField'

interface Props {
  disabled: boolean
}

const RequestedReportType: React.FC<Props> = ({ disabled }) => (
  <ConditionalField field={fieldNames.requestedReportType}>
    <SelectField
      field={fieldNames.requestedReportType}
      label="Requested report type"
      hint="Please select the report type. If you are unsure contact our Quotes Team on 01642 262217 or alternatively email VASQuotes@vas-group.co.uk."
      disabled={disabled}
    />
  </ConditionalField>
)

export default RequestedReportType
