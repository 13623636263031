import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'source', 'destination' ]

  copyTextFromSourceToDestination() {
    let text = this.sourceTarget.textContent
    if (this.destinationTarget.value === '') {
      this.destinationTarget.value = text
    }
  }
}
