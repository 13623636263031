import { createConsumer } from "@rails/actioncable"

const consumer = createConsumer()

// Handle disconnections gracefully
consumer.connection.events.error = function(error) {
  // If the connection is unauthorized, reload the page to redirect to login
  if (error.type === "unauthorized") {
    window.location.reload()
  }
}

export default consumer 
