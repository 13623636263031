import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'
import TextAreaField from 'components/Fields/Base/TextAreaField'

const PropertyReInspection: React.FC = () => {
  return (
    <>
      <ConditionalField field={fieldNames.propertyReInspection}>
        <SelectField
          field={fieldNames.propertyReInspection}
          label="Re-Inspection Required?"
          hint="Do you need the valuer to reinspect the property or are you happy for the valuer to make the assumption that the property is in the same condition as per the previous valuation?"
        />
      </ConditionalField>
      <ConditionalField field={fieldNames.propertyReInspectionDetails}>
        <TextAreaField
          field={fieldNames.propertyReInspectionDetails}
          label="Re-inspection comments"
          rows={3}
        />
      </ConditionalField>
    </>
  )
}

export default PropertyReInspection
