import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import FormattedNumberField from 'components/Fields/Base/FormattedNumberField'

const PropertyInspectionCount: React.FC = () => {
  return (
    <ConditionalField field={fieldNames.propertyInspectionCount}>
      <FormattedNumberField
        field={fieldNames.propertyInspectionCount}
        label="Number of likely inspections required"
        validationRules={{
          min: {
            value: 1,
            message: 'must be greater than 0',
          },
          max: {
            value: 999,
            message: 'must be less than 1000',
          },
        }}
      />
    </ConditionalField>
  )
}

export default PropertyInspectionCount
