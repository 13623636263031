import React from 'react'

interface Props {
  idx: number
  title: string
}

const StepHeadline: React.FC<Props> = ({ idx, title, children }) => (
  <>
    <h1 className="d-flex align-items-center">
      <span
        className="d-flex align-items-center justify-content-center rounded-circle bg-dark text-light mr-2"
        style={{ width: 50, height: 50 }}
      >
        {idx}
      </span>
      <span className="flex-grow-1">{title}</span>
      {children}
    </h1>
    <hr />
  </>
)

export default StepHeadline
