import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "checkbox"]

  connect() {
    this.checkboxTarget.addEventListener("change", this.submitForm.bind(this))
  }

  submitForm() {
    // by adding data-alert to the toggle checkbox, we can add a custom confirmation alert
    // if the alert is not set, we will submit the form without any confirmation
    if (this.checkboxTarget.dataset.alert) {
      const confirmed = confirm(this.checkboxTarget.dataset.alert);
      if (confirmed) {
        this.formTarget.requestSubmit();
      } else {
        this.checkboxTarget.checked = !this.checkboxTarget.checked;
      }
    } else {
      this.formTarget.requestSubmit();
    }
  }
}
