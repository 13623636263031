import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['count', 'radio', 'collapsed', 'question']

  connect() {
    this.updateCount();
    this.highlightNoAnswers()
  }

  toggleConditionalQuestions(target){
    this.collapsedTargets
        .filter(element => element.dataset.source === target.name)
        .forEach(element => {
          if (target.value === element.dataset.toggleValue){
            element.classList.remove('d-none');
          } else {
            element.classList.add('d-none');

            element.querySelectorAll('input, textarea, select').forEach(input => {
              if (input.type === 'radio' || input.type === 'checkbox') {
                input.checked = false;
              } else {
                input.value = '';
              }
            });
            this.updateCount();
          }
        })
  }

  updateCount() {
    const noAnswers = this.radioTargets.filter(
      radio => radio.value === "false" && radio.checked
    ).length;
    this.countTarget.textContent = noAnswers;

    if (noAnswers > 0) {
      this.countTarget.classList.add('text-danger');
    } else {
      this.countTarget.classList.remove('text-danger');
    }
  }

  update(event) {
    this.updateCount();
    this.toggleConditionalQuestions(event.target);
  }

  highlightNoAnswers() {
    this.questionTargets.forEach(question => {
      const formRow = question.closest('.form-row');
      const hasFalseChecked = Array.from(formRow.querySelectorAll('input[type="radio"]'))
        .some(radio => radio.value === "false" && radio.checked);

      if (hasFalseChecked) {
        formRow.classList.add('alert-danger');
      } else {
        formRow.classList.remove('alert-danger');
      }
    });
  }
}
