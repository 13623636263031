import Trix from 'trix'

Trix.config.textAttributes.underline = {
  tagName: 'u',
  inheritable: true,
  parser: function (element) {
    var style = window.getComputedStyle(element)
    return style.textDecoration === 'underline'
  }
}

Trix.config.toolbar.getDefaultHTML = () => {
  const colors = ['lightblue', 'red', 'darkblue', 'purple', 'orange', 'green', 'fuchsia', 'turquoise', 'black']

  let colorPickers = colors.map((color) => {
    return `
    <label class="color-picker" data-trix--colours-target="colour">
      <input type="radio" name="foregroundColor" value="${color}" data-trix-input>
      <span class="swatch" style="color: ${color}"></span>
    </label>
    `
  }).join('')
  return `
  <div class="trix-button-row" data-controller="font-awesome">
    <div class="trix-button-group" data-trix-button-group="text-tools">
      <button data-trix-attribute="bold" data-trix-key="b" title="Bold" tabindex="-1">
        <i class="fas fa-bold"></i>
      </button>
      <button data-trix-attribute="italic" data-trix-key="i" title="Italic" tabindex="-1">
        <i class="fas fa-italic"></i>
      </button>
      <button data-trix-attribute="underline" data-trix-key="u" title="Underline" tabindex="-1">
        <i class="fas fa-underline"></i>
      </button>
      <button data-trix-action="x-colors" data-target="trix--colours.button" title="Colours" tabindex="-1">
        <i class="fas fa-paint-brush"></i>
      </button>
    </div>
    <div class="trix-button-group" data-trix-button-group="block-tools">
      <button data-trix-attribute="bullet" title="Bullets" tabindex="-1">
        <i class="fas fa-list-ul"></i>
      </button>
      <button data-trix-attribute="number" title="Numbers" tabindex="-1">
        <i class="fas fa-list-ol"></i>
      </button>
      <button data-trix-action="decreaseNestingLevel" title="Decrease Nesting Level" tabindex="-1">
        <i class="fas fa-outdent"></i>
      </button>
      <button data-trix-action="increaseNestingLevel" title="Decrease Nesting Level" tabindex="-1">
        <i class="fas fa-indent"></i>
      </button>
    </div>
    <div class="trix-button-group" data-trix-button-group="history-tools">
      <button data-trix-action="undo" data-trix-key="z" title="Undo" tabindex="-1">
        <i class="fas fa-undo"></i>
      </button>
      <button data-trix-action="redo" data-trix-key="shift+z" title="Redo" tabindex="-1">
        <i class="fas fa-redo"></i>
      </button>
    </div>
  </div>

  <div class="trix-dialogs" data-trix-dialogs>
    <div class="trix-dialog trix-dialog--x-colors" data-trix-dialog="x-colors">
      <div>${colorPickers}</div>
    </div>
  </div>
  `
}

window.Trix = Trix
