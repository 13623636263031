import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'
import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'
import FormattedNumberField from 'components/Fields/Base/FormattedNumberField'
import TextField from 'components/Fields/Base/TextField'

const Loan: React.FC = () => (
  <>
    <ConditionalField field={fieldNames.loanType}>
      <SelectField
        field={fieldNames.loanType}
        label="Loan type *"
        hint="We request this information as our valuers need to record this for their insurance renewal purposes."
        validationRules={{ required: true }}
      />
    </ConditionalField>
    <ConditionalField field={fieldNames.loanCharge}>
      <SelectField
        field={fieldNames.loanCharge}
        label="Proposed loan charge *"
        hint="We request this information as our valuers need to record this for their insurance renewal purposes."
        validationRules={{ required: true }}
      />
    </ConditionalField>
    <ConditionalField field={fieldNames.loanAmount}>
      <FormattedNumberField
        field={fieldNames.loanAmount}
        prefix="£"
        label="Proposed loan amount *"
        hint="We request this information as our valuers need to record this for their insurance renewal purposes."
        validationRules={{
          required: true,
          max: {
            value: 999999999,
            message: 'must be less than 999,999,999',
          },
          min: {
            value: 1,
            message: 'must be greater than 0',
          },
        }}
        decimalScale={0}
        allowNegative={false}
      />
    </ConditionalField>
    <ConditionalField field={fieldNames.loanPurpose}>
      <SelectField
        field={fieldNames.loanPurpose}
        label="Loan Purpose"
        validationRules={{ required: false }}
      />
    </ConditionalField>
  </>
)

export default Loan
