import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['passQcAnywayCheckbox', 'passQcButton', 'skipValidation']

  connect() {
    if (this.passQcAnywayCheckboxTarget.checked &&
        window.confirm("Are you sure?\nThe report will be sent to the client, but no metadata will be provided.")) {
      this.passQcButtonTarget.disabled = false
      this.skipValidationTarget.value = true
    } else {
      this.cancelPassQc()
    }
  }

  confirmPassQc(event) {
    event.preventDefault()
    console.log(event.target.dataset)
    const confirmationMessage = event.target.dataset.confirmMessage
    const userConfirmed = confirm(confirmationMessage)

    if (userConfirmed) {
      this.unmaskMarketValue()
      event.target.form.requestSubmit()
    } else {
      this.cancelPassQc()
    }
  }

  cancelPassQc() {
    this.passQcAnywayCheckboxTarget.checked = false
    this.passQcButtonTarget.disabled = true
    this.skipValidationTarget.value = false
  }

  unmaskMarketValue() {
    const marketValueInput = document.querySelector('input#valuation_report_market_value')
    const value = marketValueInput.inputmask.unmaskedvalue()
    marketValueInput.inputmask.remove()
    marketValueInput.value = value
  }
}
