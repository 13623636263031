import React, { CSSProperties, useEffect, useState } from 'react'
import { Form, Col, Row, Button } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import Dropzone from 'react-dropzone'

import useCanDisplayField from 'components/Fields/Base/useCanDisplayField'
import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const maxSize = 7

const fileSizeInMB = (file): number => file.size / 10 ** 6

const totalFilesSizeInMB = (attachments: File[]): number =>
  attachments.reduce((sum, file) => sum + fileSizeInMB(file), 0)

interface Props {
  attachments: File[]
  onRemove: (idx: number) => (ev: React.MouseEvent<HTMLElement>) => void
}

const AttachmentsList: React.FC<Props> = ({ attachments, onRemove }) => {
  return (
    <>
      <small className="py-2 text-muted">
        Files ({totalFilesSizeInMB(attachments).toFixed(2)} MB out of {maxSize}{' '}
        MB):
      </small>
      {attachments.map((attachment, idx) => (
        <Row className="d-flex align-items-center border-top" key={idx}>
          <Col>
            <small className="text-uppercase pl-2">
              {attachment.name}{' '}
              <span className="text-muted">
                {fileSizeInMB(attachment).toFixed(2)}
                MB
              </span>
            </small>
          </Col>
          <Col className="text-right">
            <Button variant="link" className="icon" onClick={onRemove(idx)}>
              <i className="fa fa-trash" aria-hidden="true" />
            </Button>
          </Col>
        </Row>
      ))}
    </>
  )
}

const Attachments = () => {
  const [error, setError] = useState<string>(null)
  const { register, unregister, setValue, watch } = useFormContext()
  const canDisplay = useCanDisplayField(fieldNames.attachments)
  const attachments = watch(fieldNames.attachments) || []

  const handleDrop = async (droppedAttachments) => {
    const allAttachments = [...attachments, ...droppedAttachments]
    const totalSize = totalFilesSizeInMB(allAttachments)
    const canDrop = totalSize <= maxSize

    if (canDrop) {
      cleanErrors()
      setValue(fieldNames.attachments, allAttachments)
    } else {
      setError(`Sorry, total attachments cannot exceed ${maxSize} MB. ` +
        'Please email any additional documents to us directly at VASQuotes@vas-group.co.uk.')
    }
  }

  const handleRemove = (idx) => (ev) => {
    ev.stopPropagation()
    setValue(fieldNames.attachments, [
      ...attachments.slice(0, idx),
      ...attachments.slice(idx + 1),
    ])
  }

  const cleanErrors = () => {
    setError(null)
  }

  useEffect(() => {
    if (canDisplay) {
      register(fieldNames.attachments)
    }

    return () => {
      unregister(fieldNames.attachments)
    }
  }, [canDisplay])

  return (
    <ConditionalField field={fieldNames.attachments}>
      <Form.Group controlId={fieldNames.attachments}>
        <Form.Label>Attachments</Form.Label>
        <Dropzone onDrop={handleDrop} onDragEnter={cleanErrors}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div {...getRootProps({ style: baseStyle })}>
              <input {...getInputProps()} />
              <div>
                {error && (
                  <div className="rounded bg-danger text-white text-center p-2">
                    {error}
                  </div>
                )}
                <div className="text-center my-4">
                  <div>
                    <i
                      className="fa fa-copy display-4 text-muted mb-2"
                      aria-hidden="true"
                    />
                  </div>
                  {isDragActive && 'Drop files here!'}
                  {!isDragActive &&
                    'Click here or drag and drop your file to upload!'}
                </div>
              </div>
              {attachments.length > 0 && (
                <AttachmentsList
                  attachments={attachments}
                  onRemove={handleRemove}
                />
              )}
            </div>
          )}
        </Dropzone>
      </Form.Group>
    </ConditionalField>
  )
}

export default Attachments
