import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from 'components/Fields/Base/SelectField'

interface Props {
  disabled: boolean
}

const RequestedPropertySector: React.FC<Props> = ({ disabled }) => (
  <ConditionalField field={fieldNames.requestedPropertySector}>
    <SelectField
      field={fieldNames.requestedPropertySector}
      label="Requested property sector"
      hint="If the land or buildings have planning permission for conversion and you want them valuing on this basis, please select Developments / Land or buildings (WITH planning permission)."
      disabled={disabled}
    />
  </ConditionalField>
)

export default RequestedPropertySector
