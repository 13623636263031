import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import SelectField from "./Base/SelectField";

const PropertyPreviousReportFromVAS: React.FC = () => (
    <ConditionalField field={fieldNames.propertyPreviousReportFromVAS}>
        <SelectField
            field={fieldNames.propertyPreviousReportFromVAS}
            label="Previous report *"
            validationRules={{ required: true }}
        />
    </ConditionalField>
)

export default PropertyPreviousReportFromVAS
