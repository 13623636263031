import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'figuresAmendedRow', 'figuresAmendedYesRadio', 'figuresAmendedNoRadio' ]

  connect() {
    this.figuresAmendedRowTarget.style.display = 'none'
  }

  reportAmendedYesSelected() {
    this.figuresAmendedYesRadioTarget.required = true
    this.figuresAmendedNoRadioTarget.required = true
    this.figuresAmendedRowTarget.style.display = ''
  }

  reportAmendedNoSelected() {
    this.figuresAmendedYesRadioTarget.required = false
    this.figuresAmendedNoRadioTarget.required = false
    this.figuresAmendedYesRadioTarget.checked = false
    this.figuresAmendedNoRadioTarget.checked = false
    this.figuresAmendedRowTarget.style.display = 'none'
  }
}
