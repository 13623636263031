import React, {useState} from 'react'

import PropertyAdditionalInformation from 'components/Fields/PropertyAdditionalInformation'
import PropertyAddress from 'components/Fields/PropertyAddress'
import PropertyAmendments from 'components/Fields/PropertyAmendments'
import PropertyAnnualRent from 'components/Fields/PropertyAnnualRent'
import PropertyBuildCost from 'components/Fields/PropertyBuildCost'
import PropertyCondition from 'components/Fields/PropertyCondition'
import PropertyContract from 'components/Fields/PropertyContract'
import PropertyDevelopmentDetails from 'components/Fields/PropertyDevelopmentDetails'
import PropertyEstimatedValue from 'components/Fields/PropertyEstimatedValue'
import PropertyGrossDevelopmentValue from 'components/Fields/PropertyGrossDevelopmentValue'
import PropertyInspectionCount from 'components/Fields/PropertyInspectionCount'
import PropertyLandDetails from 'components/Fields/PropertyLandDetails'
import PropertyLeasehold from 'components/Fields/PropertyLeasehold'
import PropertyOutbuildings from 'components/Fields/PropertyOutbuildings'
import PropertyPortfolioDescription from 'components/Fields/PropertyPortfolioDescription'
import PropertyPortfolioPropertyCount from 'components/Fields/PropertyPortfolioPropertyCount'
import PropertyPortfolioValuationBasis from 'components/Fields/PropertyPortfolioValuationBasis'
import PropertyPreviousVisitCommentary from 'components/Fields/PropertyPreviousVisitCommentary'
import PropertyPreviousVp from 'components/Fields/PropertyPreviousVp'
import PropertyReInspection from 'components/Fields/PropertyReInspection'
import PropertyRefurbishment from 'components/Fields/PropertyRefurbishment'
import PropertyRemainingBuildCost from 'components/Fields/PropertyRemainingBuildCost'
import PropertyReports from 'components/Fields/PropertyReports'
import PropertyResidentialStatus from 'components/Fields/PropertyResidentialStatus'
import PropertySameReportFormat from 'components/Fields/PropertySameReportFormat'
import PropertyTenancyStatus from 'components/Fields/PropertyTenancyStatus'
import PropertyTenants from 'components/Fields/PropertyTenants'
import PropertyTenure from 'components/Fields/PropertyTenure'
import PropertyUnits from 'components/Fields/PropertyUnits'
import PropertyValuationFirm from 'components/Fields/PropertyValuationFirm'
import PropertyValuationBasis from 'components/Fields/PropertyValuationBasis'
import SimpleStep from 'components/Steps/SimpleStep'
import PropertyPreviousReportFromVAS from "../Fields/PropertyPreviousReportFromVAS";
import PropertyReInspectionType from "../Fields/PropertyReInspectionType";

interface Props {
  onAddressSelected: (addressSelected: boolean) => void,
}

const PropertyDetails: React.FC<Props> = ({ onAddressSelected }) => {
  const [addressSelected, setAddressSelected] = useState<boolean>(false)

  const handlePropertyAddressSelected = (addressSelected: boolean) => {
    setAddressSelected(addressSelected)
    onAddressSelected(addressSelected)
  }

  const renderMainForm = () => (
    <>
      <>
        <PropertyCondition/>
        <PropertyRefurbishment/>
      </>
      <>
        <PropertyPortfolioPropertyCount/>
        <PropertyPortfolioDescription/>
        <PropertyEstimatedValue/>
        <PropertyGrossDevelopmentValue/>
        <PropertyPortfolioValuationBasis/>
      </>

      <>
        <PropertyReInspectionType/>
        <PropertyPreviousReportFromVAS/>
        <PropertyPreviousVp/>
        <PropertyAmendments/>
        <PropertySameReportFormat/>
      </>
      <>
        <PropertyValuationFirm/>
        <PropertyReInspection/>
      </>
      <PropertyPreviousVisitCommentary/>
      <>
        <PropertyDevelopmentDetails/>
        <PropertyReports/>
        <PropertyInspectionCount/>
        <PropertyBuildCost/>
        <PropertyRemainingBuildCost/>
        <PropertyContract/>
      </>
      <PropertyOutbuildings/>
      <PropertyLandDetails/>
      <PropertyResidentialStatus/>
      <PropertyTenancyStatus/>
      <PropertyValuationBasis/>
      <PropertyTenants/>
      <PropertyAnnualRent/>
      <PropertyUnits/>
      <PropertyTenure/>
      <PropertyLeasehold/>
      <PropertyAdditionalInformation/>
    </>
  )

  return (
    <SimpleStep idx={2} title="Property Details">
      <PropertyAddress
        onAddressSelected={handlePropertyAddressSelected }
      />
      { addressSelected && renderMainForm() }
    </SimpleStep>
  )
}

export default PropertyDetails
