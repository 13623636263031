import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import TextField from 'components/Fields/Base/TextField'

const PropertyPreviousVp: React.FC = () => {
  return (
    <ConditionalField field={fieldNames.propertyPreviousVp}>
      <TextField
        field={fieldNames.propertyPreviousVp}
        label="Previous VP#"
        hint="Please leave blank if you do not know."
      />
    </ConditionalField>
  )
}

export default PropertyPreviousVp
