import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['text'];

  copy() {
    const content = this.textTarget.innerText;

    navigator.clipboard.writeText(content)
      .then(() => {
        alert("Text copied to clipboard!");
      })
      .catch(error => {
        console.error("Failed to copy text: ", error);
      });
  }
}
