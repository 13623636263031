import { Controller } from "stimulus"

export default class extends Controller {
  static values = { frameId: String, pageUrl: String }

  connect() {
    if (this.frameIdValue != "") {
      setTimeout(() => {
        document.getElementById(this.frameIdValue).scrollIntoView({ behavior: "smooth" });
        history.pushState(null, "", this.pageUrlValue);
      }, 300)
    }
  }
}
