import React from 'react'

import { FieldName } from 'components/types'
import useCanDisplayField from 'components/Fields/Base/useCanDisplayField'

interface Props {
  field: FieldName
}

const ConditionalField: React.FC<Props> = ({ field, children }) => {
  const canDisplay = useCanDisplayField(field)

  return canDisplay ? <>{children}</> : null
}

export default ConditionalField
