import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['payment', 'payee', 'applicantEmailFormRow']
  static values = { currentPaymentMethod: String }

  initialize() {
    this.payeeChosen()
  }

  connect () {
    this.toggleEmails()
  }

  toggleEmails () {
    const payee = $('#quote_valuation_attributes_chosen_payee').find('option:selected').text()
    const lender_emails = $('#lender_emails')
    const broker_emails = $('#broker_emails')

    if (payee === 'Lender') {
      lender_emails.show()
      broker_emails.hide()
    } 
    else if (payee === 'Broker') {
      broker_emails.show()
      lender_emails.hide()
    } 
    else {
      lender_emails.hide()
      broker_emails.hide()
    }
  }

  payeeChosen(_e) {
    const payee = this.payeeTarget.value;

    this.toggleEmails()
    this.updatePaymentMethods(payee);
    this.updateApplicantEmailInput(payee);
  }

  updatePaymentMethods(payee) {
    const paymentTarget = $(this.paymentTarget)
    // Reset payment options state - show all by default
    paymentTarget.find('option').show()
    // What are the payment methods for payees?
    const paymentMethods = paymentTarget.data('payee-payment-methods');

    // What are the payment methods selected payee?
    const availablePaymentMethods = paymentMethods[payee] || []

    availablePaymentMethods.push('')

    paymentTarget.find('option').each(function(i, opt) {
      let option = $(opt);

      // Filter out options (and deselect if needed) that are
      // not suitable for selected payee
      if (availablePaymentMethods.indexOf(option.val()) < 0) {
        option.hide();
        option.prop('selected', false)
      }
    });

    if (payee === 'applicant' && this.currentPaymentMethodValue !== 'card') {
      paymentTarget.find('option[value="bacs"]').prop('selected', true);
    }
  }

  // If applicant is chosen as payee, then we want the input
  // to be required to proceed with a selected quote,
  // otherwise - make it optional
  updateApplicantEmailInput(payee) {
    if (!this.hasApplicantEmailFormRowTarget) {
      return;
    }
    const applicantEmailFormRowTarget = $(this.applicantEmailFormRowTarget)

    const labelTag = applicantEmailFormRowTarget.find('label')
    const inputTag = applicantEmailFormRowTarget.find('input')

    const requiredLabel = applicantEmailFormRowTarget.data('required-label')
    const notRequiredLabel = applicantEmailFormRowTarget.data('not-required-label')

    switch(payee) {
      case 'applicant':
        labelTag.html(requiredLabel)
        inputTag.prop('required', true)
        break;
      case 'broker':
      case 'lender':
      default:
        labelTag.html(notRequiredLabel)
        inputTag.prop('required', false)
        break;
    }
  };
}
