import React from 'react'

import fieldNames from 'components/Fields/Base/fieldNames'

import ConditionalField from 'components/Fields/Base/ConditionalField'
import FormattedNumberField from 'components/Fields/Base/FormattedNumberField'

const PropertyUnits: React.FC = () => (
  <ConditionalField field={fieldNames.propertyUnits}>
    <FormattedNumberField
      field={fieldNames.propertyUnits}
      label="Number of units *"
      validationRules={{
        required: true,
        min: {
          value: 1,
          message: 'must be greater than 0',
        },
        max: {
          value: 999,
          message: 'must be less than 1000',
        },
      }}
    />
  </ConditionalField>
)

export default PropertyUnits
