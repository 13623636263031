import React from 'react'
import { Alert, Form } from 'react-bootstrap'
import { Controller, useFormContext, ValidationRules } from 'react-hook-form'
import { TrixEditor } from 'react-trix'

import { FieldName, HelpText } from 'components/types'

import ErrorMessage from 'components/Fields/Base/ErrorMessage'
import Label from 'components/Fields/Base/Label'
import useFieldLabel from 'components/Fields/Base/useFieldLabel'
import useFieldLabelHint from 'components/Fields/Base/useFieldLabelHint'
import useFieldHelpTexts from 'components/Fields/Base/useFieldHelpTexts'
import useFieldPlaceholder from 'components/Fields/Base/useFieldPlaceholder'

interface Props {
  field: FieldName
  placeholder?: string
  label?: string
  helpTexts?: HelpText[]
  hint?: string
  validationRules?: ValidationRules
  disableColorPicker?: boolean
}

const FormattedTextArea: React.FC<Props> = ({
  field,
  placeholder,
  label,
  helpTexts,
  hint,
  validationRules,
  disableColorPicker = false
}) => {
  const { control, errors } = useFormContext()
  const schemaLabel = useFieldLabel(field)
  const schemaLabelHint = useFieldLabelHint(field)
  const schemaHelpTexts = useFieldHelpTexts(field)
  const schemaPlaceholder = useFieldPlaceholder(field)

  return (
    <Form.Group controlId={field} className={disableColorPicker ? 'disable-color-picker' : ''}>
      {(schemaLabel || label) && (
        <Label
          field={field}
          label={schemaLabel || label}
          hint={schemaLabelHint || hint}
        />
      )}
      {(schemaHelpTexts || helpTexts)?.map((helpText, idx) => (
        <Alert variant="info" key={idx}>
          {helpText}
        </Alert>
      ))}
      <div className={`${errors[field] ? 'is-invalid' : ''}`} id={`trix-editor-wrapper-${field}`}>
        <Controller
          name={field}
          defaultValue=""
          rules={validationRules}
          render={(props) => (
            <TrixEditor
              placeholder={schemaPlaceholder || placeholder}
              className={`form-control ${errors[field] ? 'is-invalid' : ''}`}
              mergeTags={[]}
              {...props}
            />
          )}
          control={control}
        />
      </div>
      {errors[field] && <ErrorMessage error={errors[field]} />}
    </Form.Group>
  )
}

export default FormattedTextArea
