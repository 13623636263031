import React from 'react'

import LenderName from 'components/Fields/LenderName'
import LenderSelect from 'components/Fields/LenderSelect'
import Loan from 'components/Fields/Loan'
import Broker from 'components/Fields/Broker'
import SimpleStep from 'components/Steps/SimpleStep'

const LoanDetails: React.FC = () => (
  <SimpleStep idx={3} title="Loan Details">
    <Loan />
    <Broker />
    <LenderName />
    <LenderSelect />
  </SimpleStep>
)

export default LoanDetails
