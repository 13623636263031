import { FieldName } from 'components/types'

import useFieldSchema from './useFieldSchema'

const useHideField = (fieldName: FieldName): string | null => {
  const schema = useFieldSchema(fieldName)
  return schema?.hideField
}

export default useHideField
