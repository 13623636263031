import { FieldName, HelpText } from 'components/types'

import useFieldSchema from './useFieldSchema'

const useFieldHelpTexts = (fieldName: FieldName): HelpText[] => {
  const schema = useFieldSchema(fieldName)
  return schema?.helpTexts
}

export default useFieldHelpTexts
