import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['quotes', 'totalnet', 'totalgross', 'totalgrossInput', 'invoiceNumbersInput', 'dateInput']
  mask_opts = {alias: 'currency', digits: '2'}
  total = 0
  vat_rate = this.totalgrossTarget.dataset.vatRate

  connect() {
    //hack to re-calculate total value after failed validation of form submit
    this.total = Array.from(document.querySelectorAll("#invoice_quote_ids_"))
      .filter(checkbox => checkbox.checked)
      .map(checkbox => this.getAmount(checkbox))
      .reduce((accumulator, currentAmount) => Number(accumulator) + Number(currentAmount), 0)
    if (this.total) {
      this.render_totals();
    }
  }

  prefetch(e) {
    let url = e.currentTarget.selectedOptions[0].dataset.url
    if (!url) {
      e.preventDefault()
      this.quotesTarget.innerHTML = 'Please select a valuation firm on the left'
    }
    e.currentTarget.dataset.url = url
    this.total = 0
  }

  calculate_total(e) {
    const amount = this.getAmount(e.currentTarget)
    if (e.target.checked) {
      this.total += Number(amount);
    } else {
      this.total -= Number(amount);
    }
    this.render_totals();
  }

  valuerChange() {
    this.total = ''
    this.totalgrossInputTarget.value = ''
    this.totalnetTarget.textContent = ''
    this.totalgrossTarget.textContent = ''
    this.invoiceNumbersInputTarget.value = ''
    this.dateInputTarget.value = ''
    let fileInput = document.getElementById("invoice_file")
    let uploaded1 = document.getElementsByClassName("returned-attachment")
    let uploaded2 = document.getElementsByClassName("file-uploaded")
    fileInput.value = null
    Array.from(uploaded1).forEach((element) => element.remove())
    Array.from(uploaded2).forEach((element) => element.remove())
  }

  getAmount(element) {
    const isCancelled = element.dataset.cancelled === 'true'
    const abortiveCost = element.dataset.abortiveCost
    const regularAmount = element.dataset.amount

    return isCancelled ? abortiveCost : regularAmount
  }

  render_totals() {
    const gross = this.total * this.vat_rate
    this.totalgrossInputTarget.value = this.total * this.vat_rate
    this.totalgrossInputTarget.value = (Math.round(gross * 100) / 100).toFixed(2);
    this.totalnetTarget.textContent = Inputmask.format(this.total, this.mask_opts)
    this.totalgrossTarget.textContent = Inputmask.format(gross, this.mask_opts);
  }
}
