import React from 'react'

import fieldsNames from 'components/Fields/Base/fieldNames'

import SimpleStep from 'components/Steps/SimpleStep'
import QuoteReportType from 'components/Fields/QuoteReportType'

const ReportDetails: React.FC = () => (
  <SimpleStep
    idx={6}
    title="Report Details"
    dependentFields={[fieldsNames.quoteReportType]}
  >
    <QuoteReportType />
  </SimpleStep>
)

export default ReportDetails
