import { FieldName } from 'components/types'

import useFieldSchema from './useFieldSchema'

const useFieldValue = (fieldName: FieldName): string | null => {
  const schema = useFieldSchema(fieldName)
  return schema?.value
}

export default useFieldValue
